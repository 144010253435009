import React from "react";
import { View, StyleSheet } from "react-native";
import InstructionRow from "./InstructionRow";
import { Text, Spacing } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

const AuthInstructions: React.FC = () => {
  const { t } = useTranslation();

  const instructions: {
    message: string;
    extra?: string;
  }[] = t("guest.payment.roomtab.auth.instructions", { returnObjects: true });

  return (
    <View style={styles.container}>
      <View>
        <Text type="h5" style={styles.header}>
          {t("guest.payment.roomtab.authorise")}
        </Text>
        {Object.values(instructions).map((item, index) => {
          return (
            <InstructionRow
              key={index}
              item={item}
              index={index}
              markerType="numbers"
            />
          );
        })}
      </View>
      <Text type="subtextRegular">{t("guest.payment.roomtab.help")}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    padding: Spacing.sp2,
    marginBottom: Spacing.sp2,
    zIndex: 20,
  },
  header: {
    marginBottom: Spacing.sp5,
  },
});

export default AuthInstructions;
