import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/types";
import AccountFormScreen from "./AccountFormScreen";
import { FormDetails } from "../../../store/account/types";
import { updateGuestAccount } from "../../../store/account/actions";
import u from "../../../api/helpers";
import api, { handleErrors } from "../../../api";
import FormSchema from "./FormSchema";
import { getI18n, useTranslation } from "react-i18next";
import { TextInput } from "react-native";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";
import { AuthNavProp } from "../../../navigation/guest/AuthRoutes";

export type FormValidation = {
  isValid: boolean;
  errors: FormDetails;
};

export interface Refs {
  [key: string]: TextInput;
}

export interface PhoneData {
  national: string;
  e164?: string;
  isValid: boolean;
}

interface AccountFormContainerProps {
  navigation: GuestNavProp | AuthNavProp;
}

const AccountFormContainer: React.FC<AccountFormContainerProps> = ({
  navigation,
}) => {
  const { t } = useTranslation();
  const account = useSelector(({ account: { guest } }: AppState) => guest);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form, setForm] = useState<FormDetails>(
    account
      ? account
      : {
          title: t("account.form.titles", { returnObjects: true })[0],
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
          postalcode: "",
          housenumber: "",
          street: "",
          countryCode: "NL",
          city: "",
          language: getI18n().language,
        }
  );
  const [validation, setValidation] = useState<FormValidation>({
    isValid: false,
    errors: {},
  });
  const [formErrors, setFormErrors] = useState<FormDetails>({});
  const phoneData = useRef<PhoneData>(null);
  const refs = useRef<Refs>({});

  const titles: string[] = t("account.form.titles", { returnObjects: true });

  const validate = async () => {
    const result = await u.Form.validate(FormSchema, {
      ...form,
      mobile: phoneData.current?.isValid,
    });
    if (result == null) {
      setValidation({ isValid: true, errors: {} });
    } else {
      setValidation({ isValid: false, errors: result });
    }
  };

  const updateForm = (partialForm: Partial<FormDetails>) => {
    setForm({ ...form, ...partialForm });
  };

  const onSubmit = async () => {
    if (!validation.isValid) return;

    try {
      setIsLoading(true);
      await api.Account.register({ ...form, mobile: phoneData.current.e164 });
      dispatch(updateGuestAccount(form));
      setIsLoading(false);
      navigation.goBack();
    } catch (error) {
      setIsLoading(false);
      handleErrors(error);
    }
  };

  useEffect(() => {
    validate();
  }, [form]);

  return (
    <AccountFormScreen
      form={form}
      titles={titles}
      onSubmit={onSubmit}
      updateForm={updateForm}
      validation={validation}
      refs={refs}
      formErrors={formErrors}
      setFormErrors={setFormErrors}
      isLoading={isLoading}
      phoneData={phoneData}
    />
  );
};

export default AccountFormContainer;
