import { request, handleErrors } from "../../api";
import {
  ReceiptActionTypes,
  ReceiptState,
  ReceiptActions,
  TipMode,
} from "./types";
import l from "../../locale";
import { AppThunk } from "../types";

export const getReceiptData = ({
  receiptId,
  digibonQr,
  printerId,
  setError,
  setIsLoading,
  expectPayment,
}: {
  receiptId?: string;
  digibonQr?: string;
  printerId?: string;
  setError: (error: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  expectPayment: boolean;
}): AppThunk => async (dispatch) => {
  setIsLoading(true);
  try {
    const response = await request.post(
      "/vpay/receipt",
      {
        id: receiptId,
        digibonQr,
      },
      true
    );
    const { balance, total } = response.data;
    dispatch(
      setReceipt({
        ...response.data,
        printerId,
        amountPlusTip: total,
      })
    );
    if (!expectPayment) {
      if (balance === 0) setError(l.t("guest.error.alreadyPaid"));
    }
  } catch (error) {
    setError(handleErrors(error, false));
  }
  setIsLoading(false);
};

export const addTip = (tipAmount: number): ReceiptActions => {
  return {
    type: ReceiptActionTypes.ADD_TIP,
    payload: tipAmount,
  };
};

export const selectTipMode = (tipMode: TipMode): ReceiptActions => ({
  type: ReceiptActionTypes.SELECT_TIP_MODE,
  payload: tipMode,
});

export const setReceipt = (receipt: Partial<ReceiptState>) => {
  return {
    type: ReceiptActionTypes.SET_RECEIPT,
    payload: receipt,
  };
};
