import React from "react";
import {
  KeyboardAvoidingView,
  ViewStyle,
  Platform,
  Keyboard,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

interface KeyboardAvoidedViewProps {
  style: ViewStyle;
  behavior?: "position" | "padding" | "height";
}

const KeyboardAvoidedView: React.FC<KeyboardAvoidedViewProps> = ({
  style,
  children,
  behavior = "padding",
}) => {
  const insets = useSafeAreaInsets();

  return (
    <KeyboardAvoidingView
      style={style}
      contentContainerStyle={style}
      behavior={Platform.OS === "android" ? undefined : behavior}
      keyboardVerticalOffset={insets.bottom + 54}
      onStartShouldSetResponder={() => {
        Keyboard.dismiss();
        return true;
      }}
      enabled
    >
      {children}
    </KeyboardAvoidingView>
  );
};

export default KeyboardAvoidedView;
