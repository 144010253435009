import React from "react";
import { StyleSheet, View } from "react-native";
import { Text, TextButton, Button, Spacing } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface RoomTabDeniedScreenProps {
  goToPaymentOptions: () => void;
  showInfo: () => void;
}

const RoomTabDeniedScreen: React.FC<RoomTabDeniedScreenProps> = ({
  goToPaymentOptions,
  showInfo,
}: RoomTabDeniedScreenProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Text type="h3" textAlign="center" style={styles.header}>
        {t("guest.payment.roomtab.noAuth")}
      </Text>
      <Text textAlign="center">{t("guest.payment.roomtab.noAuthInfo")}</Text>

      <Button
        label={t("guest.payment.roomtab.abort")}
        onPress={goToPaymentOptions}
        containerStyle={styles.button}
      />

      <TextButton
        label={t("guest.payment.roomtab.authorise")}
        onPress={showInfo}
        style={styles.textButton}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: Spacing.sp2,
    flex: 1,
    justifyContent: "center",
    position: "relative",
  },
  header: {
    marginBottom: Spacing.sp1,
  },
  button: {
    marginTop: Spacing.sp6,
    marginBottom: Spacing.sp3,
  },
  textButton: {
    alignSelf: "center",
  },
  row: {
    flexDirection: "row",
    alignSelf: "center",
  },
});

export default RoomTabDeniedScreen;
