import React, { useState, useEffect } from "react";
import { AppState } from "../../store/types";
import WelcomeScreen from "./WelcomeScreen";
import { useDispatch, useSelector } from "react-redux";
import { getReceiptData } from "../../store/receipt/actions";
import {
  GuestNavProp,
  GuestParamList,
} from "../../navigation/guest/GuestRoutes";
import { RouteProp } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import PromoScreen from "./PromoScreen";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";

interface WelcomeContainerProps {
  navigation: GuestNavProp;
  route: RouteProp<GuestParamList, "welcome">;
}

const WelcomeContainer: React.FC<WelcomeContainerProps> = ({
  navigation,
  route,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const receipt = useSelector(({ receipt }: AppState) => receipt);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { amount, id } = receipt;
  const couponsEnabled = id?.includes("tab");

  const hasPromo = true;

  const proceed = () => {
    if (couponsEnabled) {
      navigation.push("voucher-receipt");
      return;
    }
    navigation.push("tip");
  };

  useEffect(() => {
    if (route.params === undefined) {
      setError(t("guest.error.noReceipt"));
      return;
    }
    const {
      receipt_id: receiptId,
      printer_id: printerId,
      digibon_qr: digibonQr,
    } = route.params;
    if (!receiptId && !digibonQr) {
      setError(t("guest.error.noReceipt"));
      return;
    }
    dispatch(
      getReceiptData({
        digibonQr,
        receiptId,
        printerId,
        setError,
        setIsLoading,
        expectPayment: false,
      })
    );
  }, [route.params]);

  if (error) {
    return <ErrorPage error={error} />;
  }
  if (isLoading) {
    return <LoadingPage />;
  }
  if (hasPromo) {
    return <PromoScreen amount={amount} proceed={proceed} />;
  }
  return <WelcomeScreen amount={amount} proceed={proceed} />;
};

export default WelcomeContainer;
