import React, { useContext } from "react";
import {
  View,
  StyleSheet,
  GestureResponderEvent,
  LayoutChangeEvent,
} from "react-native";
import hitSlop from "../utils/hitSlop";
import { Text, Spacing, ThemeContext } from "@valkdigital/ui-kit";

interface SliderScreenProps {
  min: number;
  max: number;
  value: number;
  percentage: string;
  onTouchMove: (event: GestureResponderEvent) => void;
  getRailWidth: (event: LayoutChangeEvent) => void;
}

const SliderScreen: React.FC<SliderScreenProps> = ({
  min,
  max,
  value,
  percentage,
  onTouchMove,
  getRailWidth,
}) => {
  const { grey } = useContext(ThemeContext);

  return (
    <>
      <View style={[styles.display, { backgroundColor: grey[0] }]}>
        <Text type="h2" textAlign="center">
          {value.toString()}
        </Text>
      </View>

      <View
        style={[styles.rail, { backgroundColor: grey[0] }]}
        onStartShouldSetResponder={() => true}
        onResponderStart={onTouchMove}
        onResponderMove={onTouchMove}
        onLayout={getRailWidth}
        hitSlop={hitSlop}
      >
        <View style={[styles.left, { width: percentage }]} />
        <View style={[styles.thumbOuter, { left: percentage }]}>
          <View style={styles.thumbInner} />
        </View>
      </View>

      <View style={styles.row}>
        <Text textAlign="center">{min}</Text>
        <Text textAlign="center">{max}</Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  display: {
    alignSelf: "center",
    justifyContent: "center",
    width: 80,
    height: 59,
    borderRadius: 18,
    marginBottom: 0,
  },
  large: {
    width: "20%",
    fontSize: 40,
    borderRadius: 18,
    paddingVertical: Spacing.sp1,
    paddingHorizontal: 13,
  },
  rail: {
    position: "relative",
    width: "78%",
    height: 18,
    alignSelf: "center",
    borderRadius: 19.5,
    marginTop: Spacing.sp4,
  },
  left: {
    position: "absolute",
    top: -2,
    left: 0,
    height: 22,
    borderRadius: 19.5,
    backgroundColor: "#d97e6d",
  },
  thumbOuter: {
    position: "absolute",
    top: -10,
    marginLeft: -20,
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    backgroundColor: "#BA5C4A",
  },
  thumbInner: {
    alignSelf: "center",
    width: 14,
    height: 14,
    borderRadius: 20,
    backgroundColor: "white",
  },
  row: {
    flexDirection: "row",
    width: "82%",
    alignSelf: "center",
    justifyContent: "space-between",
    marginVertical: Spacing.sp2,
  },
});

export default SliderScreen;
