import { ThemeContext } from "@valkdigital/ui-kit";
import React, { useContext } from "react";
import Svg, { Path } from "react-native-svg";
import { SvgProps } from "./types";

export default ({ style }: SvgProps) => {
  const { info } = useContext(ThemeContext);

  return (
    <Svg width="6" height="20" viewBox="0 0 6 20" fill="none" style={style}>
      <Path
        d="M1.416 12.532L0.828 3.964L0.688 0.24H4.832L4.692 3.964L4.104 12.532H1.416ZM2.76 19.336C2.05067 19.336 1.45333 19.0933 0.968 18.608C0.501333 18.104 0.268 17.488 0.268 16.76C0.268 16.0133 0.501333 15.3973 0.968 14.912C1.45333 14.408 2.05067 14.156 2.76 14.156C3.46933 14.156 4.05733 14.408 4.524 14.912C5.00933 15.3973 5.252 16.0133 5.252 16.76C5.252 17.488 5.00933 18.104 4.524 18.608C4.05733 19.0933 3.46933 19.336 2.76 19.336Z"
        fill={info.dark}
      />
    </Svg>
  );
};
