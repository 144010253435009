import React from "react";
import { StyleSheet } from "react-native";
import { Spacing, EmptyState } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface ErrorPageProps {
  error: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <EmptyState
      heading={t("ui.errorPageTitle")}
      content={error}
      style={styles.container}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    padding: Spacing.sp2,
  },
});

export default ErrorPage;
