import i18next from "i18next";
import * as Localization from "expo-localization";
import nl from "./nl";
import { initReactI18next } from "react-i18next";
import en from "./en";
import de from "./de";

i18next.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: "nl",
  lng: Localization.locale,
  resources: {
    nl: {
      translation: nl,
    },
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  },
});

export default i18next;
