import React from "react";
import { StyleSheet, ScrollView } from "react-native";
import CheckMark from "../../../components/Svg/CheckMark";
import { Button, Text, Spacing } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface PaymentSuccessProps {
  proceed: () => void;
  paymentMethods?: string[];
}

const PaymentSuccess: React.FC<PaymentSuccessProps> = ({
  proceed,
  paymentMethods,
}) => {
  const { t } = useTranslation();

  const title = paymentMethods?.includes("room_tab")
    ? t("guest.payment.success.method.roomtab")
    : t("guest.payment.success.method.other");

  return (
    <>
      <ScrollView contentContainerStyle={styles.container}>
        <CheckMark style={styles.checkMark} />
        <Text textAlign="center" type="h3" style={styles.header}>
          {title}
        </Text>
        <Text textAlign="center">{t("guest.payment.success.message")}</Text>
      </ScrollView>
      <Button size="full" label={t("guest.review.create")} onPress={proceed} />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    padding: Spacing.sp2,
  },
  checkMark: {
    marginBottom: Spacing.sp2,
    width: 56,
    height: 56,
    alignSelf: "center",
  },
  header: {
    marginBottom: Spacing.sp2,
  },
});

export default PaymentSuccess;
