import { PropertyActionTypes, PropertyState, PropertyActions } from "./types";

const initialState: PropertyState = {
  entities: undefined,
  ids: undefined,
  selectedId: undefined,
};

export default (
  state: PropertyState = initialState,
  action: PropertyActions
) => {
  switch (action.type) {
    case PropertyActionTypes.SET_PROPERTIES: {
      const { entities, ids } = action.payload;
      return { ...state, entities, ids };
    }
    case PropertyActionTypes.SET_SELECTED_PROPERTY_ID: {
      return { ...state, selectedId: action.payload };
    }
    default:
      return state;
  }
};
