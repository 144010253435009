import React, { useState, useEffect } from "react";
import PaymentOptionsScreen from "./PaymentOptionsScreen";
import { AppState } from "../../../store/types";
import { useDispatch, useSelector } from "react-redux";
import {
  GuestNavProp,
  GuestRoutes,
} from "../../../navigation/guest/GuestRoutes";
import { submitPayment } from "../../../store/payment/actions";
import { PaymentMethod } from "../../../store/payment/types";

interface PaymentOptionsContainerProps {
  navigation: GuestNavProp;
}

export interface PaymentLinks {
  [key: string]: { link: GuestRoutes | PaymentMethod["method"] };
}
const links: PaymentLinks = {
  iDEAL: {
    link: "ideal",
  },
  "Credit Card": { link: "creditcard" },
  SoFort: {
    link: "sofort",
  },
  "Valk Cadeaucard": { link: "giftcard" },
  Bancontact: {
    link: "bancontact",
  },
  "guest.payment.options.roomtab": { link: "room-tab-options" },
};

const PaymentOptionsContainer: React.FC<PaymentOptionsContainerProps> = ({
  navigation,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedLink, setSelectedLink] = useState<string>("");
  const [activeLinks, setActiveLinks] = useState<PaymentLinks>({});
  const { giftcard, account } = useSelector(
    ({ giftcard, account }: AppState) => ({
      giftcard,
      account,
    })
  );
  const dispatch = useDispatch();

  const { amount: giftcardAmount } = giftcard;
  const isSignedIn = !!account.guest?.type;
  const showSpinner: string = isLoading ? selectedLink : "";

  const onLinkPress = async (key: string) => {
    const { link } = links[key];
    if (["sofort", "bancontact", "creditcard"].includes(link)) {
      setSelectedLink(key);
      await dispatch(
        submitPayment({
          setIsLoading,
          paymentMethod: {
            method: link as "sofort" | "bancontact" | "creditcard",
          },
        })
      );
      return;
    }
    if (link === "room-tab-options") {
      if (isSignedIn) {
        setSelectedLink(key);
        await dispatch(
          submitPayment({
            setIsLoading,
            paymentMethod: {
              method: "room_tab",
            },
            skipToken: false,
          })
        );
        return;
      }
    }
    navigation.push(link as GuestRoutes);
  };

  useEffect(() => {
    setActiveLinks(
      Object.keys(links).reduce((result, key) => {
        if (key === "Valk Cadeaucard" && giftcardAmount) {
          return result;
        }
        result[key] = links[key];
        return result;
      }, {})
    );
  }, []);

  return (
    <PaymentOptionsScreen
      links={activeLinks}
      showSpinner={showSpinner}
      onLinkPress={onLinkPress}
    />
  );
};

export default PaymentOptionsContainer;
