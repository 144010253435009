import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../store/types";
import Routes from "./Routes";
import ErrorMessage from "../components/ErrorMessage";
import { StyleSheet } from "react-native";
import { Notification, Spacing } from "@valkdigital/ui-kit";
import Modal from "../components/Modal";
import GiftcardErrorMessage from "../components/GiftcardErrorMessage";

interface NavContainerProps {}

const NavContainer: React.FC<NavContainerProps> = () => {
  const { error, notification, showGiftcardError } = useSelector(
    ({ ui: { error, notification, showGiftcardError } }: AppState) => ({
      error,
      notification,
      showGiftcardError,
    })
  );

  return (
    <>
      <Routes />
      {!!notification && (
        <Modal style={styles.modal}>
          <Notification
            type={notification.type}
            content={notification.content}
          />
        </Modal>
      )}
      {!!error && <ErrorMessage error={error} />}
      {showGiftcardError && <GiftcardErrorMessage />}
    </>
  );
};

const styles = StyleSheet.create({
  modal: {
    marginTop: Spacing.sp6,
    marginHorizontal: Spacing.sp1,
    justifyContent: "flex-start",
  },
});

export default NavContainer;
