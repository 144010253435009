import { ReviewActionTypes, Rating, ReviewActions } from "./types";

export const addRating = (
  rating: Rating,
  ratings: Rating[],
  questionType: string
): ReviewActions => {
  const indexToOverwrite: number = ratings.findIndex(
    (rating) => rating.type === questionType
  );

  if (indexToOverwrite === -1) {
    return {
      type: ReviewActionTypes.ADD_RATING,
      payload: {
        ratings: [...ratings, rating],
      },
    };
  } else {
    const ratingsCopy = [...ratings];
    ratingsCopy[indexToOverwrite] = rating;
    return {
      type: ReviewActionTypes.ADD_RATING,
      payload: {
        ratings: ratingsCopy,
      },
    };
  }
};
