import { Text, TextButton, ThemeContext } from "@valkdigital/ui-kit";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";
import { AppliedCoupon } from "../store/tab/types";

interface CouponsProps {
  item: AppliedCoupon;
  removeCoupon: (qrCode: string) => void;
}

const Coupons: React.FC<CouponsProps> = ({ item, removeCoupon }) => {
  const { error } = useContext(ThemeContext);
  const { t } = useTranslation();

  const onRemove = () => {
    removeCoupon(item.qrCode);
  };

  return (
    <View style={styles.row}>
      <View>
        <Text type="subtextRegular" textAlign="left">
          {`${item.eligibleItemsOnTab}x ${item.label}`}
        </Text>
      </View>
      <TextButton
        label={t("employee.voucher.remove")}
        onPress={onRemove}
        color={error.primary}
        textType="subtextRegular"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  remove: {
    textDecorationLine: "underline",
  },
});

export default Coupons;
