import { UIActionTypes } from "../ui/types";
import { TabActionTypes, TabState, TabActions } from "./types";

const initialState: TabState = null;

export default (state: TabState = initialState, action: TabActions) => {
  switch (action.type) {
    case TabActionTypes.SET_TAB: {
      return action.payload;
    }
    case UIActionTypes.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};
