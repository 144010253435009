import React from "react";
import Review from "../../../components/Review";
import { useTranslation } from "react-i18next";

interface ReviewValkPayProps {}

const ReviewValkPay: React.FC<ReviewValkPayProps> = () => {
  const { t } = useTranslation();

  return (
    <Review
      question={t("guest.review.valkpay")}
      step={3}
      totalSteps={5}
      questionType="valk_pay"
      redirectTo="review-email"
      smallerTitle={true}
    />
  );
};

export default ReviewValkPay;
