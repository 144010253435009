import React, { useContext } from "react";
import { View, StyleSheet, ScrollView, useColorScheme } from "react-native";
import CheckMark from "../../../components/Svg/CheckMark";
import {
  Button,
  Text,
  Spacing,
  TextButton,
  ThemeContext,
} from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";
import { LinearGradient } from "expo-linear-gradient";

interface PaymentSuccessPromoProps {
  proceed: () => void;
  proceedWithPromo: () => void;
  paymentMethods?: string[];
}

const PaymentSuccessPromo: React.FC<PaymentSuccessPromoProps> = ({
  proceed,
  proceedWithPromo,
  paymentMethods,
}) => {
  const { info, typography } = useContext(ThemeContext);
  const colorScheme = useColorScheme();
  const { t } = useTranslation();

  const title = paymentMethods?.includes("room_tab")
    ? t("guest.payment.success.method.roomtab")
    : t("guest.payment.success.method.other");

  return (
    <>
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.content}>
          <CheckMark style={styles.checkMark} />
          <Text textAlign="center" type="h3" style={styles.header}>
            {title}
          </Text>

          <LinearGradient
            colors={[info.dark, info.primary]}
            start={[0.8, 0.5]}
            end={[1, 0.4]}
            style={styles.gradient}
          >
            <Text
              type="h6"
              textAlign="center"
              color={
                colorScheme === "dark" ? typography.color : typography.inverted
              }
            >
              {t("guest.payment.success.promo.subHeader")}
            </Text>
            <Text
              textAlign="center"
              type="subtextRegular"
              style={styles.requirement}
              color={
                colorScheme === "dark" ? typography.color : typography.inverted
              }
            >
              {t("guest.payment.success.promo.phone")}
            </Text>
          </LinearGradient>
        </View>
        <TextButton
          label={t("common.noThanks")}
          onPress={proceed}
          style={styles.textButton}
        />
      </ScrollView>

      <Button
        size="full"
        label={t("guest.payment.success.promo.proceed")}
        onPress={proceedWithPromo}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
  },
  content: {
    flex: 1,
    justifyContent: "center",
  },
  checkMark: {
    marginBottom: Spacing.sp2,
    width: 56,
    height: 56,
    alignSelf: "center",
  },
  header: {
    marginHorizontal: Spacing.sp2,
    marginBottom: Spacing.sp2,
  },
  gradient: {
    paddingVertical: Spacing.sp2,
    paddingHorizontal: Spacing.sp2,
  },
  textButton: {
    alignSelf: "center",
    marginBottom: Spacing.sp3,
  },
  requirement: {
    marginTop: Spacing["sp1/2"],
  },
});

export default PaymentSuccessPromo;
