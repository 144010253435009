import { request, handleErrors } from "../../api";
import { GiftcardActionTypes, GiftcardState, GiftcardActions } from "./types";
import { AppThunk } from "../types";
import { setGiftcardError } from "../ui/actions";
import l from "../../locale";

export const setGiftcardData = (
  giftCardData: Partial<GiftcardState>
): GiftcardActions => ({
  type: GiftcardActionTypes.SET_GIFTCARD_DATA,
  payload: giftCardData,
});

export const checkGiftcardNumber = (
  code: string,
  goToNextPage: () => void,
  setIsLoading: (isLoading: boolean) => void
): AppThunk => async (dispatch) => {
  try {
    setIsLoading(true);
    const response = await request.post(
      "/vpay/giftcard",
      {
        number: code,
      },
      true
    );
    setIsLoading(false);
    if (response.data.balance === 0) {
      throw {
        response: { data: { error: l.t("guest.error.giftcardBalance") } },
      };
    }

    dispatch(
      setGiftcardData({
        code,
      })
    );
    goToNextPage();
  } catch (error) {
    setIsLoading(false);
    handleErrors(error);
  }
};

export const checkGiftcardPin = (
  pin: string,
  setPinError: (pinError: string) => void,
  setIsLoading: (isLoading: boolean) => void
): AppThunk => async (dispatch, getState) => {
  try {
    setIsLoading(true);
    setPinError("");
    await request.post(
      "/vpay/giftcard",
      {
        number: getState().giftcard.code,
        pin,
      },
      true
    );

    dispatch(
      setGiftcardData({
        pin,
      })
    );
    dispatch(setGiftcardError(false));
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    setPinError(handleErrors(error, false));
  }
};

export const getGiftcardBalance = (
  pin: string,
  number: string,
  goToNextPage: () => void,
  setIsLoading: (isLoading: boolean) => void
): AppThunk => async (dispatch) => {
  try {
    setIsLoading(true);
    const response = await request.post(
      "/vpay/giftcard",
      {
        number,
        pin,
      },
      true
    );
    const { balance } = response.data;

    dispatch(
      setGiftcardData({
        pin,
        balance,
      })
    );
    setIsLoading(false);
    goToNextPage();
  } catch (error) {
    setIsLoading(false);
    handleErrors(error);
  }
};

export const cancelGiftCard = () => ({
  type: GiftcardActionTypes.REMOVE_GIFTCARD_DATA,
});
