import React from "react";
import { ScrollView, View, Image, StyleSheet, Dimensions } from "react-native";
import {
  Picker,
  Spacing,
  Text,
  Button,
  TextButton,
  PickerOption,
} from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface TikkieScreenProps {
  proceed: () => void;
  goBack: () => void;
  onSelectChange: (option: PickerOption) => void;
  selectedOption: PickerOption;
  options: PickerOption[];
}

const TikkieScreen: React.FC<TikkieScreenProps> = ({
  proceed,
  goBack,
  onSelectChange,
  selectedOption,
  options,
}) => {
  const { t } = useTranslation();

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Image
        source={require("../../../assets/images/tikkie.png")}
        style={styles.banner}
      />

      <View style={styles.section}>
        <Text textAlign="center">{t("guest.tikkie.text")}</Text>

        <Picker
          selectedOption={selectedOption}
          options={options}
          onSelectChange={onSelectChange}
          title={t("guest.tikkie.title")}
          listType="flatList"
          modalSize="responsive"
          size="small"
          selectContainerStyle={styles.picker}
        />
      </View>

      <View>
        <TextButton
          label={t("guest.tikkie.goBack")}
          onPress={goBack}
          style={styles.textButton}
        />
        <Button
          label={t("guest.tikkie.proceed")}
          onPress={proceed}
          color={"#4db6ac"}
          size="full"
        />
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: "space-between" },
  section: {
    padding: Spacing.sp2,
  },
  banner: {
    width: "100%",
    height: Dimensions.get("window").height * 0.33,
    resizeMode: "contain",
    backgroundColor: "#424284",
  },
  picker: {
    marginTop: Spacing.sp1,
    alignSelf: "center",
  },
  textButton: {
    alignSelf: "center",
    marginBottom: Spacing.sp3,
  },
});

export default TikkieScreen;
