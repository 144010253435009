import React, { useState, useEffect, useRef } from "react";
import PromoPhoneScreen from "./PromoPhoneScreen";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/types";
import { sendPhoneNumber } from "../../../store/promo/actions";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";
import { TextInput } from "react-native";
import { PhoneData } from "../../account/account-form/AccountFormContainer";

interface PromoPhoneContainerProps {
  navigation: GuestNavProp;
}

const PromoPhoneContainer: React.FC<PromoPhoneContainerProps> = ({
  navigation,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const {
    receipt: { id },
    promo: { phone },
  } = useSelector(({ receipt, promo }: AppState) => ({
    receipt,
    promo,
  }));
  const dispatch = useDispatch();
  const inputRef = useRef<TextInput | null>(null);
  const phoneData = useRef<PhoneData | null>(null);

  const formIsValid = isValid && checked;

  const blurInput = () => {
    inputRef?.current?.blur();
  };

  const onPhoneChange = (item: PhoneData) => {
    phoneData.current = item;
    const { national, isValid } = item;
    setPhoneNumber(national);
    setIsValid(isValid);
    if (isValid) blurInput();
  };

  const onSubmit = () => {
    if (phoneData.current?.e164 === undefined) return;
    dispatch(
      sendPhoneNumber(id, phoneData.current.e164, setIsLoading, navigation)
    );
  };

  const onCheckboxChange = () => {
    setChecked(!checked);
  };

  const showConditions = () => {
    blurInput();
    navigation.push("promo-conditions");
  };

  useEffect(() => {
    if (phone) {
      onPhoneChange({ national: phone, e164: phone, isValid: true });
    }
  }, [phone]);

  return (
    <PromoPhoneScreen
      inputRef={inputRef}
      phone={phoneNumber}
      onPhoneChange={onPhoneChange}
      onSubmit={onSubmit}
      isLoading={isLoading}
      onCheckboxChange={onCheckboxChange}
      checked={checked}
      showConditions={showConditions}
      isValid={isValid}
      formIsValid={formIsValid}
    />
  );
};

export default PromoPhoneContainer;
