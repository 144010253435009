import { Spacing } from "@valkdigital/ui-kit";
import React from "react";
import { StyleSheet, ViewStyle, TouchableOpacity } from "react-native";
import CheckBox from "./Svg/CheckBox";

const SIZES = {
  small: 16,
  medium: 24,
  large: 32,
};

interface CheckboxProps {
  onCheckboxChange: () => void;
  checked: boolean;
  children: any;
  size?: "small" | "medium" | "large";
  style?: ViewStyle;
}

const Checkbox: React.FC<CheckboxProps> = ({
  onCheckboxChange,
  checked,
  children,
  size = "medium",
  style,
}) => {
  return (
    <TouchableOpacity
      onPress={onCheckboxChange}
      style={[styles.container, style]}
    >
      <CheckBox
        style={[styles.checkbox, { width: SIZES[size], height: SIZES[size] }]}
        checked={checked}
      />
      {children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  checkbox: {
    marginRight: Spacing.sp1,
  },
});

export default Checkbox;
