import React, { useState, useEffect } from "react";
import GiftCardPayScreen from "./GiftcardPayScreen";
import { AppState } from "../../../store/types";
import { useDispatch, useSelector } from "react-redux";
import { formatMoney } from "../../../components/utils/formatMoney";
import {
  setGiftcardData,
  cancelGiftCard,
} from "../../../store/giftcard/actions";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";

interface GiftcardPayContainerProps {
  navigation: GuestNavProp;
}

const GiftcardPayContainer: React.FC<GiftcardPayContainerProps> = ({
  navigation,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const {
    receipt: { amountPlusTip },
    giftcard,
  } = useSelector(({ receipt, giftcard }: AppState) => ({ receipt, giftcard }));
  const dispatch = useDispatch();

  const spendableAmount = Math.min(giftcard.balance, amountPlusTip);
  const parsedInput = parseFloat(inputValue.replace(",", ".").slice(1));
  const inputAmount: number = isNaN(parsedInput) ? 0 : parsedInput * 100;
  const inputIsValid: boolean =
    inputAmount > 0 && inputAmount <= spendableAmount;

  const onInputChange = (text: string) => {
    const regex = /^€[0-9]*(\.|\,)?([0-9]{1,2})?$/;
    const characterIsValid: boolean = regex.test(text);
    if (characterIsValid) setInputValue(text);
  };

  const onFocusedInput = () => {
    setInputValue("€");
  };

  const proceed = () => {
    if (inputValue) {
      dispatch(setGiftcardData({ amount: inputAmount }));
      navigation.push("giftcard-receipt");
    }
  };

  const goBack = () => {
    dispatch(cancelGiftCard());
    navigation.navigate("payment-options");
  };

  useEffect(() => {
    if (giftcard.amount) {
      return setInputValue(formatMoney(giftcard.amount));
    }
    setInputValue(formatMoney(spendableAmount));
  }, [spendableAmount]);

  return (
    <GiftCardPayScreen
      proceed={proceed}
      goBack={goBack}
      onInputChange={onInputChange}
      onFocusedInput={onFocusedInput}
      inputValue={inputValue}
      inputIsValid={inputIsValid}
      amountPlusTip={amountPlusTip}
      balance={giftcard.balance}
    />
  );
};

export default GiftcardPayContainer;
