import React from "react";
import {
  createStackNavigator,
  StackNavigationProp,
  StackNavigationOptions,
} from "@react-navigation/stack";
import GuestLoginContainer, {
  GuestLoginTypes,
} from "../../guest/account/login/GuestLoginContainer";
import PasswordContainer from "../../guest/account/password/PasswordContainer";
import AccountFormContainer from "../../guest/account/account-form/AccountFormContainer";
import { modalOptions, noModalOptions } from "../styling";
import { useTranslation } from "react-i18next";

export enum LoginTypes {
  VOUCHER = "voucher",
  ROOM_TAB = "roomTab",
}

const screenOptions = ({
  navigation,
}: {
  navigation: any;
}): StackNavigationOptions => {
  const isNotOnFirstScreen = navigation.getState().index > 0;
  const { headerLeft, headerRight, ...modalWithBackButton } = modalOptions;
  if (isNotOnFirstScreen) {
    return modalWithBackButton;
  }
  return modalOptions;
};

export type AuthStackParamList = {
  login: {
    loginType?: GuestLoginTypes;
    error?: "user_denied" | "no_account";
  };
  password: undefined;
  "account-form": undefined;
  "account-details": undefined;
  update: {
    info: string[];
  };
};

const AuthStack = createStackNavigator<AuthStackParamList>();
export const AuthStackScreen = () => {
  const { t } = useTranslation();

  return (
    <AuthStack.Navigator
      screenOptions={({ navigation }) => screenOptions({ navigation })}
    >
      <AuthStack.Screen
        name="login"
        component={GuestLoginContainer}
        options={{ title: t("account.titles.login") }}
      />
      <AuthStack.Screen
        name="password"
        component={PasswordContainer}
        options={{ title: t("account.titles.login") }}
      />
      <AuthStack.Screen
        name="account-form"
        component={AccountFormContainer}
        options={{ title: t("account.titles.register") }}
      />
      <AuthStack.Screen
        name="account-details"
        component={AccountFormContainer}
        options={{ title: t("account.titles.details") }}
      />
    </AuthStack.Navigator>
  );
};

export type AuthRootParamList = {
  auth: undefined;
};

const AuthRoot = createStackNavigator<AuthRootParamList>();
const AuthRootScreen = () => {
  return (
    <AuthRoot.Navigator screenOptions={{ presentation: "card" }}>
      <AuthRoot.Screen
        name="auth"
        component={AuthStackScreen}
        options={noModalOptions}
      />
    </AuthRoot.Navigator>
  );
};

export type AuthNavProp = StackNavigationProp<AuthStackParamList>;
export type AuthRootNavProp = StackNavigationProp<
  AuthStackParamList & AuthRootParamList
>;
export default AuthRootScreen;
