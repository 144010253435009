import React from "react";
import { View, StyleSheet } from "react-native";
import { Spacing, Text, Button, TextInput } from "@valkdigital/ui-kit";
import ThumbsUp from "../../../components/Svg/ThumbsUp";
import KeyboardAvoidedView from "../../../components/KeyboardAvoidedView";
import { useTranslation } from "react-i18next";

interface ReviewEmailScreenProps {
  email: string;
  onEmailChange: (text: string) => void;
  onSubmitReview: () => void;
  isLoading: boolean;
}

const ReviewEmailScreen: React.FC<ReviewEmailScreenProps> = ({
  email,
  onEmailChange,
  onSubmitReview,
  isLoading,
}: ReviewEmailScreenProps) => {
  const { t } = useTranslation();

  return (
    <KeyboardAvoidedView style={styles.container}>
      <View style={styles.section}>
        <ThumbsUp style={styles.icon} />
        <Text type="h3" textAlign="center">
          {t("guest.review.email.header")}
        </Text>
        <Text textAlign="center" style={styles.info}>
          {t("guest.review.email.text")}
          <Text type="bodySemiBold" textAlign="center">
            {t("guest.review.email.textBold")}
          </Text>
        </Text>

        <TextInput
          label={t("account.form.email")}
          keyboardType="email-address"
          value={email}
          onChangeText={onEmailChange}
          placeholder={t("account.form.emailPlaceholder")}
          autoCapitalize="none"
          containerStyle={styles.input}
        />
      </View>

      <View>
        <Button
          label={t("guest.review.email.proceed")}
          onPress={onSubmitReview}
          loading={isLoading}
          disabled={isLoading}
          type="progressbar"
          currentProgress={100}
          color="#498fa7"
        />
      </View>
    </KeyboardAvoidedView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingTop: Spacing.sp2,
  },
  section: {
    padding: Spacing.sp2,
  },
  icon: {
    alignSelf: "center",
    marginBottom: Spacing.sp2,
  },
  info: {
    marginTop: Spacing.sp5,
    marginBottom: Spacing.sp2,
  },
  input: {
    marginTop: Spacing.sp2,
  },
});

export default ReviewEmailScreen;
