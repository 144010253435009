import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/types";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";
import { useNavigation } from "@react-navigation/core";
import Receipt from "../../../components/Receipt";
import VoucherRow from "./VoucherRow";
import api, { handleErrors } from "../../../api";
import { GuestLoginTypes } from "../../account/login/GuestLoginContainer";
import { setTabData } from "../../../store/tab/actions";
import { setNotification } from "../../../store/ui/actions";
import { useTranslation } from "react-i18next";
import LoadingPage from "../../../components/LoadingPage";
import { setReceipt } from "../../../store/receipt/actions";
import { AxiosError } from "axios";

interface VoucherReceiptProps {}

const VoucherReceipt: React.FC<VoucherReceiptProps> = ({}) => {
  const receipt = useSelector(({ receipt }: AppState) => receipt);
  const isSignedIn = useSelector(
    ({ account }: AppState) =>
      account?.guest !== null && account?.guest !== undefined
  );
  const tab = useSelector(({ tab }: AppState) => tab);
  const applyFromReservation = useRef<boolean>(false);
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const navigation = useNavigation<GuestNavProp>();
  const [hasNoCoupons, setHasNoCoupons] = useState(false);
  const [ineligibleArticles, setIneligibleArticles] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const coupons = tab?.coupons;
  const table = receipt.table ?? "";

  const onAccountChange = () => {
    navigation.navigate("auth", {
      screen: "login",
      params: { loginType: GuestLoginTypes.VOUCHER },
    });
  };

  const handleCouponErrors = (error: AxiosError) => {
    if (error.response?.status !== 422) return false;
    switch (error.response.data.code) {
      case "ineligible_articles":
        setIneligibleArticles(true);
        return true;
      case "no_coupons":
        setHasNoCoupons(true);
        return true;
      case "no_active_reservation":
        setHasNoCoupons(true);
        return true;
      default:
        return false;
    }
  };

  const goToNextPage = () => {
    if (coupons?.newBalance === 0) {
      navigation.push("payment-progress", {
        id: receipt.id,
        resultCode: "completed",
      });
      return;
    }
    navigation.push("tip");
  };

  const applyCoupons = async () => {
    setIsApplying(true);
    setIneligibleArticles(false);
    setHasNoCoupons(false);
    try {
      const response = await api.Table.setCoupons({
        table,
        confirm: false,
        applyFromReservation: applyFromReservation.current,
      });
      dispatch(setTabData(response.data));
      dispatch(
        setNotification({
          type: "positive",
          content: applyFromReservation.current
            ? t("guest.voucher.applySuccess")
            : t("guest.voucher.removeSuccess"),
        })
      );
      setIsApplying(false);
    } catch (error) {
      setIsApplying(false);
      if (handleCouponErrors(error)) return;
      handleErrors(error);
    }
  };

  const claimCoupons = async () => {
    setIsConfirming(true);
    try {
      const response = await api.Table.setCoupons({
        table,
        confirm: true,
        applyFromReservation: true,
      });
      dispatch(setTabData(response.data));

      const { id, coupons } = response.data;
      dispatch(
        setReceipt({
          id,
          amount: coupons?.newBalance,
        })
      );

      dispatch(
        setNotification({
          type: "positive",
          content: t("guest.voucher.confirmSuccess"),
        })
      );
      setIsConfirming(false);
      goToNextPage();
    } catch (error) {
      handleErrors(error);
      setIsConfirming(false);
    }
  };

  const onProceed = async () => {
    if (applyFromReservation.current && !!coupons?.eligible) {
      await claimCoupons();
      return;
    }
    goToNextPage();
  };

  const removeCoupons = async () => {
    applyFromReservation.current = false;
    await applyCoupons();
  };

  const addCoupons = async () => {
    applyFromReservation.current = true;
    await applyCoupons();
  };

  useEffect(() => {
    if (!isSignedIn) return;
    addCoupons();
  }, [isSignedIn]);

  if (isApplying) {
    return <LoadingPage message={t("guest.voucher.fetching")} />;
  }
  return (
    <Receipt
      amount={receipt.amount}
      amountLeft={coupons?.newBalance ?? receipt.amount}
      onProceed={onProceed}
      isLoading={isConfirming}
    >
      <VoucherRow
        isSignedIn={isSignedIn}
        onAccountChange={onAccountChange}
        addCoupons={addCoupons}
        removeCoupons={removeCoupons}
        appliedCoupons={coupons?.applied}
        hasNoCoupons={hasNoCoupons}
        ineligibleArticles={ineligibleArticles}
      />
    </Receipt>
  );
};

export default VoucherReceipt;
