import React, { useState, useEffect, useRef } from "react";
import GiftcardNumberScreen from "./GiftcardNumberScreen";
import { checkGiftcardNumber } from "../../../store/giftcard/actions";
import { useDispatch } from "react-redux";
import { Platform } from "react-native";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";
import { TextInputType } from "@valkdigital/ui-kit";

interface GiftcardNumberContainerProps {
  navigation: GuestNavProp;
}

const GiftcardNumberContainer: React.FC<GiftcardNumberContainerProps> = ({
  navigation,
}) => {
  const [code, setCode] = useState<string>("6064 3634 ");
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [scannerIsAvailable, setScannerIsAvailable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const inputRef = useRef<TextInputType>(null);
  const dispatch = useDispatch();

  const blurInput = () => {
    inputRef?.current?.blur();
  };

  const formatCode = (value: string): string => {
    return value
      .replace(/[^[0-9]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
  };

  const onCodeChange = (text: string) => {
    const textWithSpaces = formatCode(text);

    if (textWithSpaces.length > 22) {
      blurInput();
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    if (textWithSpaces.length > 23) return;
    setCode(textWithSpaces);
  };

  const goToPinCodeCheckPage = () => {
    setIsScanning(false);
    navigation.push("giftcard-pin");
  };

  const proceed = (scannedCode?: string) => {
    dispatch(
      checkGiftcardNumber(
        scannedCode ? scannedCode : code,
        goToPinCodeCheckPage,
        setIsLoading
      )
    );
  };

  const toggleScan = () => {
    blurInput();
    setIsScanning(!isScanning);
  };

  const onBarCodeRead = (barCode: string) => {
    const barCodeWithSpaces = barCode.replace(/(.{4})/g, "$1 ").trim();
    setCode(barCodeWithSpaces);
    proceed(barCodeWithSpaces);
  };

  useEffect(() => {
    if (Platform.OS === "web") {
      setScannerIsAvailable(
        !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
      );
    } else {
      setScannerIsAvailable(true);
    }
  }, []);

  return (
    <GiftcardNumberScreen
      inputRef={inputRef}
      onCodeChange={onCodeChange}
      code={code}
      proceed={proceed}
      toggleScan={toggleScan}
      onBarCodeRead={onBarCodeRead}
      isScanning={isScanning}
      scannerIsAvailable={scannerIsAvailable}
      isLoading={isLoading}
      isValid={isValid}
    />
  );
};

export default GiftcardNumberContainer;
