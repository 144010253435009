import React from "react";
import { View, StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import {
  Text,
  Spacing,
  TextButton,
  Button,
  Notification,
} from "@valkdigital/ui-kit";
import Coupons from "../../../components/Coupons";
import { AppliedCoupon } from "../../../store/tab/types";

interface VoucherRowProps {
  isSignedIn: boolean;
  onAccountChange: () => void;
  addCoupons: () => void;
  removeCoupons: (qrCode: string) => void;
  appliedCoupons?: AppliedCoupon[];
  hasNoCoupons: boolean;
  ineligibleArticles: boolean;
}

const VoucherRow: React.FC<VoucherRowProps> = ({
  isSignedIn,
  onAccountChange,
  addCoupons,
  removeCoupons,
  appliedCoupons,
  hasNoCoupons,
  ineligibleArticles,
}) => {
  const { t } = useTranslation();
  const hasAppliedCoupons = !!appliedCoupons?.length;

  if (!isSignedIn) {
    return (
      <View>
        <Text type="subtextRegular">{t("guest.voucher.hasBooking")}</Text>
        <Button
          label={t("guest.voucher.redeem")}
          onPress={onAccountChange}
          type="ghost"
          containerStyle={styles.voucherButton}
        />
      </View>
    );
  }
  if (hasAppliedCoupons) {
    return (
      <View>
        <Text type="h5" textAlign="left" style={styles.header}>
          {t("employee.voucher.title")}
        </Text>
        {appliedCoupons.map((item, index) => {
          return (
            <Coupons key={index} item={item} removeCoupon={removeCoupons} />
          );
        })}
      </View>
    );
  }
  if (hasNoCoupons) {
    return (
      <Notification
        type="warning"
        containerStyle={styles.notification}
        content={t("guest.voucher.hasNoVouchers")}
      >
        <TextButton
          label={t("guest.voucher.tryAnotherAccount")}
          onPress={onAccountChange}
        />
      </Notification>
    );
  }
  if (!ineligibleArticles && !hasAppliedCoupons) {
    return (
      <View>
        <Text type="subtextRegular">
          {t("guest.voucher.ineligibleArticles")}
        </Text>
        <Button
          label={t("guest.voucher.redeem")}
          onPress={addCoupons}
          type="ghost"
          containerStyle={styles.voucherButton}
        />
      </View>
    );
  }
  if (ineligibleArticles) {
    return (
      <Notification
        type="warning"
        content={t("guest.voucher.hasNoClaimableVouchers")}
        containerStyle={styles.notification}
      />
    );
  }
  return null;
};

const styles = StyleSheet.create({
  header: {
    marginBottom: Spacing.sp2,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: Spacing.sp2,
  },
  voucherButton: {
    marginTop: Spacing.sp1,
    marginBottom: Spacing.sp3,
    width: "100%",
  },
  notification: { marginTop: Spacing.sp1 },
});

export default VoucherRow;
