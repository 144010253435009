import { Action } from "redux";
import { UIActionTypes } from "../ui/types";

export interface LineItem {
  articleId: string;
  label: string;
  quantity: number;
  price: number;
  total: number;
  vat?: {
    percentage: number;
    value: number;
  };
  coupon?: Coupon;
}

export interface Coupon {
  id: string;
  label: string;
  qrCode: string;
}

export interface AppliedCoupon extends Coupon {
  eligibleItemsOnTab: number;
}

export interface Coupons {
  eligible: number;
  applied: AppliedCoupon[];
  confirmed: boolean;
  newBalance: number;
}

export interface TabProps {
  id: string;
  updatedAt: string;
  terminal: string;
  table: string;
  service: string;
  server: string;
  propertyId: string;
  balance: number;
  amount: number;
  items: LineItem[];
  coupons?: Coupons;
}

export type TabState = TabProps | null;

export enum TabActionTypes {
  SET_TAB = "@@tab/SET_TAB",
}

interface SetTab extends Action<TabActionTypes.SET_TAB> {
  payload: TabState;
}
interface ResetStore extends Action<UIActionTypes.RESET_STORE> {}

export type TabActions = SetTab | ResetStore;
