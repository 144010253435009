import { Platform } from "react-native";

export default {
  ...Platform.select({
    web: {
      boxShadow: "0px -4px 20px rgba(0, 0, 0, 0.1)",
    },
    ios: {
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.36,
      shadowRadius: 6.68,
    },
    android: {
      elevation: 11,
    },
  }),
};
