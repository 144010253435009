import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Button, Text, Spacing } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface PaymentFailedProps {
  tryAgain: () => void;
}

const PaymentFailed: React.FC<PaymentFailedProps> = ({ tryAgain }) => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.section}>
          <Text type="h4" textAlign="center" style={styles.text}>
            {t("guest.payment.failed.header")}
          </Text>
          <Text textAlign="center">{t("guest.payment.failed.text")}</Text>
        </View>
      </ScrollView>
      <Button
        label={t("guest.payment.failed.retry")}
        onPress={tryAgain}
        size="full"
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingTop: Spacing.sp10,
  },
  section: {
    paddingHorizontal: Spacing.sp2,
  },
  text: {
    marginBottom: Spacing.sp1,
  },
});

export default PaymentFailed;
