import React, { useContext } from "react";
import { View, StyleSheet } from "react-native";
import ExclamationMark from "./Svg/ExclamationMark";
import Modal from "./Modal";
import shadow from "./utils/shadow";
import { Button, Spacing, ThemeContext, Text } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setError } from "../store/ui/actions";

interface ErrorMessageProps {
  error: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  const { info } = useContext(ThemeContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setError(""));
  };

  return (
    <Modal onClose={onClose} backgroundColor="rgba(0,0,0,0.3)">
      {(onCloseModal) => (
        <View
          style={[styles.modal, { ...shadow, backgroundColor: info.light }]}
        >
          <ExclamationMark style={styles.exclamation} />
          <View style={styles.textContainer}>
            <Text style={styles.text} color={info.dark} type="bodySemiBold">
              {t("ui.error.title")}
              <Text color={info.dark}>{error}</Text>
            </Text>
            <Button
              onPress={onCloseModal}
              label={t("ui.error.close")}
              color={info.midDark}
              containerStyle={styles.button}
            />
          </View>
        </View>
      )}
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    marginHorizontal: Spacing.sp2,
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    padding: Spacing.sp2,
    borderRadius: Spacing["sp1/2"],
  },
  exclamation: {
    marginTop: Spacing["sp1/2"],
    marginLeft: Spacing["sp1/2"],
    marginRight: Spacing.sp2,
  },
  textContainer: {
    flex: 1,
  },
  text: { marginRight: Spacing.sp4 },
  fallback: {
    flex: 1,
    justifyContent: "center",
  },
  button: {
    marginTop: Spacing.sp2,
  },
});

export default ErrorMessage;
