import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import Checkbox from "../../../components/Checkbox";
import { useTranslation } from "react-i18next";
import { Text, Button, Spacing } from "@valkdigital/ui-kit";

interface RoomTabConfirmScreenProps {
  name?: string;
  room?: string;
  amount: string;
  checked: boolean;
  onCheckboxChange: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

const RoomTabConfirmScreen: React.FC<RoomTabConfirmScreenProps> = ({
  name,
  room,
  amount,
  checked,
  onCheckboxChange,
  onConfirm,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.section}>
          <Text type="h3" style={styles.header}>
            {t("guest.payment.roomtab.check")}
          </Text>
          <View style={[styles.row, styles.name]}>
            <Text type="bodySemiBold" textAlign="center">
              {t("common.name")}
            </Text>
            <Text type="bodySemiBold" textAlign="center">
              {name}
            </Text>
          </View>
          <View style={styles.row}>
            <Text type="bodySemiBold" textAlign="center">
              {t("guest.payment.roomtab.room")}
            </Text>
            <Text type="bodySemiBold" textAlign="center">
              {room}
            </Text>
          </View>
        </View>

        <View>
          <View style={styles.section}>
            <View style={styles.row}>
              <Text type="h6" textAlign="center">
                {t("common.amountLeft")}
              </Text>
              <Text type="h6" textAlign="center">
                {amount}
              </Text>
            </View>

            <Checkbox
              checked={checked}
              onCheckboxChange={onCheckboxChange}
              style={styles.checkbox}
            >
              <Text type="subtextSemiBold" style={styles.permission}>
                {t("guest.payment.roomtab.consent")}
              </Text>
            </Checkbox>
          </View>
        </View>
      </ScrollView>
      <Button
        label={t("guest.payment.roomtab.book")}
        onPress={onConfirm}
        loading={isLoading}
        disabled={!checked || isLoading}
        size="full"
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingTop: Spacing.sp2,
  },
  header: {
    marginBottom: Spacing.sp7,
  },
  section: {
    paddingHorizontal: Spacing.sp2,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  name: {
    marginBottom: Spacing.sp1,
  },
  checkbox: {
    marginVertical: Spacing.sp6,
  },
  permission: {
    flex: 1,
  },
});

export default RoomTabConfirmScreen;
