import React, { useContext } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  Image,
  useWindowDimensions,
  useColorScheme,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Button, Text, Spacing, ThemeContext } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";
import { formatMoney } from "../../components/utils/formatMoney";

interface PromoProps {
  amount: number;
  proceed: () => void;
}

const PromoScreen: React.FC<PromoProps> = ({ amount, proceed }) => {
  const { t } = useTranslation();
  const { info, typography } = useContext(ThemeContext);
  const { width } = useWindowDimensions();
  const colorScheme = useColorScheme();

  return (
    <>
      <ScrollView contentContainerStyle={styles.container}>
        <View>
          <LinearGradient
            colors={[info.dark, info.primary]}
            start={[0.8, 0.5]}
            end={[1, 0.4]}
            style={styles.gradient}
          >
            <Image
              source={require("../../../assets/images/valkpay.png")}
              style={[
                styles.logo,
                {
                  tintColor:
                    colorScheme === "dark"
                      ? typography.color
                      : typography.inverted,
                },
              ]}
            />
            <Text
              type="bodySemiBold"
              textAlign="center"
              color={
                colorScheme === "dark" ? typography.color : typography.inverted
              }
            >
              {t("guest.promo.info")}
            </Text>
          </LinearGradient>
          <Image
            source={{
              uri: "https://mediabank.valkenhorst.nl/images/339/image96520.png",
            }}
            style={{ width, height: width / 3.6, maxHeight: 200 }}
          />
        </View>

        <View>
          <Text textAlign="center">{t("common.totalAmount")}</Text>
          <Text textAlign="center" type="h3" style={styles.amount}>
            {formatMoney(amount)}
          </Text>
          <Image
            style={styles.toucan}
            source={require("../../../assets/images/exclusief-logo.png")}
          />
        </View>
      </ScrollView>
      <Button label={t("ui.proceed")} onPress={proceed} size="full" />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
  },
  toucan: {
    alignSelf: "center",
    resizeMode: "contain",
    width: "60%",
    height: 40,
    maxWidth: 288,
  },
  logo: {
    marginBottom: Spacing.sp3,
    width: 136,
    height: 51,
    alignSelf: "center",
  },
  gradient: {
    paddingVertical: Spacing.sp4,
    paddingHorizontal: Spacing.sp2,
  },
  amount: {
    marginTop: Spacing.sp1,
    marginBottom: Spacing.sp5,
  },
});

export default PromoScreen;
