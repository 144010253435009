import React from "react";
import { View, FlatList, StyleSheet } from "react-native";
import ApplePayButton from "../apple/ApplePayButton";
import { NavigationTextButton, Spacing, Text } from "@valkdigital/ui-kit";
import CustomLabel from "./CustomLabel";
import { PaymentLinks } from "./PaymentOptionsContainer";
import { useTranslation } from "react-i18next";

interface PaymentOptionsProps {
  links: PaymentLinks;
  showSpinner: string;
  onLinkPress: (key: string) => void;
}

const PaymentOptions: React.FC<PaymentOptionsProps> = ({
  links,
  showSpinner,
  onLinkPress,
}) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View>
        <Text type="h3" style={styles.title}>
          {t("guest.payment.options.header")}
        </Text>
        <FlatList
          data={Object.keys(links)}
          keyExtractor={(_, index) => index.toString()}
          renderItem={({ item, index }) => {
            return (
              <NavigationTextButton
                showTopBorder={index === 0}
                onPress={() => onLinkPress(item)}
                customLabel={
                  <CustomLabel item={item} showSpinner={showSpinner} />
                }
              />
            );
          }}
        />

        <View style={styles.appleButton}>
          <View style={styles.center}>
            <ApplePayButton />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingTop: Spacing.sp2,
  },
  title: {
    paddingHorizontal: Spacing.sp2,
    paddingBottom: Spacing.sp2,
  },
  label: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: Spacing.sp2,
  },
  appleButton: {
    width: "100%",
    paddingTop: Spacing.sp2,
  },
  center: {
    alignSelf: "center",
  },
});

export default PaymentOptions;
