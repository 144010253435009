import { useNavigation } from "@react-navigation/core";
import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";
import Receipt from "../../../components/Receipt";
import { Spacing, Text } from "@valkdigital/ui-kit";
import { formatMoneyWithoutSign } from "../../../components/utils/formatMoney";
import { AppState } from "../../../store/types";
import { useTranslation } from "react-i18next";
import { submitPayment } from "../../../store/payment/actions";

interface GiftcardReceiptProps {}

const GiftcardReceipt: React.FC<GiftcardReceiptProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { receipt, giftcard } = useSelector(
    ({ receipt, giftcard }: AppState) => ({
      receipt,
      giftcard,
    })
  );
  const navigation = useNavigation<GuestNavProp>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    amountPlusTip,
    amount,
    tip: { tipAmount },
  } = receipt;
  const { amount: giftcardAmount } = giftcard;

  const amountLeft = amountPlusTip - giftcardAmount;
  const hasRemainder = amountLeft !== 0;

  const onProceed = async () => {
    if (!hasRemainder) {
      await dispatch(
        submitPayment({
          setIsLoading,
        })
      );
      return;
    }
    navigation.push("payment-options");
  };

  return (
    <Receipt
      amount={amount}
      tip={tipAmount}
      amountLeft={amountLeft}
      onProceed={onProceed}
      isLoading={isLoading}
    >
      <View style={styles.row}>
        <Text>{t("guest.payment.receipt.giftcard")}</Text>
        <Text>{"-" + formatMoneyWithoutSign(giftcardAmount)}</Text>
      </View>
    </Receipt>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: Spacing.sp2,
  },
});

export default GiftcardReceipt;
