import React, { MutableRefObject, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { FormDetails } from "../../../store/account/types";
import { FormValidation, PhoneData, Refs } from "./AccountFormContainer";
import { getI18n, useTranslation } from "react-i18next";
import {
  TextInput,
  PhoneInput,
  Picker,
  Spacing,
  countries,
  Button,
  PickerOption,
} from "@valkdigital/ui-kit";

interface AccountFormScreenProps {
  form: FormDetails;
  titles: string[];
  updateForm: (partialForm: Partial<FormDetails>) => void;
  onSubmit: () => void;
  refs: React.MutableRefObject<Refs>;
  validation: FormValidation;
  formErrors: FormDetails;
  setFormErrors: (formErrors: FormDetails) => void;
  isLoading: boolean;
  phoneData: MutableRefObject<PhoneData>;
}

const AccountFormScreen: React.FC<AccountFormScreenProps> = ({
  form,
  titles,
  updateForm,
  onSubmit,
  refs,
  validation: { isValid, errors },
  formErrors,
  setFormErrors,
  isLoading,
  phoneData,
}) => {
  const { t } = useTranslation();
  const { language } = getI18n();
  const [phoneList, setPhoneList] = useState(countries[language]);
  const [countryList, setCountryList] = useState({});

  useEffect(() => {
    if (!language) return;
    setPhoneList(countries[language]);
    setCountryList(
      Object.entries(countries[language]).reduce(
        (
          result: { [key: string]: PickerOption },
          [key, item]: [string, PickerOption]
        ) => {
          const { extraLabel, ...withoutPhoneCode } = item;
          result[key] = withoutPhoneCode;
          return result;
        },
        {}
      )
    );
  }, [language]);

  return (
    <>
      <KeyboardAwareScrollView
        scrollEnabled={true}
        keyboardOpeningTime={0}
        extraScrollHeight={20}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="handled"
      >
        <View style={styles.form}>
          <Picker
            title={t("account.form.title")}
            label={t("account.form.title")}
            placeholder={t("account.form.title")}
            selectedOption={{ label: form.title, value: form.title }}
            options={titles.map((title) => ({ label: title, value: title }))}
            onSelectChange={(title) => {
              updateForm({ title: title.value });
            }}
            listType="flatList"
            modalSize="responsive"
            selectContainerStyle={styles.input}
            onClose={() => refs.current.firstName.focus()}
          />

          <TextInput
            ref={(ref) => (refs.current.firstName = ref)}
            containerStyle={styles.input}
            label={t("account.form.firstname")}
            value={form?.firstName}
            onChangeText={(firstName) => updateForm({ firstName })}
            onSubmitEditing={() => refs.current.middleName.focus()}
            showCheckmark={!errors.firstName}
            onBlur={() =>
              setFormErrors({ ...formErrors, firstName: errors.firstName })
            }
            error={formErrors.firstName}
          />
          <TextInput
            containerStyle={styles.input}
            label={t("account.form.middlename")}
            value={form?.middleName}
            onChangeText={(middleName) => updateForm({ middleName })}
            ref={(ref) => (refs.current.middleName = ref)}
            onSubmitEditing={() => refs.current.lastName.focus()}
            autoCapitalize="none"
          />
          <TextInput
            containerStyle={styles.input}
            label={t("account.form.lastname")}
            value={form?.lastName}
            onChangeText={(lastName) => updateForm({ lastName })}
            ref={(ref) => (refs.current.lastName = ref)}
            onSubmitEditing={() => refs.current.email.focus()}
            showCheckmark={!errors.lastName}
            onBlur={() =>
              setFormErrors({ ...formErrors, lastName: errors.lastName })
            }
            error={formErrors.lastName}
          />

          <TextInput
            containerStyle={styles.input}
            label={t("account.form.email")}
            value={form?.email}
            onChangeText={(email) => updateForm({ email })}
            ref={(ref) => (refs.current.email = ref)}
            textContentType="emailAddress"
            keyboardType="email-address"
            autoCapitalize="none"
            onSubmitEditing={() => refs.current.password.focus()}
            showCheckmark={!errors.email}
            onBlur={() => setFormErrors({ ...formErrors, email: errors.email })}
            error={formErrors.email}
          />
          <TextInput
            containerStyle={styles.input}
            label={t("account.form.pass")}
            value={form?.password}
            onChangeText={(password) => updateForm({ password })}
            ref={(ref) => (refs.current.password = ref)}
            autoCapitalize="none"
            onSubmitEditing={() => refs.current.phone.focus()}
            showCheckmark={!errors.password}
            onBlur={() =>
              setFormErrors({ ...formErrors, password: errors.password })
            }
            error={formErrors.password}
            type="password"
          />

          <PhoneInput
            label={t("account.form.mobile")}
            value={form?.mobile}
            onChangeText={(item) => {
              updateForm({ mobile: item.national });
              phoneData.current = item;
            }}
            ref={(ref) => (refs.current.phone = ref)}
            onBlur={() =>
              setFormErrors({ ...formErrors, mobile: errors.mobile })
            }
            onSubmitEditing={() => refs.current.street.focus()}
            defaultCountry="NL"
            countries={phoneList}
            listTitle={t("account.form.mobile")}
            listEmptyText={t("common.searchEmpty")}
            listSearchPlaceholder={t("common.search")}
            showCheckmark={!errors.mobile}
            error={formErrors.mobile}
            containerStyle={styles.input}
          />

          <Picker
            label={t("account.form.country")}
            title={t("account.form.country")}
            placeholder={t("account.form.country")}
            options={Object.values(countryList)}
            selectedOption={countryList[form.countryCode]}
            favoriteOptions={["NL", "BE", "DE", "GB"].map(
              (key) => countryList[key]
            )}
            onSelectChange={(option) => {
              updateForm({ countryCode: option.value });
            }}
            onClose={() => refs.current.street?.focus()}
            selectContainerStyle={styles.input}
            alphabeticScrollEnabled={true}
            searchPlaceholder={t("common.search")}
            listEmptyText={t("common.searchEmpty")}
          />

          <TextInput
            containerStyle={styles.input}
            label={t("account.form.street")}
            value={form?.street}
            onChangeText={(street) => updateForm({ street })}
            ref={(ref) => (refs.current.street = ref)}
            onSubmitEditing={() => refs.current.houseNumber.focus()}
            showCheckmark={!errors.street}
            onBlur={() =>
              setFormErrors({ ...formErrors, street: errors.street })
            }
            error={formErrors.street}
          />

          <TextInput
            containerStyle={styles.input}
            label={t("account.form.housenumber")}
            value={form?.housenumber}
            onChangeText={(housenumber) => updateForm({ housenumber })}
            ref={(ref) => (refs.current.houseNumber = ref)}
            keyboardType="number-pad"
            returnKeyType="done"
            onSubmitEditing={() => refs.current.postalCode.focus()}
            showCheckmark={!errors.housenumber}
            onBlur={() =>
              setFormErrors({ ...formErrors, housenumber: errors.housenumber })
            }
            error={formErrors.housenumber}
          />
          <TextInput
            containerStyle={styles.input}
            label={t("account.form.postalcode")}
            value={form?.postalcode}
            onChangeText={(postalcode) => updateForm({ postalcode })}
            ref={(ref) => (refs.current.postalCode = ref)}
            onSubmitEditing={() => refs.current.city.focus()}
            autoCapitalize="characters"
            showCheckmark={!errors.postalcode}
            onBlur={() =>
              setFormErrors({ ...formErrors, postalcode: errors.postalcode })
            }
            error={formErrors.postalcode}
          />
          <TextInput
            containerStyle={styles.input}
            label={t("account.form.city")}
            value={form?.city}
            onChangeText={(city) => updateForm({ city })}
            ref={(ref) => (refs.current.city = ref)}
            onSubmitEditing={onSubmit}
            showCheckmark={!errors.city}
            onBlur={() => setFormErrors({ ...formErrors, city: errors.city })}
            error={formErrors.city}
          />
        </View>
      </KeyboardAwareScrollView>
      <Button
        label={t("account.titles.register")}
        onPress={onSubmit}
        disabled={!isValid}
        loading={isLoading}
        size="full"
      />
    </>
  );
};

const styles = StyleSheet.create({
  form: {
    paddingVertical: Spacing.sp2,
    paddingHorizontal: Spacing.sp3,
  },
  input: {
    marginBottom: Spacing.sp2,
  },
});

export default AccountFormScreen;
