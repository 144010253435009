import { Spacing, Text, ThemeContext } from "@valkdigital/ui-kit";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, ActivityIndicator } from "react-native";

interface CustomLabelProps {
  showSpinner: string;
  item: string;
}

const CustomLabel: React.FC<CustomLabelProps> = ({ showSpinner, item }) => {
  const { t } = useTranslation();
  const { typography } = useContext(ThemeContext);

  return (
    <View style={styles.label}>
      <Text type="bodySemiBold">{t(item)}</Text>
      {showSpinner === item && (
        <ActivityIndicator size="small" color={typography.color} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: Spacing.sp2,
  },
});

export default CustomLabel;
