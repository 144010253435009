import React, { useState, useEffect, useRef } from "react";
import RoomTabVerifyScreen from "./RoomTabVerifyScreen";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";
import { TextInputType } from "@valkdigital/ui-kit";
import { submitPayment } from "../../../store/payment/actions";

interface RoomTabVerifyContainerProps {
  navigation: GuestNavProp;
}

const RoomTabVerifySchema = Yup.object().shape({
  folio: Yup.string().required(),
  room: Yup.string().required(),
});

const RoomTabVerifyContainer: React.FC<RoomTabVerifyContainerProps> = ({
  navigation,
}) => {
  const inputRef = useRef<TextInputType>(null);
  const [folio, setFolio] = useState<string>("");
  const [room, setRoom] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const focusRoomInput = () => {
    inputRef.current?.focus();
  };

  const showInfo = () => {
    navigation.push("roomcode-instructions");
  };

  const onSubmit = async () => {
    await dispatch(
      submitPayment({
        setIsLoading,
        paymentMethod: { method: "room_tab", folio, room },
      })
    );
  };

  const validate = async (schema: Yup.Schema<any>, values: any) => {
    try {
      await schema.validate(values, { abortEarly: false });
      setIsValid(true);
    } catch (error) {
      setIsValid(false);
    }
  };

  useEffect(() => {
    validate(RoomTabVerifySchema, { folio, room });
  }, [folio, room]);

  return (
    <RoomTabVerifyScreen
      folio={folio}
      room={room}
      onFolioChange={setFolio}
      onRoomChange={setRoom}
      isValid={isValid}
      isLoading={isLoading}
      onSubmit={onSubmit}
      inputRef={inputRef}
      focusRoomInput={focusRoomInput}
      showInfo={showInfo}
    />
  );
};

export default RoomTabVerifyContainer;
