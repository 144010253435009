import React from "react";
import {
  View,
  StyleSheet,
  ActivityIndicator,
  useColorScheme,
} from "react-native";
import { useTranslation } from "react-i18next";
import { Spacing, TextButton, Text, Theme } from "@valkdigital/ui-kit";
import CloseButton from "./CloseButton";

interface CameraViewProps {
  hasPermission: boolean;
  openSettings: () => void;
  title: string;
  isLoading: boolean;
  onCloseModal?: () => void;
}

const CameraView: React.FC<CameraViewProps> = ({
  hasPermission,
  openSettings,
  title,
  isLoading,
  onCloseModal,
}) => {
  const { t } = useTranslation();
  const isDark = useColorScheme() === "dark";
  const { border, typography, grey } = Theme.light;

  if (!hasPermission) {
    return (
      <View
        style={[
          styles.cameraView,
          { backgroundColor: isDark ? grey[8] : typography.color },
        ]}
      >
        <View style={styles.noAccess}>
          <Text
            color={typography.inverted}
            textAlign="center"
            type="subHeading"
            style={styles.noAccessTitle}
          >
            {t("scanner.noAccess")}
          </Text>
          <Text color={border} textAlign="center">
            {t("scanner.instructions") + "\n"}
          </Text>
          <TextButton
            label={t("scanner.openSettings")}
            onPress={openSettings}
            style={styles.textButton}
            textType="subHeading"
          />
        </View>
        <View>
          {onCloseModal !== undefined && <CloseButton onPress={onCloseModal} />}
        </View>
      </View>
    );
  }
  return (
    <View style={styles.cameraView}>
      <Text
        type="subHeading"
        color="white"
        textAlign="center"
        style={styles.instruction}
      >
        {title}
      </Text>
      {isLoading && <ActivityIndicator size="large" color="#ffffff" />}
      <View>
        {onCloseModal !== undefined && <CloseButton onPress={onCloseModal} />}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cameraView: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: "rgba(0,0,0,0.1)",
    zIndex: 20,
  },
  instruction: {
    marginTop: Spacing.sp2,
  },
  noAccess: {
    flex: 1,
    justifyContent: "center",
    padding: Spacing.sp2,
  },
  noAccessTitle: { marginBottom: Spacing["sp1/2"] },
  textButton: { alignSelf: "center" },
});

export default CameraView;
