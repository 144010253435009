export const formatMoney = (value: number) => {
  return (
    "€" +
    (value / 100)
      .toFixed(2)
      .replace(".", ",")
      .replace(/\,(.*)[0]{2}/, ",-")
  );
};

export const formatMoneyWithoutSign = (value: number) => {
  return (value / 100).toFixed(2).replace(".", ",");
};

export const formatMoneyWithoutDash = (value: number) => {
  return (
    "€" +
    (value / 100)
      .toFixed(2)
      .replace(".", ",")
      .replace(/\,(.*)[0]{2}/, "")
  );
};
