import React from "react";
import {
  StackNavigationProp,
  createStackNavigator,
} from "@react-navigation/stack";
import { stackScreenOptions, modalOptions, noModalOptions } from "../styling";
import WelcomeContainer from "../../guest/welcome/WelcomeContainer";
import TipContainer from "../../guest/payment/tip/TipContainer";
import PaymentOptionsContainer from "../../guest/payment/options/PaymentOptionsContainer";
import IdealContainer from "../../guest/payment/ideal/IdealContainer";
import PaymentProgressContainer from "../../guest/payment/progress/PaymentProgressContainer";
import GiftcardNumberContainer from "../../guest/giftcard/number/GiftcardNumberContainer";
import GiftcardPinContainer from "../../guest/giftcard/pin/GiftcardPinContainer";
import GiftcardPayContainer from "../../guest/giftcard/pay/GiftcardPayContainer";
import ReviewFood from "../../guest/review/food/ReviewFood";
import ReviewService from "../../guest/review/service/ReviewService";
import ReviewValue from "../../guest/review/value/ReviewValue";
import ReviewValkPay from "../../guest/review/valkpay/ReviewValkPay";
import ReviewEmailContainer from "../../guest/review/email/ReviewEmailContainer";
import ReviewSuccessContainer from "../../guest/review/success/ReviewSuccessContainer";
import TikkieContainer from "../../guest/tikkie/TikkieContainer";
import RoomTabVerifyContainer from "../../guest/payment/room-tab-verify/RoomTabVerifyContainer";
import RoomTabConfirmContainer from "../../guest/payment/room-tab/RoomTabConfirmContainer";
import RoomTabDeniedContainer from "../../guest/payment/room-tab-denied/RoomTabDeniedContainer";
import { AuthStackScreen, AuthStackParamList } from "./AuthRoutes";
import AuthInstructions from "../../guest/payment/room-tab/AuthInstructions";
import RoomCodeInstructions from "../../guest/payment/room-tab/RoomCodeInstructions";
import RoomTabOptionsContainer from "../../guest/payment/room-tab-options/RoomTabOptionsContainer";
import VoucherReceipt from "../../guest/payment/voucher-receipt/VoucherReceipt";
import GiftcardReceipt from "../../guest/giftcard/receipt/GiftcardReceipt";
import { NavigatorScreenParams } from "@react-navigation/core";
import PromoPhoneContainer from "../../guest/promo/phone/PromoPhoneContainer";
import PromoSmsContainer from "../../guest/promo/sms/PromoSmsContainer";
import PromoConditions from "../../guest/promo/phone/PromoConditions";
import { useTranslation } from "react-i18next";

export type GuestStackParamList = {
  welcome: {
    receipt_id?: string;
    digibon_qr?: string;
    printer_id?: string;
  };
  "voucher-receipt": undefined;
  tip: undefined;
  "payment-options": undefined;
  ideal: undefined;
  "room-tab-options": undefined;
  "room-tab-confirm": undefined;
  "room-tab-denied": undefined;
  "payment-progress": {
    id?: string;
    resultCode?: "cancelled" | "completed";
  };
  giftcard: undefined;
  "giftcard-pin": undefined;
  "giftcard-pay": undefined;
  "giftcard-receipt": undefined;
  "review-food": undefined;
  "review-service": undefined;
  "review-value": undefined;
  "review-valkpay": undefined;
  "review-email": undefined;
  "review-success": undefined;
  tikkie: undefined;
  promo: undefined;
  "promo-phone": undefined;
  "promo-sms": undefined;
};

const GuestStack = createStackNavigator<GuestStackParamList>();
const GuestStackScreen = () => {
  const { t } = useTranslation();

  return (
    <GuestStack.Navigator screenOptions={stackScreenOptions}>
      <GuestStack.Screen name="welcome" component={WelcomeContainer} />
      <GuestStack.Screen name="voucher-receipt" component={VoucherReceipt} />
      <GuestStack.Screen name="tip" component={TipContainer} />
      <GuestStack.Screen
        name="payment-options"
        component={PaymentOptionsContainer}
      />
      <GuestStack.Screen name="ideal" component={IdealContainer} />
      <GuestStack.Screen
        name="room-tab-options"
        component={RoomTabOptionsContainer}
        options={{ title: t("guest.titles.roomTab") }}
      />
      <GuestStack.Screen
        name="room-tab-confirm"
        component={RoomTabConfirmContainer}
        options={{ title: t("guest.titles.roomTab") }}
      />
      <GuestStack.Screen
        name="room-tab-denied"
        component={RoomTabDeniedContainer}
        options={{ title: t("guest.titles.roomTab") }}
      />
      <GuestStack.Screen
        name="payment-progress"
        component={PaymentProgressContainer}
        options={{ headerLeft: undefined }}
      />
      <GuestStack.Screen name="giftcard" component={GiftcardNumberContainer} />
      <GuestStack.Screen name="giftcard-pin" component={GiftcardPinContainer} />
      <GuestStack.Screen name="giftcard-pay" component={GiftcardPayContainer} />
      <GuestStack.Screen name="giftcard-receipt" component={GiftcardReceipt} />
      <GuestStack.Screen name="review-food" component={ReviewFood} />
      <GuestStack.Screen name="review-service" component={ReviewService} />
      <GuestStack.Screen name="review-value" component={ReviewValue} />
      <GuestStack.Screen name="review-valkpay" component={ReviewValkPay} />
      <GuestStack.Screen name="review-email" component={ReviewEmailContainer} />
      <GuestStack.Screen
        name="review-success"
        component={ReviewSuccessContainer}
      />
      <GuestStack.Screen name="tikkie" component={TikkieContainer} />
      <GuestStack.Screen name="promo-phone" component={PromoPhoneContainer} />
      <GuestStack.Screen name="promo-sms" component={PromoSmsContainer} />
    </GuestStack.Navigator>
  );
};

type GuestRootParamList = {
  guest: undefined;
  "auth-instructions": undefined;
  "roomcode-instructions": undefined;
  "room-tab-verify": undefined;
  auth: NavigatorScreenParams<AuthStackParamList>;
  "promo-conditions": undefined;
};
const GuestRoot = createStackNavigator<GuestRootParamList>();
const GuestRootScreen = () => {
  const { t } = useTranslation();

  return (
    <GuestRoot.Navigator screenOptions={{ presentation: "card" }}>
      <GuestRoot.Screen
        name="guest"
        component={GuestStackScreen}
        options={noModalOptions}
      />
      <GuestRoot.Screen
        name="auth-instructions"
        component={AuthInstructions}
        options={{ ...modalOptions, title: undefined }}
      />
      <GuestRoot.Screen
        name="roomcode-instructions"
        component={RoomCodeInstructions}
        options={{ ...modalOptions, title: undefined }}
      />
      <GuestRoot.Screen
        name="room-tab-verify"
        component={RoomTabVerifyContainer}
        options={{ ...modalOptions, title: t("guest.titles.roomTabVerify") }}
      />
      <GuestRoot.Screen
        name="auth"
        component={AuthStackScreen}
        options={{ ...noModalOptions }}
      />
      <GuestRoot.Screen
        name="promo-conditions"
        component={PromoConditions}
        options={{ ...modalOptions, title: t("guest.promo.conditions") }}
      />
    </GuestRoot.Navigator>
  );
};

export type GuestParamList = GuestRootParamList & GuestStackParamList;
export type GuestRoutes = keyof GuestParamList;
export type GuestNavProp = StackNavigationProp<GuestParamList>;

export default GuestRootScreen;
