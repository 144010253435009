import { Action } from "redux";

export interface UIState {
  error: string;
  showGiftcardError?: boolean;
  notification?: {
    content: string;
    type: "positive" | "negative";
  };
}

export enum UIActionTypes {
  SET_ERROR = "@@ui/SET_ERROR",
  SHOW_GIFTCARD_ERROR = "@@ui/SHOW_GIFTCARD_ERROR",
  SET_NOTIFICATION = "@@ui/SET_NOTIFICATION",
  RESET_STORE = "@@ui/RESET_STORE",
}

interface SetError extends Action<UIActionTypes.SET_ERROR> {
  payload: UIState["error"];
}
interface ShowGiftcardError extends Action<UIActionTypes.SHOW_GIFTCARD_ERROR> {
  payload: UIState["showGiftcardError"];
}
interface SetNotification extends Action<UIActionTypes.SET_NOTIFICATION> {
  payload: UIState["notification"];
}
interface ResetStore extends Action<UIActionTypes.RESET_STORE> {}

export type UIActions =
  | SetNotification
  | SetError
  | ShowGiftcardError
  | ResetStore;
