import React, { useState } from "react";
import ReviewEmail from "./ReviewEmailScreen";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/types";
import { request, handleErrors } from "../../../api";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";

interface ReviewEmailContainerProps {
  navigation: GuestNavProp;
}

const ReviewEmailContainer: React.FC<ReviewEmailContainerProps> = ({
  navigation,
}) => {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { review, id } = useSelector(
    ({ review, receipt: { id } }: AppState) => ({
      review,
      id,
    })
  );

  const onEmailChange = (text: string) => {
    setEmail(text);
  };

  const onSubmitReview = async () => {
    try {
      setIsLoading(true);
      await request.post("/vpay/review", { ...review, receiptId: id, email });
      setIsLoading(false);

      navigation.push("review-success");
    } catch (error) {
      setIsLoading(false);
      handleErrors(error);
    }
  };

  return (
    <ReviewEmail
      email={email}
      onEmailChange={onEmailChange}
      onSubmitReview={onSubmitReview}
      isLoading={isLoading}
    />
  );
};

export default ReviewEmailContainer;
