import { Theme } from "@valkdigital/ui-kit";
import { Platform, useColorScheme } from "react-native";

const useTheme = () => {
  const colorScheme = useColorScheme();
  const isEmployee = Platform.OS !== "web";

  let theme = Theme[colorScheme === "dark" ? "dark" : "light"];
  if (isEmployee) {
    theme = { ...theme, cta: theme.info };
  }
  return theme;
};

export default useTheme;
