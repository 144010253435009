import axios, { AxiosError, AxiosResponse, AxiosTransformer } from "axios";
import ENV from "../../environment";
import u from "./helpers";
import { store } from "../store/store";
import { setError } from "../store/ui/actions";
import humps from "humps";
import { UIActionTypes } from "../store/ui/types";
import { Platform } from "react-native";
import Constants from "expo-constants";
import l from "../locale";
import {
  GuestLoginParams,
  EmployeeLoginParams,
  Tokens,
  FacebookResponse,
} from "./types";
import jwtDecode from "jwt-decode";
import { TabProps } from "../store/tab/types";
import { PaymentMethod } from "../store/payment/types";

const headers: { [key: string]: string } = {
  "Content-Type": "application/json",
  "Accept-Language": "nl-NL",
  "X-Application": `valkpay-${Platform.OS}-${
    Platform.OS === "web"
      ? Constants.manifest?.version
      : Constants.nativeAppVersion
  }`,
  "X-Device": Constants.deviceName ?? "",
};

const client = axios.create({
  baseURL: `https://${ENV.apiUrl}`,
  headers,
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    (data) => humps.camelizeKeys(data),
  ] as AxiosTransformer[],
  transformRequest: [
    (data) => humps.decamelizeKeys(data),
    ...(axios.defaults.transformRequest as AxiosTransformer[]),
  ],
});

export const handleErrors = (
  e: AxiosError,
  setErrorGlobally: boolean = true
) => {
  let message = l.t("ui.unknownError");

  if (e.response?.data?.error) {
    message = e.response.data.error;
  }
  if (setErrorGlobally) {
    store.dispatch(setError(message));
    return "";
  }
  return message;
};

/*
 * TOKEN HANDLING
 */

let isRefreshingTokens = false;
let refreshSubscribers: any[] = [];

const getToken = async () => {
  let tokens = await u.Auth.get();
  if (!tokens) return false;
  return tokens.accessToken;
};

const tokenHeader = (token: string | false) => {
  if (!token) return {};
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
const onNewTokensFetched = async function (access_token: string) {
  await Promise.all(refreshSubscribers.map((cb) => cb(access_token)));
  refreshSubscribers = [];
};
const addRefreshSubscriber = function (callback: Function) {
  refreshSubscribers.push(callback);
};
client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response || !error.response.status || !error.response.data)
      return Promise.reject(error);

    const {
      config,
      response: { status, data },
    } = error;

    const hasToken = await getToken();
    // if no 401 it's not related to the token so bubble up
    if (status !== 401 || !hasToken) return Promise.reject(error);
    // add original request to array to retry after refreshing token
    const retryOriginalRequest = new Promise((resolve) => {
      addRefreshSubscriber((access_token: string) => {
        config.headers.Authorization = `Bearer ${access_token}`;
        resolve(axios(config));
      });
    });

    if (!isRefreshingTokens) {
      isRefreshingTokens = true;
      let tokens = await u.Auth.get();
      /* give this request a custom header so we know,
       * when intercepting a 401 from this one that we
       * need to logout the user
       */
      const res = await client.post(
        "/account/token",
        {
          refresh_token: tokens.refreshToken,
          grant: "refresh_token",
        },
        { headers: { "X-Refresh": "1" } }
      );
      isRefreshingTokens = false;
      await u.Auth.update(res.data.token, res.data.refreshToken);
      await onNewTokensFetched(res.data.token);
    } else {
      if (config.headers["X-Refresh"]) {
        await u.Auth.reset();
        store.dispatch({ type: UIActionTypes.RESET_STORE });
        store.dispatch(setError(data.error));
        isRefreshingTokens = false;
        refreshSubscribers = [];
      }
    }
    return retryOriginalRequest;
  }
);

export const request = {
  del: async (url: string, data: any) => {
    const token = await getToken();
    const res = await client.delete(url, {
      data,
      ...tokenHeader(token),
    });
    return res.data;
  },
  get: async (url: string, skipToken: boolean = false) => {
    let reqHeaders = {};
    if (!skipToken) {
      const token = await getToken();
      reqHeaders = tokenHeader(token);
    }
    const res = await client.get(url, reqHeaders);
    return res.data;
  },
  put: async (url: string, body: any) => {
    const token = await getToken();
    const res = await client.put(url, body, tokenHeader(token));
    return res.data;
  },
  post: async (url: string, body: any, skipToken: boolean = false) => {
    let reqHeaders = {};
    if (!skipToken) {
      const token = await getToken();
      reqHeaders = tokenHeader(token);
    }
    const res = await client.post(url, body, reqHeaders);
    return res.data;
  },
};

const Properties = {
  getAll: async () => request.get("/v1/valk_pay/properties"),
};

const Account = {
  current: async () => request.get("/account/me"),
  save: async (details: any) =>
    request.put("/account/me", { account: details }),
  register: async (details: any) =>
    request.post("/account", { account: details }),
  facebookLogin: async (returnPath: string): Promise<FacebookResponse> =>
    request.post("/v1/sessions", {
      mode: "facebook",
      returnPath,
    }),
  signInWithApple: async (token: string | null): Promise<Tokens> =>
    request.post("/v1/sessions", { mode: "apple", token }),
  employeeLogin: async (params: EmployeeLoginParams) => {
    const response: Tokens = await request.post(
      "/v1/valk_pay/sessions",
      params,
      true
    );
    await u.Auth.update(response.token, response.refreshToken);
    const decodedToken = jwtDecode<{
      exp: number;
      pos: string;
      pry: string;
      sub: string;
    }>(response.token);
    return { name: decodedToken.sub };
  },
  guestLogin: async (params: GuestLoginParams): Promise<Tokens> =>
    request.post(
      "/account/token",
      {
        ...params,
        grant: "credentials",
      },
      true
    ),
};

const Table = {
  getTab: async (table: string): Promise<AxiosResponse<TabProps>> =>
    request.get(`/vpay/table/${table}`),
  setCoupons: async ({
    table,
    qrCodes,
    applyFromReservation,
    confirm,
  }: {
    table: string;
    qrCodes?: string[];
    applyFromReservation?: boolean;
    confirm: boolean;
  }): Promise<AxiosResponse<TabProps>> =>
    request.post(`/vpay/table/${table}`, {
      coupons: {
        qrCodes,
        applyFromReservation,
        confirm,
      },
    }),
  availableTerminals: async () => request.get("/v1/valk_pay/terminals"),
  availablePrinters: async () => request.get("/v1/valk_pay/printers"),
  print: async (table: string, printerId?: string) =>
    request.put(`/vpay/table/${table}/print`, { printerId }),
  pay: async (params: {
    printerId?: string;
    receiptId: string;
    methods: PaymentMethod[];
    tip: number;
  }) => request.post(`/vpay/payments`, params),
};

export default { Account, Table, Properties };
