import React from "react";
import RoomTabDeniedScreen from "./RoomTabDeniedScreen";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";

interface RoomTabDeniedContainerProps {
  navigation: GuestNavProp;
}

const RoomTabDeniedContainer: React.FC<RoomTabDeniedContainerProps> = ({
  navigation,
}) => {
  const goToPaymentOptions = () => {
    navigation.navigate("payment-options");
  };

  const showInfo = () => {
    navigation.push("auth-instructions");
  };

  return (
    <RoomTabDeniedScreen
      goToPaymentOptions={goToPaymentOptions}
      showInfo={showInfo}
    />
  );
};

export default RoomTabDeniedContainer;
