import { PromoActionTypes, PromoState, PromoActions } from "./types";
import { UIActionTypes } from "../ui/types";

const initialState: PromoState = {
  phone: "",
};

export default (state: PromoState = initialState, action: PromoActions) => {
  switch (action.type) {
    case PromoActionTypes.ADD_PROMO_DATA: {
      return action.payload;
    }
    case UIActionTypes.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};
