import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store/types";
import TikkieScreen from "./TikkieScreen";
import { Linking } from "react-native";
import { GuestNavProp } from "../../navigation/guest/GuestRoutes";
import { useTranslation } from "react-i18next";
import { PickerOption } from "@valkdigital/ui-kit";

interface TikkieContainerProps {
  navigation: GuestNavProp;
}

const TikkieContainer: React.FC<TikkieContainerProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<PickerOption>({
    value: 1,
    label: t("guest.tikkie.person", { count: 1 }),
  });

  const { paid, property } = useSelector(({ receipt }: AppState) => receipt);

  const options: PickerOption[] = Array(30)
    .fill(1)
    .map((_, i) => {
      return {
        value: i + 1,
        label: t("guest.tikkie.person", { count: i + 1 }),
      };
    });

  const proceed = () => {
    const numberOfPersons = selectedOption.value;
    const tikkieAmount = Math.floor(paid - paid / (numberOfPersons + 1));
    Linking.openURL(
      encodeURI(
        `tikkie://payment_request?clientId=ValkExclusief&totalAmountInCents=${tikkieAmount}&numberOfPersons=${numberOfPersons}&description=${property}`
      )
    );
  };

  const goBack = () => {
    navigation.goBack();
  };

  const onSelectChange = (option: PickerOption) => {
    setSelectedOption(option);
  };

  return (
    <TikkieScreen
      proceed={proceed}
      selectedOption={selectedOption}
      onSelectChange={onSelectChange}
      options={options}
      goBack={goBack}
    />
  );
};

export default TikkieContainer;
