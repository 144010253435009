import { GiftcardActionTypes, GiftcardState, GiftcardActions } from "./types";
import { UIActionTypes } from "../ui/types";

const initialState: GiftcardState = {
  code: "",
  pin: "",
  balance: 0,
  amount: 0,
};

export default (
  state: GiftcardState = initialState,
  action: GiftcardActions
) => {
  switch (action.type) {
    case GiftcardActionTypes.SET_GIFTCARD_DATA: {
      return { ...state, ...action.payload };
    }
    case GiftcardActionTypes.REMOVE_GIFTCARD_DATA:
      return initialState;
    case UIActionTypes.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};
