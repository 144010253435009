import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import PasswordScreen from "./PasswordScreen";
import { resetPassword } from "../../../store/account/actions";
import l from "../../../locale";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";

interface PasswordContainerProps {
  navigation: GuestNavProp;
}

const EmailScheme = Yup.object().shape({
  email: Yup.string()
    .email(l.t("account.form.emailInvalid"))
    .required(
      l.t("account.form.required", {
        value: l.t("account.form.email").toLowerCase(),
      })
    ),
});

const PasswordContainer: React.FC<PasswordContainerProps> = ({
  navigation,
}) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const onSubmit = async () => {
    if (isValid) await resetPassword({ email, setIsLoading, navigation });
  };

  const validate = async (schema: Yup.Schema<any>, email: string) => {
    try {
      await schema.validate({ email });
      setEmailError("");
      setIsValid(true);
    } catch (error) {
      setEmailError(error.message);
      setIsValid(false);
    }
  };

  useEffect(() => {
    validate(EmailScheme, email);
  }, [email]);

  return (
    <PasswordScreen
      email={email}
      setEmail={setEmail}
      emailError={emailError}
      isValid={isValid}
      isLoading={isLoading}
      onSubmit={onSubmit}
      showError={showError}
      setShowError={setShowError}
    />
  );
};
export default PasswordContainer;
