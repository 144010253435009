import React, { MutableRefObject } from "react";
import { View, StyleSheet } from "react-native";
import Checkbox from "../../../components/Checkbox";
import KeyboardAvoidedView from "../../../components/KeyboardAvoidedView";
import {
  Text,
  Button,
  TextButton,
  Spacing,
  PhoneInput,
  countries,
} from "@valkdigital/ui-kit";
import { useTranslation, getI18n } from "react-i18next";
import { PhoneData } from "../../account/account-form/AccountFormContainer";

interface PromoPhoneScreenProps {
  inputRef: MutableRefObject<any>;
  onSubmit: () => void;
  isLoading: boolean;
  onCheckboxChange: () => void;
  checked: boolean;
  showConditions: () => void;
  phone: string;
  onPhoneChange: (phoneData: PhoneData) => void;
  isValid: boolean;
  formIsValid: boolean;
}

const PromoPhoneScreen: React.FC<PromoPhoneScreenProps> = ({
  inputRef,
  onSubmit,
  isLoading,
  onCheckboxChange,
  checked,
  showConditions,
  phone,
  onPhoneChange,
  isValid,
  formIsValid,
}) => {
  const { t } = useTranslation();

  return (
    <KeyboardAvoidedView style={styles.container}>
      <View style={styles.section}>
        <Text type="h3">{t("guest.promo.phone.header")}</Text>
        <Text textAlign="center" style={styles.text}>
          {t("guest.promo.phone.text")}
        </Text>
        <Text type="subtextRegular">{t("guest.promo.phone.textExtra")}</Text>

        <PhoneInput
          ref={inputRef}
          label={t("account.form.mobile")}
          containerStyle={styles.phoneInput}
          countries={countries[getI18n().language]}
          favoriteCountries={["NL", "BE", "DE", "GB"]}
          defaultCountry="NL"
          listTitle={t("account.form.mobile")}
          listEmptyText={t("common.searchEmpty")}
          listSearchPlaceholder={t("common.search")}
          value={phone}
          onChangeText={(item) => {
            onPhoneChange(item);
          }}
          showCheckmark={isValid}
        />

        <Checkbox
          checked={checked}
          onCheckboxChange={onCheckboxChange}
          style={styles.checkbox}
        >
          <Text type="subtextRegular">
            {t("guest.promo.phone.consent")}
            <TextButton
              label={t("guest.promo.phone.conditions")}
              textType="subtextRegular"
              onPress={showConditions}
            />
          </Text>
        </Checkbox>
      </View>

      <Button
        label={t("guest.promo.phone.confirm")}
        onPress={onSubmit}
        loading={isLoading}
        disabled={!formIsValid || isLoading}
        size="full"
      />
    </KeyboardAvoidedView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingTop: Spacing.sp2,
  },
  section: {
    padding: Spacing.sp2,
  },
  text: {
    textAlign: "left",
    marginVertical: Spacing.sp2,
  },
  phoneInput: {
    marginTop: Spacing.sp5,
    marginBottom: Spacing.sp1,
  },
  checkbox: {
    marginTop: Spacing.sp1,
  },
});

export default PromoPhoneScreen;
