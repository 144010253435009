import { request, handleErrors } from "../../api";
import { PromoActionTypes } from "./types";
import { GuestNavProp } from "../../navigation/guest/GuestRoutes";
import { AppThunk } from "../types";

export const sendPhoneNumber = (
  receiptId: string,
  phone: string,
  setIsLoading: (isLoading: boolean) => void,
  navigation: GuestNavProp
): AppThunk => async (dispatch) => {
  try {
    setIsLoading(true);
    await request.post(
      "/vpay/promo",
      {
        campaign: "ValkPayAmz2021",
        receiptId,
        phone,
      },
      true
    );

    dispatch({
      type: PromoActionTypes.ADD_PROMO_DATA,
      payload: {
        phone,
      },
    });
    setIsLoading(false);
    navigation.push("promo-sms");
  } catch (error) {
    setIsLoading(false);
    handleErrors(error);
  }
};
