import React, { useState, useRef } from "react";
import GiftcardPinScreen from "./GiftcardPinScreen";
import { getGiftcardBalance } from "../../../store/giftcard/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/types";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";
import { TextInputType } from "@valkdigital/ui-kit";

interface GiftcardPinContainerProps {
  navigation: GuestNavProp;
}

const GiftcardPinContainer: React.FC<GiftcardPinContainerProps> = ({
  navigation,
}) => {
  const [code, setCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const inputRef = useRef<TextInputType>(null);
  const giftcard = useSelector(({ giftcard }: AppState) => giftcard);
  const dispatch = useDispatch();

  const blurInput = () => {
    inputRef?.current?.blur();
  };

  const onCodeChange = (text: string) => {
    if (text.length > 5) {
      blurInput();
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    if (text.length > 6) return;
    setCode(text);
  };

  const goToNextPage = () => {
    navigation.push("giftcard-pay");
  };

  const proceed = () => {
    dispatch(
      getGiftcardBalance(code, giftcard.code, goToNextPage, setIsLoading)
    );
  };

  return (
    <GiftcardPinScreen
      inputRef={inputRef}
      onCodeChange={onCodeChange}
      code={code}
      proceed={proceed}
      isLoading={isLoading}
      isValid={isValid}
    />
  );
};

export default GiftcardPinContainer;
