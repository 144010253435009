import React, { Suspense, useContext, useRef } from "react";
import Modal from "../Modal";
import {
  View,
  StyleSheet,
  Linking,
  useWindowDimensions,
  ActivityIndicator,
  useColorScheme,
} from "react-native";
import shadow from "../utils/shadow";
import { BarCodeScanner } from "expo-barcode-scanner";
import { BarCodeScanningResult, Camera } from "expo-camera";
import CameraView from "./CameraView";
import { Text, Spacing, Theme } from "@valkdigital/ui-kit";
import CloseButton from "./CloseButton";
import { ScannerProps } from ".";
const QuaggaScanner = React.lazy(() => import("./QuaggaScanner.web"));

const Scanner: React.FC<ScannerProps> = ({
  toggleScan,
  onBarCodeRead,
  isLoading,
  title,
  barCodeType = "code128",
  style,
  mode = "modal",
}) => {
  const prevBarCode = useRef<string>("");
  const scannerWidth = useWindowDimensions().width * 0.8;
  const isDark = useColorScheme() === "dark";

  const { typography, grey } = Theme.light;
  const backgroundColor = isDark ? grey[8] : typography.color;

  const _onBarCodeRead = (barCode: string) => {
    if (isLoading || prevBarCode.current === barCode) return;
    prevBarCode.current = barCode;
    onBarCodeRead(barCode);
  };

  const onBarCodeScanned = ({ data }: BarCodeScanningResult) => {
    if (isLoading || prevBarCode.current === data) return;
    prevBarCode.current = data;
    onBarCodeRead(data);
  };

  if (barCodeType !== "qr") {
    if (mode === "inline") {
      return (
        <View
          style={[
            styles.camera,
            {
              width: scannerWidth,
              height: scannerWidth * (4 / 3),
              backgroundColor,
            },
          ]}
        >
          <Suspense fallback={null}>
            <QuaggaScanner onBarCodeRead={_onBarCodeRead} disabled={isLoading}>
              <View style={styles.cameraView}>
                <Text
                  color="white"
                  textAlign="center"
                  type="subHeading"
                  style={styles.instruction}
                >
                  {title}
                </Text>
                {isLoading && (
                  <ActivityIndicator size="large" color="#ffffff" />
                )}
              </View>
            </QuaggaScanner>
          </Suspense>
        </View>
      );
    }
    return (
      <Modal onClose={toggleScan} backgroundColor="rgba(0,0,0,0.3)">
        {(onCloseModal: () => void) => (
          <View
            style={[
              styles.camera,
              {
                width: scannerWidth,
                height: scannerWidth * (4 / 3),
                backgroundColor,
              },
            ]}
          >
            <Suspense fallback={null}>
              <QuaggaScanner
                onBarCodeRead={_onBarCodeRead}
                disabled={isLoading}
              >
                <View style={styles.cameraView}>
                  <Text
                    color="white"
                    textAlign="center"
                    type="subHeading"
                    style={styles.instruction}
                  >
                    {title}
                  </Text>
                  {isLoading && (
                    <ActivityIndicator size="large" color="#ffffff" />
                  )}
                  <CloseButton onPress={onCloseModal} />
                </View>
              </QuaggaScanner>
            </Suspense>
          </View>
        )}
      </Modal>
    );
  }
  return (
    <Modal onClose={toggleScan} backgroundColor="rgba(0,0,0,0.3)">
      {(onCloseModal: () => void) => (
        <View
          style={[
            styles.camera,
            {
              width: scannerWidth,
              height: scannerWidth * (4 / 3),
              backgroundColor,
            },
            style,
          ]}
        >
          <Camera
            type={Camera.Constants.Type.back}
            barCodeScannerSettings={{
              barCodeTypes: [BarCodeScanner.Constants.BarCodeType[barCodeType]],
            }}
            onBarCodeScanned={onBarCodeScanned}
            style={StyleSheet.absoluteFillObject}
            ratio="4:3"
          >
            <CameraView
              hasPermission={true}
              openSettings={() => Linking.openSettings()}
              title={title}
              isLoading={isLoading}
              onCloseModal={onCloseModal}
            />
          </Camera>
        </View>
      )}
    </Modal>
  );
};

const styles = StyleSheet.create({
  camera: {
    position: "relative",
    borderRadius: Spacing.sp2,
    overflow: "hidden",
    alignSelf: "center",
    ...shadow,
  },
  cameraView: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: "rgba(0,0,0,0.1)",
    zIndex: 20,
  },
  instruction: {
    marginTop: Spacing.sp2,
  },
});

export default Scanner;
