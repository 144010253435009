import React from "react";
import { useTranslation } from "react-i18next";
import Review from "../../../components/Review";

interface ReviewServiceProps {}

const ReviewService: React.FC<ReviewServiceProps> = () => {
  const { t } = useTranslation();

  return (
    <Review
      question={t("guest.review.service")}
      step={1}
      totalSteps={5}
      questionType="service"
      redirectTo="review-value"
    />
  );
};

export default ReviewService;
