import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Text, Button, Spacing } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface PaymentCancelledProps {
  tryAgain: () => void;
}

const PaymentCancelled: React.FC<PaymentCancelledProps> = ({ tryAgain }) => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.indent}>
          <Text type="h4" textAlign="center" style={styles.text}>
            {t("guest.payment.cancelled.header")}
          </Text>
          <Text textAlign="center">{t("guest.payment.cancelled.text")}</Text>
        </View>
      </ScrollView>
      <Button
        label={t("guest.payment.cancelled.retry")}
        onPress={tryAgain}
        size="full"
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingTop: Spacing.sp10,
  },
  indent: {
    paddingHorizontal: Spacing.sp2,
  },
  text: {
    marginBottom: 8,
  },
});

export default PaymentCancelled;
