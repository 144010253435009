import React, { useContext } from "react";
import { View, StyleSheet } from "react-native";
import KeyboardAvoidedView from "../../../components/KeyboardAvoidedView";
import { formatMoney } from "../../../components/utils/formatMoney";
import {
  Button,
  Text,
  Spacing,
  ThemeContext,
  TextButton,
  XLTextInput,
} from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface GiftcardPayScreenProps {
  onInputChange: (text: string) => void;
  onFocusedInput: () => void;
  proceed: () => void;
  goBack: () => void;
  inputValue: string;
  inputIsValid: boolean;
  amountPlusTip: number;
  balance: number;
}

const GiftcardPayScreen: React.FC<GiftcardPayScreenProps> = ({
  proceed,
  goBack,
  onInputChange,
  onFocusedInput,
  inputValue,
  inputIsValid,
  amountPlusTip,
  balance,
}) => {
  const { grey } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <KeyboardAvoidedView style={styles.container}>
      <View style={styles.section}>
        <View style={styles.row}>
          <Text type="h3">{t("guest.payment.giftcard.amount")}</Text>
          <Text type="h3">{formatMoney(amountPlusTip)}</Text>
        </View>

        <View style={[styles.hr, { borderColor: grey[1] }]} />

        <View style={styles.row}>
          <Text type="h3">{t("guest.payment.giftcard.balance")}</Text>
          <Text type="h3">{formatMoney(balance)}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text textAlign="center" style={styles.inputLabel}>
          {t("guest.payment.giftcard.label")}
        </Text>
        <XLTextInput
          keyboardType="decimal-pad"
          onChangeText={onInputChange}
          value={inputValue}
          onFocus={onFocusedInput}
          size="small"
          textAlign="center"
          containerStyle={styles.input}
        />
      </View>

      <View>
        <TextButton
          label={t("guest.payment.giftcard.skip")}
          onPress={goBack}
          style={styles.textButton}
        />
        <Button
          label={t("ui.proceed")}
          onPress={proceed}
          disabled={!inputIsValid}
          size="full"
        />
      </View>
    </KeyboardAvoidedView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingTop: Spacing.sp2,
  },
  section: {
    paddingHorizontal: Spacing.sp2,
  },
  hr: {
    borderWidth: 1,
    marginVertical: Spacing.sp2,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputLabel: {
    marginBottom: Spacing.sp1,
  },
  input: {
    alignSelf: "center",
  },
  textButton: {
    alignSelf: "center",
    marginBottom: Spacing.sp3,
  },
});

export default GiftcardPayScreen;
