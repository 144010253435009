import { Action } from "redux";

export interface Property {
  code: string;
  id: string;
  name: string;
  pos: string;
}

export interface Dictionary<T> {
  [key: string]: T;
}

export interface PropertyState {
  entities?: Dictionary<Property>;
  ids?: string[];
  selectedId?: string;
}

export enum PropertyActionTypes {
  SET_PROPERTIES = "@@property/SET_PROPERTIES",
  SET_SELECTED_PROPERTY_ID = "@@property/SET_SELECTED_PROPERTY_ID",
}

interface SetProperties extends Action<PropertyActionTypes.SET_PROPERTIES> {
  payload: {
    entities: Dictionary<Property>;
    ids: string[];
  };
}
interface SetSelectedPropertyId
  extends Action<PropertyActionTypes.SET_SELECTED_PROPERTY_ID> {
  payload: string;
}

export type PropertyActions = SetProperties | SetSelectedPropertyId;
