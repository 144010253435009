import { SettingsActionTypes, SettingsState, SettingsActions } from "./types";

const initialState: SettingsState = {};

export default (
  state: SettingsState = initialState,
  action: SettingsActions
) => {
  switch (action.type) {
    case SettingsActionTypes.SET_PIN_TERMINAL: {
      return { ...state, pinTerminal: action.payload };
    }
    case SettingsActionTypes.SET_SELECTED_PRINTER: {
      return {
        ...state,
        printer: { ...state.printer, selected: action.payload },
      };
    }
    case SettingsActionTypes.SET_PRINTER_ENABLED: {
      return {
        ...state,
        printer: { ...state.printer, enabled: action.payload },
      };
    }
    case SettingsActionTypes.SET_SHOULD_ASK_FOR_PRINTER: {
      return {
        ...state,
        shouldAskForPrinter: action.payload,
      };
    }
    default:
      return state;
  }
};
