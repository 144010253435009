import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GuestLoginTypes } from "../../account/login/GuestLoginContainer";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";
import { AppState } from "../../../store/types";
import RoomTabOptionsScreen from "./RoomTabOptionsScreen";
import { submitPayment } from "../../../store/payment/actions";

interface RoomTabOptionsContainerProps {
  navigation: GuestNavProp;
}

const RoomTabOptionsContainer: React.FC<RoomTabOptionsContainerProps> = ({
  navigation,
}) => {
  const isSignedIn = useSelector(
    ({ account }: AppState) => !!account.guest?.type
  );
  const dispatch = useDispatch();

  const onLoginPress = () => {
    navigation.push("auth", {
      screen: "login",
      params: { loginType: GuestLoginTypes.ROOM_TAB },
    });
  };
  const onNoLoginPress = () => {
    navigation.push("room-tab-verify");
  };

  const onNoAuthPress = () => {
    navigation.push("auth-instructions");
  };

  const submitOnMount = async () => {
    await dispatch(
      submitPayment({
        paymentMethod: {
          method: "room_tab",
        },
        skipToken: false,
      })
    );
  };

  useEffect(() => {
    if (!isSignedIn) return;
    submitOnMount();
  }, [isSignedIn]);

  return (
    <RoomTabOptionsScreen
      onLoginPress={onLoginPress}
      onNoLoginPress={onNoLoginPress}
      onNoAuthPress={onNoAuthPress}
    />
  );
};

export default RoomTabOptionsContainer;
