export default {
  account: {
    form: {
      email: "Email address",
      emailInvalid: "Check if your email address is correct",
      emailPlaceholder: "Enter your email address",
      pass: "Password",
      required: "Your {{value}} is required",
      title: "Salutation",
      titles: ["Mr.", "Mrs.", "Fam."],
      name: "Name",
      firstname: "First name",
      middlename: "Middle name",
      lastname: "Surname",
      address: "Address",
      housenumber: "Number",
      postalcode: "Postal code",
      country: "Country",
      city: "City",
      street: "Street",
      mobile: "Telephone",
      check: "Check if your {{value}} is correct",
      updateSuccess: "Your details were updated successfully",
    },
    login: {
      appleButton: "Continue with Apple",
      button: "Login",
      info: {
        roomTab: "To be able to book on a room account, you must first log in",
        voucher: "To be able to redeem your vouchers, you must log in first.",
      },
      fbButton: "Continue with Facebook",
      forgotPass: "Forgot your password?",
      noAccount: "No account?",
      text: {
        roomTab:
          "Then you have not booked directly with us. On the previous page you can still book on room tab with your F(X) number and room number.",
        voucher:
          "Then you do not currently have any vouchers. You can easily create an account after paying.",
      },
    },
    password: {
      button: "Send instructions",
      text:
        "Not to worry, all you have to do is enter your email address. We will then mail you the instructions.",
      title: "Forgot your password?",
    },
    titles: {
      login: "Login",
      register: "Register",
      details: "Your details",
    },
  },
  common: {
    amountLeft: "To be paid",
    confirm: "Confirm",
    name: "Name",
    noInternet: "No internet connection",
    noThanks: "No, thanks",
    otherPaymentMethod: "Other payment method",
    redirect: "Back to the receipt",
    refresh: "Refresh page",
    retry: "Try again",
    search: "Search",
    searchEmpty:
      "Unfortunately no results were found for the search entry. Please try again.",
    skip: "Skip",
    startReview: "Give a review",
    total: "Total: ",
    totalAmount: "Total amount",
    tip: "Tip",
  },
  employee: {
    hotel: {
      fetchError: "Something went wrong fetching the hotels",
    },
    login: {
      button: "Login",
      changeHotel: "Change hotel",
      code: "Login",
      codePlaceholder: "Enter your personal login code",
      header: "ValkPay Login",
      hotel: "Hotel",
      scanTitle: "Scan the QR code",
    },
    navigation: {
      tabs: {
        pay: "Pay",
        settings: "Settings",
      },
      titles: {
        createQr: "Valk Pay payment",
        options: "Payment method",
        pin: "Pin terminal",
        print: "Printer",
        success: "Payment processed",
        tab: "Table number: {{table}}",
        table: "Valk Pay Employee",
        valkpay: "Valk Pay",
      },
    },
    options: {
      choose: "Choose the desired payment method",
      pos: "Debit card or credit card",
      room_tab: "Room tab",
      valkpay: "ValkPay",
    },
    payment: {
      return: "Back to home screen",
      success: "Payment has been processed successfully!",
    },
    pin: {
      error: {
        heading: "Something went wrong with the payment",
        content:
          "Try again, select another pin terminal or select another payment method.",
        reselect: "Other pin terminal",
      },
      header: "Select the correct pin terminal",
      noTerminal: "No pin terminals found",
      noTerminalFix: "Check if pin terminals are connected and try again.",
      processing: "Pin amount is sent \nto the Pin terminal {{terminalNr}}...",
      progress: "Retrieving payment status...",
      searching: "Searching for available pin terminals...",
      select: "Select a pin terminal",
      submit: "Select pin terminal",
    },
    printer: {
      error: {
        content: "Try again or proceed without printing.",
        heading: "Something went wrong with the printer",
        proformaContent: "Try again or go back to receipt.",
        proformaSkip: "Go back to receipt",
        reselect: "Other printer",
        skip: "Proceed without printing",
      },
      fetchError: "Something went wrong fetching the available printers",
      header: "Select a printer",
      noPrinter: "No printers are currently found.",
      noPrinterFix: "Check if printers are connected and try again.",
      processing: "The receipt is being printed...",
      searching: "Searching for available printers...",
      select: "Select a printer",
      shouldAskForPrinter: "Ask for printer selection for each payment",
    },
    progress: {
      cash: {
        header: "Did the guest just pay in cash?",
        submit: "Register payment",
      },
      pin: {
        header: "Pin terminal number",
        label: "Enter the pin terminal number",
        processing: "Pin amount is sent \nto the Pin terminal...",
      },
    },
    qrInstruction: "The guest can scan this QR code to pay with Valk Pay",
    roomTab: {
      amount: "Amount",
      checked: "I checked the guest name",
      denied: {
        heading: "Unable to pay on room tab for room {{room}}.",
        content:
          "Paying on room tab is only possible if the guest has granted a payment guarantee.",
        otherRoom: "Try another room",
      },
      failed: {
        heading: "Something went wrong with the selected payment method.",
        content: "Please try again or choose a different payment method.",
      },
      label: "Enter room number",
      name: "Guest name",
    },
    selectHotel: "Select your hotel to log in",
    tab: {
      voucher: "Voucher?",
    },
    table: {
      label: "Enter table number",
      proceed: "Collect receipt",
      welcome: "Welcome\n",
    },
    tip: {
      header: "Does the guest wish to leave a tip?",
      input: {
        total: "Edit total",
        tip: "Add tip",
      },
      amountWithoutTip: "Total without tip",
    },
    update: {
      new: "The new update contains:",
      button: "Download update",
      error:
        "The app update failed. Check your internet connection and try again please.",
      info:
        "A new version of the Valk Pay app is available. Download the update to continue.",
      subTitle: "Download now the new update",
      title: "New Valk Pay Update!",
    },
    voucher: {
      add: "Add a voucher",
      complete: "Complete",
      empty: "No voucher has been added yet.",
      loading: "The receipt is being updated. \nPlease wait...",
      proceed: "Yes, scan the voucher",
      processing: "Vouchers are being redeemed...",
      remove: "Remove",
      scanTitle: "Scan voucher",
      skip: "Skip this step",
      text: "Does the guest have a voucher that can be redeemed?",
      title: "Vouchers",
      warning:
        "Once the amount has been sent to the payment terminal, it is no longer possible to exchange a voucher.",
    },
  },
  guest: {
    error: {
      appleLogin: "Continue with Apple failed",
      facebookLogin: {
        no_account:
          "No account could be found. Please try again or login without Facebook.",
        user_denied:
          "You have denied access to your Facebook account, please try again or login without Facebook.",
      },
      alreadyPaid: "Your receipt has already been paid",
      giftcardBalance: "There is no balance left on your Valk Cadeaucard",
      giftcardPin: "The cadeaucard pin is missing \nEnter your PIN here:",
      noParams: "The payment status cannot be retrieved",
      noReceipt: "No receipt has been scanned",
    },
    payment: {
      cancelled: {
        header: "Payment cancelled",
        retry: "Select your payment method",
        text: "Would you like to choose another payment method?",
      },
      failed: {
        header: "Payment failed",
        retry: "Try again",
        text: "Please try again.",
      },
      giftcard: {
        amount: "To be paid",
        balance: "Your balance",
        cancel: "Cancel giftcard",
        label: "Select the amount \nyou want to redeem",
        number: "What is your giftcard number?",
        pay: "Pay",
        pin: "What is your giftcard \npin?",
        receipt: "Your account",
        scanTitle: "Scan the barcode",
        skip: "Choose another payment method",
      },
      options: {
        header: "Select your payment method",
        ideal: {
          header: "Select your bank",
        },
        roomtab: "Room tab",
      },
      processing: "Processing payment...",
      receipt: {
        amount: "Receipt",
        giftcard: "Valk Cadeaucard",
        title: "Your bill",
      },
      roomtab: {
        abort: "Choose another payment method",
        account: "Booked with a Valk Account",
        auth: {
          instructions: [
            {
              extra:
                "(If you have a Valk account, you can also do this online.)",
              message:
                "Make a request at the reception desk to authorize your credit card.",
            },
            {
              message: "Please enter your credit card information.",
            },
            {
              message: "Accept the terms.",
            },
            {
              message:
                "You can book everything on your room tab during your stay.",
            },
          ],
        },
        authorize: "How can I give a payment guarantee?",
        book: "Book on my room tab",
        check: "Check data",
        consent: "I authorize the booking of this receipt on my room tab.",
        folio: "F(X) number",
        folioInfo:
          "To verify the authorization, we request that you enter your F(X) number and room number.",
        folioQuestion: "Where can I find my F(X) number?",
        help:
          "If there are any problems during the process, the reception can always be consulted.",
        info:
          "With ValkPay we offer you the possibility to book on room account. For this you need to have provided us with a credit card authorisation.",
        invalidProfile:
          "Unfortunately, it is not possible to book on a room account at the moment.",
        noAccount: "No Valk Account",
        noAuth: "Payment guarantee required",
        noAuthInfo: "You have not given a payment guarantee yet.",
        noAuthYet: "I haven't given a payment guarantee yet",
        notFound:
          "The F(X) number and/or room number is incorrect. Please try again.",
        options: "Choose one of these options to continue:",
        proceed: "Verify",
        room: "Room number",
        roomcode: {
          instructions: [
            {
              message:
                "Your F(X) number can be found in your confirmation email.",
            },
            {
              message:
                "Your F(X) number can be found on the holder of the room card.",
            },
          ],
        },
      },
      success: {
        header: "Thank you. \nPayment succeeded!",
        message:
          "We would love to hear if you had a pleasant restaurant experience.",
        text: "Give a review and win a Valk Cadeacard worth",
        value: " €20,-",
        method: {
          other: "Thank you. \nPayment succeeded!",
          roomtab:
            "Thank you. \nYour receipt has been booked on your room tab!",
        },
        promo: {
          phone: "This requires your mobile number.",
          proceed: "Yes, I want to win an overnight stay",
          subHeader:
            "Win an overnight stay at the brand new hotel Amsterdam-Zuidas?",
        },
      },
      tip: {
        custom: "Custom input",
        header: "Would you like to tip?",
        selection: "Select",
        subHeader: "Little effort, great fun",
      },
    },
    promo: {
      conditions: "Terms and conditions",
      info:
        "Every 500th payment receives an overnight stay in the brand new hotel Amsterdam-Zuidas.",
      phone: {
        conditions: "terms and conditions",
        confirm: "Confirm mobile number",
        consent: "I agree to the ",
        header: "Win overnight stay",
        label: "Mobile number",
        text: "Enter your phone number to win the overnight stay.",
        textExtra:
          "You will also receive an SMS from Valk Exclusief with \n €5 Valk Bonus credit on your Valk Exclusief account.",
      },
      sms: {
        eta: "It may take a few minutes before you receive the text message.",
        header: "Text message is being sent!",
        info:
          "This message contains the instructions to complete your participation.",
      },
    },
    review: {
      create: "Write a review",
      addComment: "Add a comment",
      email: {
        header: "Submit your review",
        proceed: "Send",
        text: "Enter your email address and win a Valk Cadeacard worth",
        textBold: " €20,-",
      },
      food: "How did you like the food?",
      rateUs: "Rate us",
      service: "How did you like the service?",
      thanks: "Thank you for your rating!",
      tikkie: "Want to share the bill? Send a Tikkie.",
      valkpay: "How did you experience this new ValkPay service?",
      value: "How would you rate the value for money?",
    },
    tikkie: {
      goBack: "Go back",
      person: "1 person",
      person_plural: "{{count}} persons",
      proceed: "Send Tikkie",
      text:
        "Select the number of people \nwith whom you want to share the receipt",
      title: "Number of persons",
    },
    titles: {
      roomTab: "Book to room bill",
      roomTabVerify: "Verify room number",
    },
    voucher: {
      applySuccess: "Vouchers added successfully",
      confirmSuccess: "Vouchers redeemed successfully.",
      removeSuccess: "Vouchers removed successfully.",
      fetching: "Fetching vouchers..",
      hasBooking: "Have you booked a room in the hotel?",
      hasNoClaimableVouchers:
        "No vouchers are available based on your receipt.",
      hasNoVouchers: "You do not have vouchers on this account.",
      ineligibleArticles: "You have the option of using vouchers.",
      redeem: "Redeem vouchers",
      title: "Vouchers",
      tryAnotherAccount: "Try another account",
    },
    welcome: {
      greetings: "Hope to see you again! \nFamily van der Valk",
      text:
        "We hope you had a pleasant restaurant experience. We will ask you to share your opinion by answering <0>3 questions</0> after payment.",
      thanks: "Thank you for your visit.",
    },
  },
  scanner: {
    instructions: "Open your settings and give Valk Pay access to your camera.",
    noAccess: "Camera is turned off",
    openSettings: "Open settings",
  },
  tab: {
    description: "Description",
    ep: "E.P.",
    qty: "Qty",
    servedBy: "Served by: ",
    total: "Total",
  },
  ui: {
    attention: "Attention:",
    cancel: "Cancel",
    close: "Close",
    error: {
      close: "Close notification",
      title: "Something went wrong:\n",
    },
    errorPageTitle: "Something went wrong",
    loading: "Loading...",
    notice: "Notice",
    ok: "OK",
    proceed: "Continue",
    signOut: "Sign out",
    unknownError: "An unknown error occurred, please try again later",
  },
};
