import { UIActions, UIActionTypes } from "./types";
import { Keyboard } from "react-native";
import { AppThunk } from "../types";

export const setError = (error: string): UIActions => {
  Keyboard.dismiss();
  return {
    type: UIActionTypes.SET_ERROR,
    payload: error,
  };
};

export const setGiftcardError = (show: boolean): UIActions => {
  Keyboard.dismiss();
  return {
    type: UIActionTypes.SHOW_GIFTCARD_ERROR,
    payload: show,
  };
};

let timer: NodeJS.Timeout;
export const setNotification = ({
  content,
  type,
}: {
  content: string;
  type: "positive" | "negative";
}): AppThunk => async (dispatch) => {
  let time = 3000;
  // Every 180 extra charachters should add 1 second to the timer
  time += Math.floor(content.length / 180) * 1000;
  clearTimeout(timer);
  timer = setTimeout(() => {
    dispatch({ type: UIActionTypes.SET_NOTIFICATION, payload: undefined });
  }, time);

  dispatch({
    type: UIActionTypes.SET_NOTIFICATION,
    payload: { content, type },
  });
  Keyboard.dismiss();
};
