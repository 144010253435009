import React from "react";
import CheckMark from "../../../components/Svg/CheckMark";
import { StyleSheet, ScrollView } from "react-native";
import { Text, Spacing, Button } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface ReviewSuccessScreenProps {
  proceed: () => void;
}

const ReviewSuccessScreen: React.FC<ReviewSuccessScreenProps> = ({
  proceed,
}: ReviewSuccessScreenProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollView contentContainerStyle={styles.container}>
        <CheckMark style={styles.checkMark} />
        <Text textAlign="center" type="h3" style={styles.header}>
          {t("guest.review.thanks")}
        </Text>
        <Text textAlign="center">{t("guest.review.tikkie")}</Text>
      </ScrollView>

      <Button
        label={t("ui.proceed")}
        onPress={proceed}
        color="#4db6ac"
        size="full"
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    padding: Spacing.sp2,
  },
  checkMark: {
    marginBottom: Spacing.sp2,
    width: 56,
    height: 56,
    alignSelf: "center",
  },
  header: {
    marginBottom: Spacing.sp2,
  },
});

export default ReviewSuccessScreen;
