import React, { useState } from "react";
import RoomTabScreen from "./RoomTabConfirmScreen";
import { AppState } from "../../../store/types";
import { useDispatch, useSelector } from "react-redux";
import { formatMoney } from "../../../components/utils/formatMoney";
import { submitPayment } from "../../../store/payment/actions";

interface RoomTabConfirmContainerProps {}

const RoomTabConfirmContainer: React.FC<RoomTabConfirmContainerProps> = ({}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const receipt = useSelector(({ receipt }: AppState) => receipt);
  const payment = useSelector(({ payment }: AppState) => {
    if (payment?.method === "room_tab") {
      return payment;
    }
  });
  const dispatch = useDispatch();

  const { reservationId, name, room } = payment ?? {};
  const { amountPlusTip } = receipt;
  const amount = formatMoney(amountPlusTip);

  const onCheckboxChange = () => {
    setChecked(!checked);
  };

  const onConfirm = async () => {
    await dispatch(
      submitPayment({
        setIsLoading,
        paymentMethod: {
          method: "room_tab",
          reservationId,
          confirm: true,
        },
      })
    );
  };

  return (
    <RoomTabScreen
      name={name}
      room={room}
      checked={checked}
      amount={amount}
      onCheckboxChange={onCheckboxChange}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  );
};

export default RoomTabConfirmContainer;
