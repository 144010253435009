import React from "react";
import Review from "../../../components/Review";
import { useTranslation } from "react-i18next";

interface ReviewValueProps {}

const ReviewValue: React.FC<ReviewValueProps> = () => {
  const { t } = useTranslation();

  return (
    <Review
      question={t("guest.review.value")}
      step={2}
      totalSteps={5}
      questionType="value"
      redirectTo="review-valkpay"
      smallerTitle={true}
    />
  );
};

export default ReviewValue;
