import { ReviewActionTypes, ReviewState, ReviewActions } from "./types";
import { UIActionTypes } from "../ui/types";

const initialState: ReviewState = {
  ratings: [],
};

export default (state: ReviewState = initialState, action: ReviewActions) => {
  switch (action.type) {
    case ReviewActionTypes.ADD_RATING: {
      return { ...state, ...action.payload };
    }
    case UIActionTypes.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};
