import React from "react";
import { StackNavigationOptions } from "@react-navigation/stack";
import { BottomTabNavigationOptions } from "@react-navigation/bottom-tabs";
import { Platform, StyleSheet } from "react-native";
import { Fonts, Icon, Spacing } from "@valkdigital/ui-kit";
import HeaderButton from "../components/HeaderButton";
import { goBack } from "./rootNavigation";

export const tabBarConfig: BottomTabNavigationOptions = {
  tabBarLabelStyle: {
    fontFamily: Fonts.Regular,
    fontSize: 13,
  },
  tabBarHideOnKeyboard: false,
};

export const stackScreenOptions: StackNavigationOptions = {
  headerShown: Platform.OS !== "web",
  headerBackTitleVisible: false,
  headerTitleStyle: {
    fontSize: 18,
    fontFamily: Fonts.SemiBold,
  },
  headerTitleAlign: "center",
  cardStyle: { flex: 1 },
};

export const noModalOptions: StackNavigationOptions = {
  headerShown: false,
};
export const modalOptions: StackNavigationOptions = {
  ...stackScreenOptions,
  headerShown: true,
  headerLeft: ({ tintColor }) => {
    return (
      <HeaderButton onPress={goBack}>
        <Icon name="close" style={styles.close} color={tintColor} />
      </HeaderButton>
    );
  },
};

const styles = StyleSheet.create({
  close: {
    marginLeft: Spacing.sp2,
  },
});
