import React, { useEffect, useRef, useState } from "react";
import Review from "./Review";
import { useDispatch, useSelector } from "react-redux";
import { addRating } from "../../store/review/actions";
import { AppState } from "../../store/types";
import { GuestNavProp, GuestRoutes } from "../../navigation/guest/GuestRoutes";
import { useNavigation } from "@react-navigation/native";
import { TextInputType } from "@valkdigital/ui-kit";

interface ReviewContainerProps {
  question: string;
  step: number;
  totalSteps: number;
  questionType: string;
  redirectTo: GuestRoutes;
  smallerTitle?: boolean;
}
const ReviewContainer: React.FC<ReviewContainerProps> = ({
  question,
  step,
  totalSteps,
  questionType,
  redirectTo,
  smallerTitle,
}) => {
  const ratings = useSelector(({ review: { ratings } }: AppState) => ratings);
  const [sliderValue, setSliderValue] = useState<number>(
    ratings[step] ? ratings[step].value : 10
  );
  const [showComment, setShowComment] = useState<boolean>(false);
  const [comment, setComment] = useState<string>(
    ratings[step] ? ratings[step].comments : ""
  );
  const navigation = useNavigation<GuestNavProp>();
  const dispatch = useDispatch();
  const inputRef = useRef<TextInputType>(null);

  const currentProgress: number = ((step + 1) / totalSteps) * 100;

  const onSliderValueChange = (value: number) => {
    setSliderValue(value);
  };

  const toggleShowComment = () => {
    setShowComment(!showComment);
  };

  const onCommentChange = (text: string) => {
    setComment(text);
  };

  const proceed = () => {
    dispatch(
      addRating(
        { type: questionType, value: sliderValue, comments: comment },
        ratings,
        questionType
      )
    );
    navigation.push(redirectTo);
  };

  useEffect(() => {
    if (showComment) inputRef.current?.focus();
  }, [showComment]);

  return (
    <Review
      question={question}
      currentProgress={currentProgress}
      sliderValue={sliderValue}
      comment={comment}
      onSliderValueChange={onSliderValueChange}
      onCommentChange={onCommentChange}
      proceed={proceed}
      showComment={showComment}
      smallerTitle={smallerTitle}
      toggleShowComment={toggleShowComment}
      inputRef={inputRef}
    />
  );
};

export default ReviewContainer;
