import { ImageSourcePropType } from "react-native";
import { Bank } from "../../../store/payment/types";

export interface IdealLinks {
  [key: string]: {
    link: Bank;
    imageFile: ImageSourcePropType;
  };
}

export const links: IdealLinks = {
  ING: {
    link: "ing",
    imageFile: require("../../../../assets/images/ideal/ing.png"),
  },
  "ABN Amro Bank": {
    link: "abn_amro",
    imageFile: require("../../../../assets/images/ideal/abn_amro.png"),
  },
  "ASN Bank": {
    link: "asn",
    imageFile: require("../../../../assets/images/ideal/asn.png"),
  },
  Knab: {
    link: "knab",
    imageFile: require("../../../../assets/images/ideal/knab.png"),
  },
  Moneyou: {
    link: "moneyou",
    imageFile: require("../../../../assets/images/ideal/moneyou.png"),
  },
  Rabobank: {
    link: "rabobank",
    imageFile: require("../../../../assets/images/ideal/rabobank.png"),
  },
  Regiobank: {
    link: "regiobank",
    imageFile: require("../../../../assets/images/ideal/regiobank.png"),
  },
  "SNS Bank": {
    link: "sns",
    imageFile: require("../../../../assets/images/ideal/sns.png"),
  },
  "Triodos Bank": {
    link: "triodos",
    imageFile: require("../../../../assets/images/ideal/triodos.png"),
  },
  "Van Lanschot Bankiers": {
    link: "van_lanschot",
    imageFile: require("../../../../assets/images/ideal/van_lanschot.png"),
  },
  Bunq: {
    link: "bunq",
    imageFile: require("../../../../assets/images/ideal/bunq.png"),
  },
};
