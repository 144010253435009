import {
  ReceiptActionTypes,
  ReceiptState,
  ReceiptActions,
  TipMode,
} from "./types";
import { UIActionTypes } from "../ui/types";

const initialState: ReceiptState = {
  id: "",
  amount: 0,
  tip: { tipAmount: 0, tipMode: TipMode.RADIO },
  amountPlusTip: 0,
  property: "",
  propertyCode: "",
  printerId: undefined,
  table: "",
};

export default (
  state: ReceiptState = initialState,
  action: ReceiptActions
): ReceiptState => {
  switch (action.type) {
    case ReceiptActionTypes.SET_RECEIPT: {
      return { ...state, ...action.payload };
    }
    case ReceiptActionTypes.ADD_TIP: {
      return { ...state, tip: { ...state.tip, tipAmount: action.payload } };
    }
    case ReceiptActionTypes.SELECT_TIP_MODE: {
      return { ...state, tip: { ...state.tip, tipMode: action.payload } };
    }
    case UIActionTypes.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};
