import React, { useState, useRef, useEffect } from "react";
import GuestLoginScreen from "./GuestLoginScreen";
import { guestLogin } from "../../../store/account/actions";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import l from "../../../locale";
import u from "../../../api/helpers";
import {
  AuthNavProp,
  AuthStackParamList,
} from "../../../navigation/guest/AuthRoutes";
import { CommonActions, RouteProp } from "@react-navigation/native";
import api, { handleErrors } from "../../../api";
import { appleAuthHelpers, useScript } from "react-apple-signin-auth";
import ENV from "../../../../environment";
import { setError } from "../../../store/ui/actions";
import {
  GuestNavProp,
  GuestStackParamList,
} from "../../../navigation/guest/GuestRoutes";
import { useTranslation } from "react-i18next";
import { TextInputType } from "@valkdigital/ui-kit";

interface AppleRequestResponse {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
}

export type FormData = {
  email?: string;
  password?: string;
};
export type FormValidation = {
  isValid: boolean;
  errors: FormData;
};
export enum GuestLoginTypes {
  VOUCHER = "voucher",
  ROOM_TAB = "roomTab",
}

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(l.t("account.form.emailInvalid"))
    .required(
      l.t("account.form.required", {
        value: l.t("account.form.email").toLowerCase(),
      })
    ),
  password: Yup.string().required(
    l.t("account.form.required", {
      value: l.t("account.form.pass").toLowerCase(),
    })
  ),
});

interface GuestLoginContainerProps {
  navigation: AuthNavProp & GuestNavProp;
  route: RouteProp<AuthStackParamList, "login">;
}

const GuestLoginContainer: React.FC<GuestLoginContainerProps> = ({
  navigation,
  route,
}) => {
  const [values, setValues] = useState<FormData>({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fbLoading, setFbLoading] = useState<boolean>(false);
  const [appleLoading, setAppleLoading] = useState<boolean>(false);
  const [validation, setValidation] = useState<FormValidation>({
    isValid: false,
    errors: {},
  });
  const [formErrors, setFormErrors] = useState<FormValidation["errors"]>({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRef = useRef<TextInputType>(null);
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  const loginType = route.params?.loginType;

  const validate = async () => {
    const result = await u.Form.validate(LoginSchema, values);
    if (result == null) {
      setValidation({ isValid: true, errors: {} });
    } else {
      setValidation({ isValid: false, errors: result });
    }
  };

  const focusPassword = () => {
    inputRef?.current?.focus();
  };

  const onSubmit = async () => {
    if (!validation.isValid) return;
    setIsLoading(true);
    try {
      const { token, refreshToken } = await api.Account.guestLogin({
        username: values.email ?? "",
        password: values.password ?? "",
      });
      updateTokens(token, refreshToken);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const forgotPassword = () => {
    navigation.push("password");
  };

  const updateTokens = async (token: string, refreshToken: string) => {
    await u.Auth.update(token, refreshToken);
    const account = await api.Account.current();
    dispatch(guestLogin(account.data));
    navigateToNextScreen();
  };

  const navigateTo = (name: keyof GuestStackParamList) => {
    const canGoBack = navigation.canGoBack();
    if (canGoBack) {
      navigation.navigate(name);
      return;
    }

    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: "guest",
            state: {
              routes: [
                {
                  name,
                },
              ],
            },
          },
        ],
      })
    );
  };

  const navigateToNextScreen = () => {
    switch (loginType) {
      case GuestLoginTypes.ROOM_TAB:
        navigateTo("room-tab-options");
        break;
      case GuestLoginTypes.VOUCHER:
        navigateTo("voucher-receipt");
        break;
      default:
        break;
    }
  };

  const onFbLogin = async () => {
    setFbLoading(true);
    try {
      const response = await api.Account.facebookLogin(
        window.location.pathname + window.location.search
      );
      window.location.href = response.redirectUri;
    } catch (error) {
      handleErrors(error);
      setFbLoading(false);
    }
  };

  const signInWithApple = async (response: AppleRequestResponse) => {
    try {
      const { token, refreshToken } = await api.Account.signInWithApple(
        response.authorization.id_token
      );
      await updateTokens(token, refreshToken);
    } catch (error) {
      handleErrors(error);
    }
  };

  const onAppleLogin = () => {
    setAppleLoading(true);
    appleAuthHelpers.signIn({
      authOptions: {
        clientId: "nl.valkdigital.valkpay.service",
        scope: "email name",
        redirectURI: ENV.url,
        nonce: "nonce",
        usePopup: true,
      },
      onSuccess: async (response: AppleRequestResponse) => {
        await signInWithApple(response);
        setAppleLoading(false);
      },
      onError: () => {
        setAppleLoading(false);
        dispatch(setError(t("guest.error.appleLogin")));
      },
    });
  };

  useEffect(() => {
    const error = route.params?.error;
    if (!error) return;
    dispatch(
      setError(
        t(
          `guest.error.facebookLogin.${Array.isArray(error) ? error[0] : error}`
        )
      )
    );
  }, [route.params]);

  useEffect(() => {
    validate();
  }, [values]);

  return (
    <GuestLoginScreen
      inputRef={inputRef}
      values={values}
      validation={validation}
      focusPassword={focusPassword}
      forgotPassword={forgotPassword}
      setValues={setValues}
      onSubmit={onSubmit}
      isLoading={isLoading}
      loginType={loginType}
      formErrors={formErrors}
      setFormErrors={setFormErrors}
      onFbLogin={onFbLogin}
      onAppleLogin={onAppleLogin}
      fbLoading={fbLoading}
      appleLoading={appleLoading}
    />
  );
};

export default GuestLoginContainer;
