import { Platform } from "react-native";

interface Mode {
  mode: string;
  url: string;
  apiUrl: string;
  merchantName: string;
  merchantIdentifier: string;
}

interface Environment {
  dev: Mode;
  staging: Mode;
  prod: Mode;
}

const ENV: Environment = {
  dev: {
    mode: "dev",
    url: "https://localhost:19006",
    apiUrl: "app-staging.roomserve.nl",
    merchantName: "Valk Digital Test",
    merchantIdentifier: "merchant.com.adyen.valkexclusief.test",
  },
  staging: {
    mode: "staging",
    url: "https://app-staging.valkpay.nl",
    apiUrl: "app-staging.roomserve.nl",
    merchantName: "Valk Digital",
    merchantIdentifier: "merchant.com.adyen.valkexclusief.test",
  },
  prod: {
    mode: "prod",
    url: "https://app.valkpay.nl",
    apiUrl: "app.roomserve.nl",
    merchantName: "Valk Digital",
    merchantIdentifier: "merchant.com.adyen.valkexclusief",
  },
};

const getEnvVars = () => {
  if (Platform.OS === "web") {
    const { hostname } = window.location;
    switch (hostname) {
      case "localhost":
        return ENV.dev;
      case "app-staging.valkpay.nl":
        return ENV.staging;
      case "app.valkpay.nl":
        return ENV.prod;
      default:
        return ENV.dev;
    }
  } else {
    if (__DEV__) {
      return ENV.staging;
    }
    return ENV.prod;
  }
};

export default getEnvVars();
