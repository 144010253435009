export const createTipOptions = (amount: number): string[] => {
  const tenPercent = Math.round(amount * 0.001);
  const fivePercent = Math.round(amount * 0.0005);
  const fifteenPercent = Math.round(amount * 0.0015);

  if (tenPercent < 2) {
    return ["€0,50", "€1", "€2"];
  }
  if (tenPercent < 5) {
    return [-1, 0, 1].map((deviation) => "€" + (tenPercent + deviation));
  }
  if (tenPercent < 9) {
    return [-2, 0, 1].map((deviation) => "€" + (tenPercent + deviation));
  }
  return [fivePercent, tenPercent, fifteenPercent].map((x) => "€" + x);
};
