import { combineReducers } from "redux";
import account from "./account/reducer";
import settings from "./settings/reducer";
import tab from "./tab/reducer";
import payment from "./payment/reducer";
import property from "./property/reducer";
import receipt from "./receipt/reducer";
import giftcard from "./giftcard/reducer";
import review from "./review/reducer";
import ui from "./ui/reducer";
import promo from "./promo/reducer";
import { persistReducer, PersistConfig } from "redux-persist";
import storage from "./storage";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import { AppState } from "./types";

const rootPersistConfig: PersistConfig<AppState> = {
  key: "root",
  storage,
  blacklist: ["ui"],
  transforms: [createBlacklistFilter("giftcard", ["pin"])],
};

const rootReducer = combineReducers({
  account,
  settings,
  tab,
  payment,
  property,
  receipt,
  giftcard,
  review,
  ui,
  promo,
});

export default persistReducer(rootPersistConfig, rootReducer);
