import React from "react";
import { View, FlatList, StyleSheet } from "react-native";
import { NavigationTextButton, Spacing, Text } from "@valkdigital/ui-kit";
import { IdealLinks } from "./links";
import CustomLabel from "./CustomLabel";
import { useTranslation } from "react-i18next";

interface IdealScreenProps {
  links: IdealLinks;
  showSpinner: string;
  onLinkPress: (link: string) => void;
}

const IdealScreen: React.FC<IdealScreenProps> = ({
  links,
  onLinkPress,
  showSpinner,
}) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Text type="h3" style={styles.title}>
        {t("guest.payment.options.ideal.header")}
      </Text>
      <FlatList
        data={Object.keys(links)}
        keyExtractor={(_, index) => index.toString()}
        renderItem={({ item, index }) => (
          <NavigationTextButton
            showTopBorder={index === 0}
            onPress={() => onLinkPress(item)}
            customLabel={
              <CustomLabel
                item={item}
                links={links}
                showSpinner={showSpinner}
              />
            }
          />
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingTop: Spacing.sp2,
  },
  title: {
    paddingHorizontal: Spacing.sp2,
    paddingBottom: Spacing.sp2,
  },
});

export default IdealScreen;
