import React from "react";
import Svg, { Path, Text } from "react-native-svg";
import { SvgProps } from "./types";
import { Fonts } from "@valkdigital/ui-kit";

interface ListMarkerProps extends SvgProps {
  marker: string | number;
}

export default ({ marker, style }: ListMarkerProps) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={style}>
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#D9B475"
    />
    <Text
      fill="white"
      fontFamily={Fonts.Bold}
      fontSize={17}
      x="12"
      y="18"
      textAnchor="middle"
    >
      {marker}
    </Text>
  </Svg>
);
