import React from "react";
import { useTranslation } from "react-i18next";
import Review from "../../../components/Review";

interface ReviewFoodProps {}

const ReviewFood: React.FC<ReviewFoodProps> = () => {
  const { t } = useTranslation();

  return (
    <Review
      question={t("guest.review.food")}
      step={0}
      totalSteps={5}
      questionType="satisfaction"
      redirectTo="review-service"
    />
  );
};

export default ReviewFood;
