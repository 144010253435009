import React from "react";
import { ScrollView, StyleSheet } from "react-native";
import { Spacing, TextInput, Button, Text } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface PasswordScreenProps {
  email: string;
  setEmail: (email: string) => void;
  emailError: string;
  isValid: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  showError: boolean;
  setShowError: (showError: boolean) => void;
}

const PasswordScreen: React.FC<PasswordScreenProps> = ({
  email,
  setEmail,
  emailError,
  isValid,
  isLoading,
  onSubmit,
  showError,
  setShowError,
}) => {
  const { t } = useTranslation();

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text type="h3" style={styles.title}>
        {t("account.password.title")}
      </Text>

      <Text type="subtextRegular">{t("account.password.text")}</Text>

      <TextInput
        label={t("account.form.email")}
        value={email}
        autoCapitalize="none"
        placeholder={t("account.form.emailPlaceholder")}
        onChangeText={(text) => {
          setEmail(text);
          setShowError(false);
        }}
        keyboardType="email-address"
        onSubmitEditing={onSubmit}
        onBlur={() => setShowError(true)}
        error={showError && emailError}
        showCheckmark={!emailError}
        autoCorrect={false}
        containerStyle={styles.input}
      />

      <Button
        onPress={onSubmit}
        label={t("account.password.button")}
        containerStyle={styles.button}
        disabled={!isValid}
        loading={isLoading}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: Spacing.sp2,
    paddingTop: Spacing.sp3,
  },
  title: {
    marginBottom: Spacing.sp4,
  },
  input: {
    marginTop: Spacing.sp3,
  },
  button: {
    marginTop: Spacing.sp4,
  },
});

export default PasswordScreen;
