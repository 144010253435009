import React from "react";
import { View, StyleSheet, ScrollView, Platform } from "react-native";
import { Text, Spacing } from "@valkdigital/ui-kit";

interface PromoConditionsProps {}

const PromoConditions: React.FC<PromoConditionsProps> = () => {
  return (
    <View style={styles.container}>
      <ScrollView>
        <View
          onStartShouldSetResponder={() => true}
          style={styles.scrollContainer}
        >
          <Text type="subtextRegular" style={styles.conditions}>
            <Text type="h6">
              Algemeen
              {"\n"}
            </Text>
            1. Deze actievoorwaarden (hierna: de Actievoorwaarden) zijn van
            toepassing op de actie ’Van der Valk Exclusief – win een
            overnachting bij Van der Valk Hotel Zuidas’ (hierna: de Actie) ter
            promotie van Van der Valk Exclusief. {"\n"}
            2. De organisator van de Actie is Van der Valk Exclusief, gevestigd
            en kantoorhoudende Winthontlaan 4, 3526 KV Utrecht ingeschreven bij
            de Kamer van Koophandel onder nummer 27380247 (hierna: de
            Organisator). {"\n"}
            3. De Actie geldt in de hotels van de Organisator {"\n"}
            4. De Actie loopt van 2 juli - 31-12-2021, (hierna: de
            Actieperiode). {"\n"}
            5. Op de Actie zijn de Actievoorwaarden van de Organisator van
            toepassing.
            {"\n\n"}
            <Text type="h6">
              Inhoud van de Actie
              {"\n"}
            </Text>
            6. Als de gast in de Actieperiode met Valk Pay betaalt dan maakt
            hij/zij kans om een overnachting bij Van der Valk Hotel Zuidas te
            verdienen. {"\n"}
            7. Na het bevestigen van de betaling middels Valk Pay wordt de
            deelnemer gevraagd zijn/haar telefoonnummer in te vullen. {"\n"}
            8. Iedere 500e deelnemer/ betaling die permissie geeft wint een
            overnachting bij Van der Valk Hotel Zuidas. {"\n"}
            9. Deelname is alleen geldig indien het telefoonnummer naar waarheid
            is ingevuld.{"\n"}
            10. Gegevens van deelnemers zullen vertrouwelijk worden behandeld en
            verwerkt in overeenstemming met de privacywetgeving. Deze zijn te
            vinden op www.valkexclusief.nl
            {"\n\n"}
            <Text type="h6">Vereisten voor deelname {"\n"}</Text>
            11. Deelname aan de Actie is enkel toegestaan voor gasten die een
            Valk Account hebben. {"\n"}
            12. De Organisator behoudt zich het recht voor om de Actie eenzijdig
            te wijzigen, op te schorten en/of te beëindigen, mits dit niet ten
            nadele is van de deelnemer. Hiervan zal melding worden gedaan in de
            app. In geval van een redelijk vermoeden van onrechtmatige
            beïnvloeding van de Actie, misbruik of fraude behoudt de Organisator
            zich het recht voor om deelnames en/of deelnemers aan de Actie uit
            te sluiten.
            {"\n\n"}
            <Text type="h6">
              Vaststelling van de winnaar en prijsuitreiking
              {"\n"}
            </Text>
            13. De winnaars worden per telefoon of e-mail benaderd en zullen dan
            vernemen hoe de prijs in ontvangst wordt genomen. {"\n"}
            14. De prijs is niet inwisselbaar voor contanten en/of andere
            prijzen. {"\n"}
            15. In geval incorrecte en/of onvolledig opgegeven gegevens van een
            deelnemer, een redelijk vermoeden van onrechtmatige beïnvloeding van
            de Actie, misbruik of fraude behoudt de Organisator zich het recht
            voor om deelnames of deelnemers aan de Actie te weigeren of het
            recht op een prijs (achteraf) te laten vervallen.
            {"\n\n"}
            <Text type="h6">
              Publiciteit
              {"\n"}
            </Text>
            16. De winnaar stemt er door middel van deelname aan de Actie mee in
            dat zijn/haar naam en foto genoemd/geplaatst wordt op de diverse
            kanalen van de Organisator (website en social media). {"\n"}
            17. Indien de winnaar op verzoek van de Organisator meewerkt aan
            enige andere vorm van publiciteit, zal rekening worden gehouden met
            de redelijke wensen van de winnaar. {"\n"}
            18. De winnaar is niet gerechtigd tot enige vergoeding hoe ook
            genaamd en doet afstand van enige aanspraak in verband met
            publicatie en publiciteit zoals beschreven in deze voorwaarden. Alle
            eventuele publicatie- en publicitaire materialen (inclusief
            beeldmateriaal) zullen het volledig eigendom zijn c.q. worden van de
            Organisator. {"\n\n"}
            <Text type="h6">
              Vragen/klachten
              {"\n"}
            </Text>
            19. De deelnemer aan deze Actie kan binnen 90 dagen na 31-12-2021
            uitsluitend schriftelijk reclameren omtrent een al dan niet
            toegekende prijs via e-mailadres helpdesk@valkdigital.nl. Een daarna
            ontvangen reclame wordt niet meer in behandeling genomen en de
            eventuele rechten van de betreffende deelnemer zijn dan vervallen.
            Deelnemers die tijdig schriftelijk hebben gereclameerd, ontvangen
            binnen dertig dagen na het indienen van hun klacht een schriftelijke
            gemotiveerde reactie. {"\n"}
            20. Over de Actievoorwaarden kan niet worden gecorrespondeerd. In de
            gevallen waarin de Actievoorwaarden niet voorzien beslist de
            Organisator. {"\n\n"}
            <Text type="h6">
              Slotbepalingen
              {"\n"}
            </Text>
            21. De Organisator en andere partijen die op enige wijze betrokken
            zijn bij de totstandkoming van de Actie, zijn niet aansprakelijk
            voor enige (gevolg)schade van deelnemers en/of derden die op
            enigerlei wijze voort kan komen uit de Actie. {"\n"}
            22. Alle rechten van intellectueel eigendom die mogelijk rusten op
            teksten, afbeeldingen, lay-out, software en/of overige informatie
            omtrent de Actie komen toe aan de Organisator.
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: Spacing.sp2,
    paddingTop: 0,
  },
  scrollContainer: { ...Platform.select({ web: { height: 0 } }) },
  conditions: {
    marginTop: Spacing.sp2,
    marginBottom: Spacing.sp5,
    lineHeight: 26,
  },
});

export default PromoConditions;
