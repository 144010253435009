import { Action } from "redux";
import { UIActionTypes } from "../ui/types";

export interface PromoState {
  phone: string;
}

export enum PromoActionTypes {
  ADD_PROMO_DATA = "@@promo/ADD_PROMO_DATA",
}

interface AddPromoData extends Action<PromoActionTypes> {
  payload: PromoState;
}
interface ResetStore extends Action<UIActionTypes> {}

export type PromoActions = AddPromoData | ResetStore;
