import { UIActionTypes, UIState, UIActions } from "./types";

const initialState: UIState = {
  error: "",
  showGiftcardError: undefined,
  notification: undefined,
};

export default (state: UIState = initialState, action: UIActions) => {
  switch (action.type) {
    case UIActionTypes.SET_ERROR: {
      return { ...state, error: action.payload };
    }
    case UIActionTypes.SHOW_GIFTCARD_ERROR: {
      return { ...state, showGiftcardError: action.payload };
    }
    case UIActionTypes.SET_NOTIFICATION: {
      return { ...state, notification: action.payload };
    }
    case UIActionTypes.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};
