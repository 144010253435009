import React, { useContext, Ref } from "react";
import { View, StyleSheet, ScrollView, TextInput as RNTI } from "react-native";
import {
  FormData,
  FormValidation,
  GuestLoginTypes,
} from "./GuestLoginContainer";
import {
  Spacing,
  Text,
  TextInput,
  TextButton,
  Button,
  ThemeContext,
  Notification,
  TextInputType,
} from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface GuestLoginScreenProps {
  inputRef: Ref<TextInputType>;
  values: FormData;
  validation: FormValidation;
  focusPassword: () => void;
  forgotPassword: () => void;
  setValues: (values: FormData) => void;
  onSubmit: () => void;
  onFbLogin: () => void;
  onAppleLogin: () => void;
  isLoading: boolean;
  loginType?: GuestLoginTypes;
  formErrors: FormValidation["errors"];
  setFormErrors: (errors: FormValidation["errors"]) => void;
  fbLoading: boolean;
  appleLoading: boolean;
}

const GuestLoginScreen: React.FC<GuestLoginScreenProps> = ({
  inputRef,
  values: { email, password },
  validation: { isValid, errors },
  forgotPassword,
  focusPassword,
  setValues,
  onSubmit,
  onFbLogin,
  onAppleLogin,
  isLoading,
  loginType,
  formErrors,
  setFormErrors,
  fbLoading,
  appleLoading,
}) => {
  const { typography, info } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <ScrollView style={styles.container} keyboardShouldPersistTaps="handled">
      <Text type="h3" style={styles.header}>
        {t("account.login.title")}
      </Text>
      {loginType && (
        <Text style={styles.info}>{t(`account.login.info.${loginType}`)}</Text>
      )}

      <View style={styles.form}>
        <TextInput
          label={t("account.form.email")}
          value={email}
          autoCapitalize="none"
          placeholder={t("account.form.emailPlaceholder")}
          onChangeText={(text) => {
            setValues({ email: text, password });
            setFormErrors({ ...formErrors, email: "" });
          }}
          keyboardType="email-address"
          onSubmitEditing={focusPassword}
          onBlur={() => setFormErrors({ ...formErrors, email: errors.email })}
          autoCorrect={false}
          showCheckmark={!errors.email}
          containerStyle={styles.email}
          error={formErrors.email}
        />

        <TextInput
          label={t("account.form.pass")}
          ref={inputRef}
          value={password}
          autoCapitalize="none"
          placeholder="••••••••••••"
          onChangeText={(text) => {
            setValues({ email, password: text });
            setFormErrors({ ...formErrors, password: "" });
          }}
          type="password"
          onSubmitEditing={onSubmit}
          onBlur={() =>
            setFormErrors({ ...formErrors, password: errors.password })
          }
          autoCorrect={false}
          showCheckmark={!errors.password}
          containerStyle={styles.password}
          error={formErrors.password}
        />
        <TextButton
          label={t("account.login.forgotPass")}
          onPress={forgotPassword}
          color={info.primary}
          textType="subtextRegular"
        />
      </View>

      <Button
        onPress={onSubmit}
        label={t("account.login.button")}
        buttonStyle={styles.buttonStyle}
        containerStyle={styles.buttonContainer}
        icon="email"
        iconOpposite={true}
        disabled={!isValid || isLoading}
        loading={isLoading}
      />

      <Button
        onPress={onFbLogin}
        label={t("account.login.fbButton")}
        buttonStyle={styles.buttonStyle}
        containerStyle={styles.buttonContainer}
        color="#3578EA"
        labelColor="white"
        icon="facebook"
        iconOpposite={true}
        disabled={fbLoading}
        loading={fbLoading}
      />

      <Button
        onPress={onAppleLogin}
        label={t("account.login.appleButton")}
        buttonStyle={styles.buttonStyle}
        containerStyle={styles.buttonContainer}
        color={typography.color}
        labelColor={typography.inverted}
        icon="apple"
        iconOpposite={true}
        disabled={appleLoading}
        loading={appleLoading}
      />

      {loginType && (
        <Notification
          type="warning"
          heading={t("account.login.noAccount")}
          content={t(`account.login.text.${loginType}`)}
          containerStyle={styles.notification}
        />
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: Spacing.sp2,
    paddingTop: Spacing.sp3,
  },
  info: {
    marginBottom: Spacing.sp3,
  },
  header: {
    marginBottom: Spacing.sp4,
  },
  form: { paddingHorizontal: Spacing.sp1 },
  email: {
    marginBottom: Spacing.sp2,
  },
  password: {
    marginBottom: Spacing.sp1,
  },
  notification: {
    marginTop: Spacing.sp2,
  },
  button: {
    marginTop: Spacing.sp4,
  },
  loginButton: {
    marginTop: Spacing.sp4,
  },
  buttonContainer: {
    marginTop: Spacing.sp2,
  },
  buttonStyle: {
    justifyContent: "flex-start",
  },
});

export default GuestLoginScreen;
