import React, { useState } from "react";
import IdealScreen from "./IdealScreen";
import { links } from "./links";
import { submitPayment } from "../../../store/payment/actions";
import { useDispatch } from "react-redux";

interface IdealContainerProps {}

const IdealContainer: React.FC<IdealContainerProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedLink, setSelectedLink] = useState<string>("");
  const dispatch = useDispatch();

  const showSpinner: string = isLoading ? selectedLink : "";

  const onLinkPress = async (key: string) => {
    const { link } = links[key];
    setSelectedLink(key);

    await dispatch(
      submitPayment({
        setIsLoading,
        paymentMethod: {
          method: "ideal",
          bank: link,
        },
      })
    );
  };

  return (
    <IdealScreen
      links={links}
      showSpinner={showSpinner}
      onLinkPress={onLinkPress}
    />
  );
};

export default IdealContainer;
