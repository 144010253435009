import { Action } from "redux";
import { UIActionTypes } from "../ui/types";

export type Bank =
  | "ing"
  | "abn_amro"
  | "asn"
  | "knab"
  | "moneyou"
  | "rabobank"
  | "regiobank"
  | "sns"
  | "triodos"
  | "van_lanschot"
  | "bunq";

export type PaymentMethod =
  | {
      method: "ideal";
      bank: Bank;
    }
  | {
      method: "giftcard";
      number: string;
      pin: string;
      amount?: number;
    }
  | {
      method: "room_tab";
      reservationId?: string;
      folio?: string;
      room?: string;
      name?: string;
      confirm?: boolean;
    }
  | {
      method: "applepay";
      token: string;
    }
  | {
      method: "pos";
      posId?: string;
    }
  | {
      method: "creditcard";
    }
  | { method: "sofort" }
  | { method: "bancontact" }
  | { method: "valkpay" };

export interface PaymentRequestBody {
  receiptId: string;
  methods: PaymentMethod[];
  tip: number;
  return_url: string;
  printerId?: string;
}

export type PaymentState = PaymentMethod | null;

export enum PaymentActionTypes {
  SET_PAYMENT_METHOD = "@@payment/SET_PAYMENT_METHOD",
}

interface SetPaymentMethod
  extends Action<PaymentActionTypes.SET_PAYMENT_METHOD> {
  payload: PaymentMethod;
}
interface ResetStore extends Action<UIActionTypes.RESET_STORE> {}

export type PaymentActions = SetPaymentMethod | ResetStore;
