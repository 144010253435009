import React from "react";
import Svg, { Circle, Path } from "react-native-svg";
import { SvgProps } from "./types";

export default ({ style }: SvgProps) => (
  <Svg width="136" height="136" viewBox="0 0 136 136" fill="none" style={style}>
    <Circle cx="68" cy="68" r="68" fill="#69C300" />
    <Path
      d="M59.3728 78.9276L100.221 39L111 49.5362L59.3728 100L25 66.4019L35.7791 55.8657L59.3728 78.9276Z"
      fill="white"
    />
  </Svg>
);
