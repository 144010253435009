import React, { Ref } from "react";
import { StyleSheet, View } from "react-native";
import KeyboardAvoidedView from "../../../components/KeyboardAvoidedView";
import {
  Spacing,
  TextButton,
  Text,
  Button,
  TextInput,
  TextInputType,
} from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface RoomTabVerifyScreenProps {
  folio: string;
  onFolioChange: (text: string) => void;
  room: string;
  onRoomChange: (text: string) => void;
  onSubmit: () => void;
  isValid: boolean;
  isLoading: boolean;
  inputRef: Ref<TextInputType>;
  focusRoomInput: () => void;
  showInfo: () => void;
}

const RoomTabVerifyScreen: React.FC<RoomTabVerifyScreenProps> = ({
  folio,
  onFolioChange,
  room,
  onRoomChange,
  onSubmit,
  isValid,
  isLoading,
  inputRef,
  focusRoomInput,
  showInfo,
}) => {
  const { t } = useTranslation();

  return (
    <KeyboardAvoidedView style={styles.container}>
      <View style={styles.paddedView}>
        <Text type="subtextRegular" style={styles.info}>
          {t("guest.payment.roomtab.folioInfo")}
        </Text>

        <TextInput
          label={t("guest.payment.roomtab.folio")}
          keyboardType="number-pad"
          value={folio}
          onChangeText={onFolioChange}
          placeholder="8233062"
          onSubmitEditing={focusRoomInput}
          LeftIconComponent={<Text>F(X)-</Text>}
        />
        <TextButton
          label={t("guest.payment.roomtab.folioQuestion")}
          style={styles.textButton}
          textType="subtextRegular"
          onPress={showInfo}
        />
        <TextInput
          label={t("guest.payment.roomtab.room")}
          ref={inputRef}
          keyboardType="number-pad"
          value={room}
          onChangeText={onRoomChange}
          containerStyle={styles.input}
          placeholder="001"
          onSubmitEditing={onSubmit}
        />
      </View>

      <Button
        label={t("guest.payment.roomtab.proceed")}
        onPress={onSubmit}
        loading={isLoading}
        disabled={!isValid || isLoading}
        size="full"
      />
    </KeyboardAvoidedView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
  },
  paddedView: {
    padding: Spacing.sp2,
  },
  info: {
    marginBottom: Spacing.sp4,
  },
  input: {
    marginBottom: Spacing.sp2,
  },
  row: {
    marginTop: Spacing.sp3,
    flexDirection: "row",
    alignSelf: "center",
  },
  textButton: {
    marginTop: Spacing.sp1,
    marginBottom: Spacing.sp3,
  },
});

export default RoomTabVerifyScreen;
