import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReceiptData, setReceipt } from "../../../store/receipt/actions";
import { AppState } from "../../../store/types";
import ErrorPage from "../../../components/ErrorPage";
import PaymentProcessing from "./PaymentProcessing";
import PaymentSuccess from "./PaymentSuccess";
import PaymentSuccessPromo from "./PaymentSuccessPromo";
import PaymentFailed from "./PaymentFailed";
import { PaymentStatuses } from "../types";
import {
  GuestNavProp,
  GuestParamList,
} from "../../../navigation/guest/GuestRoutes";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import HeaderButton from "../../../components/HeaderButton";
import PaymentCancelled from "./PaymentCancelled";
import l from "../../../locale";
import u from "../../../api/helpers";
import { Icon, Spacing } from "@valkdigital/ui-kit";

interface PaymentProgressContainerProps {}

const PaymentProgressContainer: React.FC<PaymentProgressContainerProps> = ({}) => {
  const { channel, balance, paymentMethods } = useSelector(
    ({ receipt }: AppState) => receipt
  );
  const [paymentStatus, setPaymentStatus] = useState<
    keyof typeof PaymentStatuses
  >(PaymentStatuses.started);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const dispatch = useDispatch();
  const navigation = useNavigation<GuestNavProp>();
  const route = useRoute<RouteProp<GuestParamList, "payment-progress">>();

  const hasPromo = true;

  const proceed = () => {
    navigation.push("review-food");
  };

  const proceedWithPromo = () => {
    navigation.push("promo-phone");
  };

  const goToHome = () => {
    navigation.popToTop();
  };

  const tryAgain = () => {
    navigation.push("payment-options");
  };

  useEffect(() => {
    if (route.params?.id === undefined) {
      setError(l.t("guest.error.noParams"));
      return;
    }

    const { id, resultCode } = route.params;

    if (resultCode !== undefined) {
      dispatch(setReceipt({ id }));
      switch (resultCode) {
        case "cancelled":
          setPaymentStatus(PaymentStatuses.cancelled);
          return;
        case "completed":
          setPaymentStatus(PaymentStatuses.completed);
          return;
      }
    }

    dispatch(
      getReceiptData({
        receiptId: id,
        setError,
        setIsLoading,
        expectPayment: true,
      })
    );
  }, [route.params]);

  useEffect(() => {
    if (!channel) return;
    if (balance === 0) {
      setPaymentStatus(PaymentStatuses.paid);
      return;
    }
    u.Payment.getStatus(channel, setPaymentStatus);
  }, [channel]);

  useEffect(() => {
    if (paymentStatus === PaymentStatuses.started) return;
    navigation.setOptions({
      headerLeft: ({ tintColor }) => {
        return (
          <HeaderButton onPress={goToHome}>
            <Icon
              name="close"
              style={{ marginLeft: Spacing.sp2 }}
              color={tintColor}
            />
          </HeaderButton>
        );
      },
    });
  }, [paymentStatus]);

  if (isLoading) {
    <PaymentProcessing />;
  }
  if (error) {
    return <ErrorPage error={error} />;
  }
  if (
    paymentStatus === PaymentStatuses.paid ||
    paymentStatus === PaymentStatuses.completed
  ) {
    if (hasPromo) {
      return (
        <PaymentSuccessPromo
          proceed={proceed}
          proceedWithPromo={proceedWithPromo}
          paymentMethods={paymentMethods}
        />
      );
    }
    return <PaymentSuccess proceed={proceed} paymentMethods={paymentMethods} />;
  }
  if (paymentStatus === PaymentStatuses.started) {
    return <PaymentProcessing />;
  }
  if (paymentStatus === PaymentStatuses.failed) {
    return <PaymentFailed tryAgain={tryAgain} />;
  }
  if (paymentStatus === PaymentStatuses.cancelled) {
    return <PaymentCancelled tryAgain={tryAgain} />;
  }
  return null;
};

export default PaymentProgressContainer;
