import { createNavigationContainerRef } from "@react-navigation/native";
import { EmployeeStackParamList } from "./employee/EmployeeRoutes";
import { AuthStackParamList } from "./employee/AuthRoutes";
import { GuestParamList } from "./guest/GuestRoutes";

type RootStackParamList = EmployeeStackParamList &
  AuthStackParamList &
  GuestParamList;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

export const navigationRef = createNavigationContainerRef();

export const navigate = (
  name: keyof RootStackParamList,
  params?: RootStackParamList[keyof RootStackParamList]
) => {
  if (navigationRef.isReady()) {
    navigationRef.current?.navigate(name, params);
  }
};

export const goBack = () => {
  if (navigationRef.isReady()) {
    navigationRef.current?.goBack();
  }
};
