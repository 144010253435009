import { request, handleErrors } from "../../api";
import u from "../../api/helpers";
import {
  AccountActionTypes,
  FormDetails,
  AccountActions,
  EmployeeAccount,
  GuestAccount,
} from "./types";
import { StackNavigationProp } from "@react-navigation/stack";
import { UIActionTypes } from "../ui/types";
import { AppThunk } from "../types";

export const resetPassword = async ({
  email,
  setIsLoading,
  navigation,
}: {
  email: string;
  setIsLoading: (isLoading: boolean) => void;
  navigation: StackNavigationProp<any>;
}) => {
  try {
    setIsLoading(true);
    await request.post("/account/reset", { email });
    navigation.goBack();
    setIsLoading(false);
  } catch (error) {
    handleErrors(error);
    setIsLoading(false);
  }
};

export const guestLogin = (details: GuestAccount) => {
  return {
    type: AccountActionTypes.SET_GUEST_ACCOUNT,
    payload: details,
  };
};

export const updateGuestAccount = (details: FormDetails): AccountActions => {
  return {
    type: AccountActionTypes.UPDATE_GUEST_ACCOUNT,
    payload: details,
  };
};

export const employeeLogin = (details: EmployeeAccount): AccountActions => {
  return {
    type: AccountActionTypes.SET_EMPLOYEE_ACCOUNT,
    payload: details,
  };
};

export const signOut = (): AppThunk => async (dispatch) => {
  try {
    await u.Auth.reset();
    dispatch({ type: UIActionTypes.RESET_STORE });
  } catch (error) {
    handleErrors(error);
  }
};
