import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Text, Button, Spacing, TextButton } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";
import CheckMark from "../../../components/Svg/CheckMark";

interface PromoSmsScreenProps {
  proceedWithReview: () => void;
}

const PromoSmsScreen: React.FC<PromoSmsScreenProps> = ({
  proceedWithReview,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.section}>
          <CheckMark style={styles.checkMark} />
          <Text textAlign="center" type="h3" style={styles.header}>
            {t("guest.promo.sms.header")}
          </Text>
          <Text textAlign="center">{t("guest.promo.sms.info")}</Text>
          <Text textAlign="center" style={styles.eta}>
            {t("guest.promo.sms.eta")}
          </Text>
        </View>
      </ScrollView>
      <Button
        label={t("common.startReview")}
        onPress={proceedWithReview}
        size="full"
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    padding: Spacing.sp2,
    paddingBottom: 0,
  },
  checkMark: {
    marginBottom: Spacing.sp2,
    width: 56,
    height: 56,
    alignSelf: "center",
  },
  header: {
    marginBottom: Spacing.sp2,
  },
  section: {
    flex: 1,
    justifyContent: "center",
  },
  eta: {
    marginTop: Spacing.sp2,
  },
  textButton: {
    alignSelf: "center",
    marginBottom: Spacing.sp3,
  },
});

export default PromoSmsScreen;
