import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { NavigationContainer } from "@react-navigation/native";
import Constants from "expo-constants";
import ENV from "../../environment";

Bugsnag.start({
  apiKey: "5b2a743680740571e1b4d1a6722719b8",
  plugins: [new BugsnagPluginReact()],
  appVersion: Constants.manifest.version,
  appType: "web",
  releaseStage: ENV.mode,
  enabledReleaseStages: ["prod"],
});

export default NavigationContainer;
