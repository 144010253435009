import { Action } from "redux";
import { UIActionTypes } from "../ui/types";

export enum TipMode {
  RADIO = "radioButtons",
  INPUT = "inputField",
}

export interface ReceiptState {
  id: string;
  amount: number;
  amountPlusTip: number;
  tip: {
    tipAmount: number;
    tipMode: TipMode;
  };
  property: string;
  propertyCode: string;
  balance?: number;
  paid?: number;
  channel?: {
    channel: string;
    id: string;
  };
  paymentMethods?: string[];
  printerId?: string;
  table: string | null;
}

export enum ReceiptActionTypes {
  SET_RECEIPT = "@@receipt/SET_RECEIPT",
  ADD_TIP = "@@receipt/ADD_TIP",
  SELECT_TIP_MODE = "@@receipt/SELECT_TIP_MODE",
}

interface SetReceipt extends Action<ReceiptActionTypes.SET_RECEIPT> {
  payload: Partial<ReceiptState>;
}
interface AddTip extends Action<ReceiptActionTypes.ADD_TIP> {
  payload: number;
}
interface SelectTipMode extends Action<ReceiptActionTypes.SELECT_TIP_MODE> {
  payload: TipMode;
}
interface ResetStore extends Action<UIActionTypes.RESET_STORE> {}

export type ReceiptActions = SetReceipt | AddTip | SelectTipMode | ResetStore;
