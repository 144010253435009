import React, { useContext } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Button, Spacing, Text, ThemeContext } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";
import { formatMoneyWithoutSign } from "./utils/formatMoney";

interface ReceiptScreenProps {
  amount: number;
  tip?: number;
  amountLeft: number;
  onProceed: () => void;
  isLoading: boolean;
}

const ReceiptScreen: React.FC<ReceiptScreenProps> = ({
  amount,
  tip,
  amountLeft,
  onProceed,
  isLoading,
  children,
}) => {
  const { grey } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <>
      <ScrollView contentContainerStyle={styles.container}>
        <View>
          <Text type="h3" style={styles.header}>
            {t("guest.payment.receipt.title")}
          </Text>
          <View>
            <View style={styles.row}>
              <Text type="bodySemiBold">
                {t("guest.payment.receipt.amount")}
              </Text>
              <Text type="bodySemiBold">{formatMoneyWithoutSign(amount)}</Text>
            </View>
            {tip !== undefined && (
              <View style={styles.row}>
                <Text type="bodySemiBold">{t("common.tip")}</Text>
                <Text type="bodySemiBold">{formatMoneyWithoutSign(tip)}</Text>
              </View>
            )}

            {children}
          </View>
        </View>

        <View>
          <View style={[styles.hr, { borderColor: grey[1] }]} />
          <View style={[styles.row, styles.amountLeft]}>
            <Text type="h4">{t("common.amountLeft")}</Text>
            <Text type="h4">{"€" + formatMoneyWithoutSign(amountLeft)}</Text>
          </View>
        </View>
      </ScrollView>

      <Button
        label={amountLeft !== 0 ? t("ui.proceed") : t("common.confirm")}
        onPress={onProceed}
        size="full"
        loading={isLoading}
        disabled={isLoading}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingHorizontal: Spacing.sp2,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: Spacing.sp2,
  },
  header: {
    marginVertical: Spacing.sp4,
  },
  hr: {
    borderWidth: 1,
  },
  amountLeft: {
    marginTop: Spacing.sp2,
    marginBottom: Spacing.sp3,
  },
});

export default ReceiptScreen;
