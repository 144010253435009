import React from "react";
import { View, StyleSheet } from "react-native";
import SelectTip from "./SelectTip";
import KeyboardAvoidedView from "../../../components/KeyboardAvoidedView";
import {
  Button,
  Text,
  Spacing,
  TextButton,
  SegmentControl,
  XLTextInput,
} from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface TipScreenProps {
  tip: string;
  amountPlusTipAsString?: string;
  onTipChange: (value: string) => void;
  onCustomTipChange: (text: string) => void;
  tipMode: string;
  segment: string;
  segments: string[];
  onSegmentChange: (segment: string) => void;
  proceedWithTip: () => void;
  proceedWithoutTip: () => void;
  onFocusedInput: () => void;
  tipOptions: string[];
}

const TipScreen: React.FC<TipScreenProps> = ({
  tip,
  amountPlusTipAsString,
  onTipChange,
  onCustomTipChange,
  tipMode,
  segment,
  segments,
  onSegmentChange,
  proceedWithTip,
  proceedWithoutTip,
  onFocusedInput,
  tipOptions,
}) => {
  const { t } = useTranslation();

  return (
    <KeyboardAvoidedView style={styles.container}>
      <View>
        <Text type="h3" textAlign="center" style={styles.header}>
          {t("guest.payment.tip.header")}
        </Text>
        <Text textAlign="center">{t("guest.payment.tip.subHeader")}</Text>
      </View>

      <View style={styles.tip}>
        <SegmentControl
          value={segment}
          segments={segments}
          onPress={onSegmentChange}
          style={styles.segments}
        />
        {tipMode === "inputField" ? (
          <XLTextInput
            onChangeText={onCustomTipChange}
            value={tip}
            onFocus={onFocusedInput}
            containerStyle={styles.input}
            size="small"
            textAlign="center"
            keyboardType="decimal-pad"
          />
        ) : (
          <SelectTip
            tip={tip}
            tipOptions={tipOptions}
            onTipChange={onTipChange}
          />
        )}
        <View>
          <Text textAlign="center" style={styles.total}>
            {t("common.totalAmount")}
          </Text>
          <Text type="h2" textAlign="center" style={styles.amount}>
            {amountPlusTipAsString}
          </Text>
        </View>
      </View>

      <View>
        <TextButton
          label={t("common.noThanks")}
          onPress={proceedWithoutTip}
          style={styles.textButton}
        />
        <Button label={t("ui.proceed")} onPress={proceedWithTip} size="full" />
      </View>
    </KeyboardAvoidedView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingTop: Spacing.sp2,
  },
  header: {
    marginBottom: Spacing.sp1,
  },
  tip: {
    justifyContent: "space-between",
    height: 240,
  },
  total: { marginTop: Spacing.sp2 },
  amount: { marginBottom: Spacing.sp4 },
  textButton: {
    alignSelf: "center",
    marginBottom: Spacing.sp3,
  },
  segments: {
    flex: 1,
    marginHorizontal: Spacing.sp4,
    marginBottom: Spacing.sp2,
  },
  input: {
    alignSelf: "center",
  },
});

export default TipScreen;
