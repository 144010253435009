import React, { useState } from "react";
import { GestureResponderEvent, LayoutChangeEvent } from "react-native";
import Slider from "./Slider";

interface SliderContainerProps {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
}

const SliderContainer: React.FC<SliderContainerProps> = ({
  value,
  min,
  max,
  step,
  onChange,
}) => {
  const [railWidth, setRailWidth] = useState<number>(0);
  const [railX, setRailX] = useState<number>(0);

  const percentage: string = ((value - min) / (max - min)) * 100 + "%";

  const onTouchMove = (event: GestureResponderEvent) => {
    const { pageX } = event.nativeEvent;
    calculateFactor(pageX);
  };

  const getRailWidth = (event: LayoutChangeEvent) => {
    const { x, width } = event.nativeEvent.layout;
    setRailX(x);
    setRailWidth(width);
  };

  const calculateFactor = (position: number) => {
    if (railWidth) {
      const factor = (position - railX) / railWidth;
      const limitedFactor = factor < 0 ? 0 : factor > 1 ? 1 : factor;
      const sliderValue = limitedFactor * (max - min) + min;
      const numberToRoundTo = 1 / step;
      const roundedValue =
        Math.round(numberToRoundTo * sliderValue) / numberToRoundTo;
      onChange(roundedValue);
    }
  };

  return (
    <Slider
      min={min}
      max={max}
      value={value}
      percentage={percentage}
      onTouchMove={onTouchMove}
      getRailWidth={getRailWidth}
    />
  );
};

export default SliderContainer;
