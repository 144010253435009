import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Platform, StatusBar, StyleSheet, useColorScheme } from "react-native";
import Navigation from "./navigation";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Fonts, Spacing, ThemeContext } from "@valkdigital/ui-kit";
import LoadingPage from "./components/LoadingPage";
import useTheme from "./hooks/useTheme";
import BugsnagNavigationContainer from "./navigation/BugsnagNavigationContainer";
import { getStateFromPath, LinkingOptions } from "@react-navigation/native";
import ENV from "../environment";
import queryString from "query-string";
import helpers from "./api/helpers";
import api, { handleErrors } from "./api";
import { guestLogin } from "./store/account/actions";
import { navigationRef } from "./navigation/rootNavigation";
import useNavigationTheme from "./hooks/useNavigationTheme";
import { useFonts } from "expo-font";

const App: React.FC = () => {
  const theme = useTheme();
  const navigationTheme = useNavigationTheme();
  const colorScheme = useColorScheme();
  const [fontLoaded] = useFonts({
    [Fonts.Bold]: require("../assets/fonts/SourceSansPro-Bold.ttf"),
    [Fonts.Regular]: require("../assets/fonts/SourceSansPro-Regular.ttf"),
    [Fonts.SemiBold]: require("../assets/fonts/SourceSansPro-SemiBold.ttf"),
    [Fonts.Italic]: require("../assets/fonts/SourceSansPro-Italic.ttf"),
    [Fonts.IconSetSolid]: require("../assets/fonts/icomoon_solid.ttf"),
    [Fonts.IconSetOutline]: require("../assets/fonts/icomoon_outline.ttf"),
  });

  const linking: LinkingOptions<ReactNavigation.RootParamList> = {
    prefixes: [ENV.url, "valkpay://"],
    getStateFromPath: (path, options) => {
      const { url, query: params } = queryString.parseUrl(path);
      const isEmployee = Platform.OS !== "web";

      if (!isEmployee) {
        let newPath = queryString.exclude(path, ["jwt", "refresh_token"]);
        if (["/", ""].includes(url)) {
          newPath = `guest/welcome${newPath}`;
        }
        const { jwt, refresh_token: refreshToken } = params;
        if (jwt !== undefined && refreshToken !== undefined) {
          loginUser(jwt as string, refreshToken as string);
          return getStateFromPath(newPath, options);
        }
        return getStateFromPath(newPath, options);
      }
      return getStateFromPath(path, options);
    },
  };

  const loginUser = async (jwt: string, refreshToken: string) => {
    try {
      const signedIn = await helpers.Auth.get();
      if (!signedIn) {
        await helpers.Auth.update(jwt, refreshToken);
      }
      const account = await api.Account.current();
      store.dispatch(guestLogin(account.data));
    } catch (error) {
      handleErrors(error);
    }
  };

  return (
    <Provider store={store}>
      <ThemeContext.Provider value={theme}>
        <PersistGate
          loading={<LoadingPage fontLoaded={fontLoaded} />}
          persistor={persistor}
        >
          {fontLoaded && (
            <SafeAreaProvider>
              <StatusBar
                barStyle={
                  colorScheme === "dark" ? "light-content" : "dark-content"
                }
                backgroundColor="transparent"
              />
              <BugsnagNavigationContainer
                ref={navigationRef}
                linking={linking}
                fallback={<LoadingPage fontLoaded={false} />}
                theme={navigationTheme}
                documentTitle={{
                  formatter: () => "Valk Pay",
                }}
              >
                <Navigation />
              </BugsnagNavigationContainer>
            </SafeAreaProvider>
          )}
        </PersistGate>
      </ThemeContext.Provider>
    </Provider>
  );
};

const styles = StyleSheet.create({
  modal: {
    marginTop: Spacing.sp6,
    marginHorizontal: Spacing.sp1,
    justifyContent: "flex-start",
  },
});

export default App;
