import { Spacing, Text, ThemeContext } from "@valkdigital/ui-kit";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet, ActivityIndicator, Image } from "react-native";
import type { IdealLinks } from "./links";

interface CustomLabelProps {
  showSpinner: string;
  item: string;
  links: IdealLinks;
}

const CustomLabel: React.FC<CustomLabelProps> = ({
  showSpinner,
  item,
  links,
}) => {
  const { t } = useTranslation();
  const { typography } = useContext(ThemeContext);

  return (
    <>
      <Image source={links[item].imageFile} style={styles.image} />
      <View style={styles.label}>
        <Text type="bodySemiBold">{t(item)}</Text>
        {showSpinner === item && (
          <ActivityIndicator size="small" color={typography.color} />
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 32,
    height: 32,
    resizeMode: "contain",
    marginRight: Spacing.sp2,
  },
  label: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: Spacing.sp2,
  },
});

export default CustomLabel;
