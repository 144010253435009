import { Text, Spacing } from "@valkdigital/ui-kit";
import React, { ReactChild } from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import hitSlop from "./utils/hitSlop";

interface HeaderButtonProps {
  onPress: () => void;
  title?: string;
  children?: ReactChild;
}

const HeaderButton: React.FC<HeaderButtonProps> = ({
  title,
  onPress,
  children,
}) => (
  <TouchableOpacity
    onPress={onPress}
    hitSlop={hitSlop}
    style={styles.container}
  >
    {title && <Text type="subtextRegular">{title}</Text>}
    {children}
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  container: { marginRight: Spacing.sp2 },
});

export default HeaderButton;
