import React, { Ref } from "react";
import {
  Text,
  Spacing,
  Button,
  XLTextInput,
  TextInputType,
} from "@valkdigital/ui-kit";
import { View, Image, StyleSheet, Dimensions } from "react-native";
import ScanButton from "./ScanButton";
import Scanner from "../../../components/Scanner";
import KeyboardAvoidedView from "../../../components/KeyboardAvoidedView";
import { useTranslation } from "react-i18next";

interface GiftcardNumberScreenProps {
  inputRef: Ref<TextInputType>;
  onCodeChange: (text: string) => void;
  code: string;
  proceed: () => void;
  toggleScan: () => void;
  onBarCodeRead: (barCode: string) => void;
  isScanning: boolean;
  scannerIsAvailable: boolean;
  isLoading: boolean;
  isValid: boolean;
}

const GiftcardNumberScreen: React.FC<GiftcardNumberScreenProps> = ({
  inputRef,
  onCodeChange,
  code,
  proceed,
  toggleScan,
  onBarCodeRead,
  isScanning,
  scannerIsAvailable,
  isLoading,
  isValid,
}) => {
  const { t } = useTranslation();

  return (
    <KeyboardAvoidedView style={styles.container}>
      <Text type="h3" style={styles.title}>
        {t("guest.payment.giftcard.number")}
      </Text>

      <Image
        source={require("../../../../assets/images/valk-cadeaucard-number.png")}
        style={styles.image}
      />

      <View style={styles.inputContainer}>
        <XLTextInput
          ref={inputRef}
          onChangeText={onCodeChange}
          value={code}
          placeholder="•••• •••• •••• •••• •••"
          keyboardType="number-pad"
        />
        {scannerIsAvailable && <ScanButton toggleScan={toggleScan} />}
      </View>

      <Button
        label={t("ui.proceed")}
        disabled={!isValid || isLoading}
        loading={isLoading}
        onPress={proceed}
        size="full"
      />
      {isScanning && (
        <Scanner
          toggleScan={toggleScan}
          onBarCodeRead={onBarCodeRead}
          isLoading={isLoading}
          title={t("guest.payment.giftcard.scanTitle")}
        />
      )}
    </KeyboardAvoidedView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingTop: Spacing.sp2,
  },
  image: {
    width: "90%",
    height: Dimensions.get("window").height * 0.33,
    resizeMode: "contain",
    alignSelf: "center",
  },
  title: {
    paddingHorizontal: Spacing.sp2,
  },
  inputContainer: {
    paddingHorizontal: Spacing.sp2,
  },
});

export default GiftcardNumberScreen;
