export default {
  account: {
    form: {
      email: "E-Mail Adresse",
      emailInvalid:
        "Sehen Sie nach, ob Ihre E-Mail Adresse korrekt eingegeben wurde",
      emailPlaceholder: "Geben Sie Ihre E-Mail-Adresse ein",
      pass: "Passwort",
      required: "Ihre {{value}} fehlt noch",
      title: "Anrede",
      titles: ["Herr", "Frau", "Fam."],
      name: "Name",
      firstname: "Vorname",
      middlename: "Zweitname",
      lastname: "Familienname",
      address: "Adresse",
      housenumber: "Hausnummer",
      postalcode: "Postleitzahl",
      country: "Land",
      city: "Ort",
      street: "Straße",
      mobile: "Telefon",
      check: "Sehen Sie nach, ob Ihre {{value}} korrekt eingegeben wurde",
      updateSuccess: "Ihre Daten wurden erfolgreich aktualisiert",
    },
    login: {
      appleButton: "Fahren Sie mit Apple fort",
      button: "Einloggen",
      info: {
        roomTab:
          "Um auf Zimmerkonten buchen zu können, müssen Sie sich zunächst einloggen",
        voucher:
          "Um Ihre Gutscheine einlösen zu können, müssen Sie sich zuerst anmelden.",
      },
      fbButton: "Fahren Sie mit Facebook fort",
      forgotPass: "Passwort vergessen?",
      noAccount: "Noch keinen Account?",
      text: {
        roomTab:
          "Dann haben Sie nicht direkt bei uns gebucht. Auf der vorherigen Seite können Sie weiterhin mit Ihrem F(X)-nummer und Ihrer Zimmernummer auf Rechnung buchen.",
        voucher:
          "Dann haben Sie derzeit keine Gutscheine. Sie können nach dem Bezahlen ganz einfach ein Konto bei uns erstellen.",
      },
    },
    password: {
      button: "Anweisungen senden",
      text:
        "Keine Sorgem Sie brauchen nur Ihre E-Mail Adresse einzugeben. Wir werden Ihnen dann die Anweisungen per E-mail schicken.",
      title: "Ihr Passwort vergessen?",
    },
    titles: {
      login: "Einloggen",
      register: "Registrieren",
      details: "Ihre Daten",
    },
  },
  common: {
    amountLeft: "Noch zu zahlen",
    confirm: "Bestätigen",
    name: "Name",
    noInternet: "",
    noThanks: "Nein Danke",
    otherPaymentMethod: "Andere Zahlungsart",
    redirect: "Zurück zum Konto",
    refresh: "Seite neu laden",
    retry: "Versuchen Sie es erneut",
    search: "Suche",
    searchEmpty:
      "Leider wurden keine Ergebnisse für den Sucheintrag gefunden. Bitte versuche es erneut.",
    skip: "Überspringen",
    startReview: "Geben Sie eine Bewertung",
    tip: "Trinkgeld",
    total: "Gesamt: ",
    totalAmount: "Gesamtbetrag",
  },
  employee: {
    hotel: {
      fetchError: "Beim Abrufen der Hotels ist ein Fehler aufgetreten",
    },
    login: {
      button: "Einloggen",
      changeHotel: "Hotel wechseln",
      code: "Login",
      codePlaceholder: "Geben Sie Ihren persönlichen Login-Code ein",
      header: "ValkPay login",
      hotel: "Hotel",
      scanTitle: "QR-Code scannen",
    },
    navigation: {
      tabs: {
        pay: "Zahlen",
        settings: "Einstellungen",
      },
      titles: {
        createQr: "Valk Pay Zahlung",
        options: "Zahlungsmethode",
        pin: "Pin-Anschluss",
        print: "Drucker",
        success: "Zahlung verarbeitet",
        tab: "Tischnummer {{table}}",
        table: "Valk Pay Mitarbeiter",
        valkpay: "Valk Pay",
      },
    },
    options: {
      choose: "Wählen Sie die gewünschte Zahlungsmethode",
      pos: "Debitkarte oder Kreditkarte",
      room_tab: "Auf Zimmerrechnung",
      valkpay: "ValkPay",
    },
    payment: {
      return: "Zurück zum Startbildschirm",
      success: "Zahlung wurde erfolgreich bearbeitet!",
    },
    pin: {
      error: {
        heading: "Bei der Zahlung ist ein Fehler aufgetreten",
        content:
          "Try again, select another pin terminal or select another payment method.",
        reselect: "Anderen Pin-Anschluss",
      },
      header: "Wählen Sie den richtigen Pin-Anschluss",
      noTerminal: "Keine Pin-Anschluss gefunden",
      noTerminalFix:
        "Überprüfen Sie, ob die Pin-Anschluss angeschlossen sind, und versuchen Sie es erneut.",
      processing: "Betrag wird gesendet\nzum Pin-Anschluss {{terminalNr}}...",
      progress: "Zahlungsstatus abrufen ...",
      searching: "Suche nach verfügbaren Pin-Anschluss",
      select: "Wählen Sie einen Pin-Anschluss",
      submit: "Pin-Anschluss auswählen",
    },
    printer: {
      error: {
        content: "Erneut versuchen oder ohne Drucken fortfahren.",
        heading: "Beim Drucker ist ein Fehler aufgetreten",
        proformaContent:
          "Versuchen Sie es erneut oder gehen Sie zurück zur Quittung.",
        proformaSkip: "Zurück zur Quittung",
        reselect: "Anderen Drucker auswählen",
        skip: "Ohne Drucken fortfahren",
      },
      fetchError:
        "Beim Abrufen der verfügbaren Drucker ist ein Fehler aufgetreten",
      header: "Drucker auswählen",
      noPrinter: "Derzeit werden keine Drucker gefunden.",
      noPrinterFix:
        "Überprüfen Sie, ob Drucker angeschlossen sind, und versuchen Sie es erneut.",
      processing: "Die Quittung wird gedruckt...",
      searching: "Suche nach verfügbaren Druckern...",
      select: "Drucker auswählen",
      shouldAskForPrinter: "Bei jeder Zahlung nach der Druckerauswahl fragen",
    },
    qrInstruction:
      "Der Gast kann diesen QR-Code scannen, um mit Valk Pay zu bezahlen",
    roomTab: {
      amount: "Betrag",
      checked: "Ich habe den Gastnamen überprüft",
      denied: {
        heading: "Zimmerkonto für Zimmer {{room}} kann nicht gebucht werden.",
        content:
          "Eine Buchung auf einem Zimmerkonto ist nur möglich, wenn der Gast eine Zahlungsgarantie abgegeben hat.",
        otherRoom: "Versuchen Sie einen anderen Raum",
      },
      failed: {
        heading:
          "Bei der ausgewählten Zahlungsmethode ist ein Fehler aufgetreten.",
        content:
          "Bitte versuchen Sie es erneut oder wählen Sie eine andere Zahlungsmethode.",
      },
      label: "Geben Sie die Zimmernummer ein",
      name: "Gastname",
    },
    selectHotel: "Wählen Sie Ihr Hotel aus, um sich anzumelden",
    tab: {
      voucher: "Gutschein?",
    },
    table: {
      label: "Geben Sie die Tabellennummer ein",
      proceed: "Quittung abholen",
      welcome: "Willkommen\n",
    },
    tip: {
      header: "Möchte der Gast ein Trinkgeld hinterlassen?",
      input: {
        total: "Betrag anpassen",
        tip: "Trinkgeld eingeben",
      },
      amountWithoutTip: "Betrag ohne Trinkgeld",
    },
    update: {
      button: "Update herunterladen",
      error:
        "Das App-Update ist fehlgeschlagen. Überprüfen Sie die Internetverbindung und versuchen Sie es erneut.",
      info:
        "Eine neue Version der Valk Pay App ist verfügbar. Laden Sie das Update herunter, um fortzufahren",
      new: "Das neue Update enthält:",
      subTitle: "Laden Sie jetzt das neue Update herunter",
      title: "Neues Valk Pay Update!",
    },
    voucher: {
      add: "Gutschein hinzufügen",
      complete: "Vollständigen",
      empty: "Es wurde noch kein Gutschein hinzugefügt",
      loading: "Die Quittung wird aktualisiert. \nBitte warten...",
      proceed: "Ja, scannen Sie den Gutschein",
      processing: "Gutscheine werden eingelöst...",
      remove: "Entfernen",
      scanTitle: "Gutschein scannen",
      skip: "Diesen Schritt überspringen",
      text: "Hat der Gast einen Gutschein, der eingelöst werden kann?",
      title: "Gutscheine",
      warning:
        "Sobald der Betrag an das Debitkarten-Terminal gesendet wurde, kann kein Gutschein mehr umgetauscht werden.",
    },
  },
  guest: {
    error: {
      appleLogin: "Fortsetzung mit Apple fehlgeschlagen",
      facebookLogin: {
        no_account:
          "Es wurde kein Konto gefunden. Bitte versuchen Sie es erneut oder melden Sie sich ohne Facebook an.",
        user_denied:
          "Sie haben den Zugriff auf Ihr Facebook Account verweigert. Bitte versuche es erneut oder melde Sie sich an ohne Facebook.",
      },
      alreadyPaid: "Ihre Quittung wurde bereits bezahlt",
      giftcardBalance: "Auf Ihrer Geschenkkarte befindet sich kein Guthaben",
      giftcardPin:
        "Die Geschenkkarten-PIN fehlt \nGeben Sie hier Ihre PIN ein:",
      noParams: "Der Zahlungsstatus kann nicht abgerufen werden",
      noReceipt: "Es wurde keine Quittung gescannt",
    },

    payment: {
      cancelled: {
        header: "Zahlung storniert",
        retry: "Wählen Sie Ihre Zahlungsmethode",
        text: "Möchten Sie eine andere Zahlungsmethode wählen?",
      },
      failed: {
        header: "Zahlung fehlgeschlagen",
        retry: "Versuchen Sie es erneut",
        text: "Bitte versuchen Sie es erneut.",
      },
      giftcard: {
        amount: "Zu zahlen",
        balance: "Ihr Gleichgewicht",
        cancel: "Geschenkkarte stornieren",
        label: "Wählen Sie den Betrag aus, \nder eingelöst werden soll",
        number: "Wie lautet Ihre \nGeschenkkartennummer?",
        pay: "Bezahlen",
        pin: "Was ist deine \nGeschenkkarten-PIN?",
        receipt: "Ihr Konto",
        scanTitle: "Barcode scannen",
        skip: "Wählen Sie eine andere Zahlungsmethode",
      },
      options: {
        header: "Wählen Sie Ihre Zahlungsmethode",
        ideal: {
          header: "Wählen Sie Ihre Bank",
        },
        roomtab: "Auf Zimmerrechnung",
      },
      processing: "Zahlung an Verarbeitung...",
      receipt: {
        amount: "Quittung",
        giftcard: "Valk Cadeaucard",
        title: "Ihr Konto",
      },
      roomtab: {
        abort: "Wählen Sie eine andere Zahlungsmethode",
        account: "Gebucht mit einem Valk Account",
        auth: {
          instructions: [
            {
              extra:
                "(Wenn Sie ein Valk-Konto haben, können Sie dies auch online tun.)",
              message:
                "Fordern Sie an der Rezeption die Autorisierung Ihrer Kreditkarte an.",
            },
            {
              message: "Bitte geben Sie Ihre Kreditkarteninformationen ein.",
            },
            {
              message: "Akzeptiere die Bedingungen.",
            },
            {
              message:
                "Sie können während Ihres Aufenthalts alles auf Zimmerrechnung buchen.",
            },
          ],
        },
        authorise: "Wie kann ich eine Zahlungsgarantie abgeben?",
        book: "Buch auf meinem Zimmer",
        check: "Daten prüfen",
        consent:
          "Ich autorisiere die Buchung dieser Quittung auf meiner Zimmerrechnung.",
        folio: "F(X)-nummer",
        folioInfo:
          "Um die Autorisierung zu überprüfen, bitten wir Sie, Ihren F(X)-nummer und Ihre Zimmernummer einzugeben.",
        folioQuestion: "Wo finde ich meinen F(X)-nummer?",
        help:
          "Wenn während des Vorgangs Probleme auftreten, kann jederzeit die Rezeption konsultiert werden.",
        info:
          "Mit ValkPay bieten wir Ihnen die Möglichkeit, auf Zimmerrechnung zu buchen. Dazu benötigen Sie eine Kreditkartenautorisierung bei uns.",
        invalidProfile:
          "Leider ist es derzeit nicht möglich, auf einem Zimmerkonto zu buchen.",
        noAccount: "Kein Valk Account?",
        noAuth: "Zahlungsgarantie erforderlich",
        noAuthInfo: "Sie haben noch keine Zahlungsgarantie abgegeben.",
        noAuthYet: "Ich habe noch keine Zahlungsgarantie abgegeben",
        notFound:
          "Der F(X)-nummer und/oder die Zimmernummer ist falsch. Bitte versuchen Sie es erneut.",
        options: "Wählen Sie eine dieser Optionen, um fortzufahren:",
        proceed: "Überprüfen",
        room: "Zimmernummer",
        roomcode: {
          instructions: [
            {
              message:
                "Ihr F(X)-nummer finden Sie in Ihrer Bestätigungs-E-Mail.",
            },
            {
              message:
                "Ihr F(X)-nummer befindet sich auf dem Inhaber der Zimmerkarte.",
            },
          ],
        },
      },
      success: {
        header: "Danke.\nSie haben bezahlt!",
        message:
          "Wir würden uns freuen zu hören, ob Sie ein angenehmes Restauranterlebnis hatten.",
        text:
          "Geben Sie eine Bewertung ab und gewinnen Sie eine Geschenkkarte von",
        value: " €20,-",
        method: {
          other: "Danke.\nSie haben bezahlt!",
          roomtab: "Danke. \nIhre Rechnung wird auf Ihrem Zimmer gebucht!",
        },
        promo: {
          phone: "Dies erfordert Ihre Handynummer.",
          proceed: "Ja, ich möchte eine Übernachtung gewinnen",
          subHeader:
            "Möchten Sie eine Übernachtung im brandneuen Amsterdam Zuidas Hotel gewinnen??",
        },
      },
      tip: {
        custom: "Andere Betrag",
        header: "Willst du Trinkgeld geben?",
        selection: "Auswahl",
        subHeader: "Wenig Aufwand, viel Spaß",
      },
    },
    promo: {
      conditions: "Bedingungen",
      info:
        "Jede 500 Zahlung erhält eine Übernachtung im brandneuen Hotel Amsterdam-Zuidas.",
      phone: {
        conditions: "Bedingungen",
        confirm: "Handynummer bestätigen",
        consent: "Ich stimme den ",
        header: "Übernachtung gewinnen",
        label: "Handynummer",
        text:
          "Geben Sie Ihre Telefonnummer ein, um die Übernachtung zu gewinnen.",
        textExtra:
          "Sie erhalten außerdem eine SMS von Valk Exclusief mit einem Valk-Bonusguthaben von €5 auf Ihrem Valk Exclusief Account.",
      },
      sms: {
        eta:
          "Es kann einige Minuten dauern, bis Sie die Textnachricht erhalten.",
        header: "SMS wird gesendet!",
        info:
          "Dieser Beitrag enthält die Anweisungen zum Abschluss Ihrer Teilnahme.",
      },
    },
    review: {
      create: "Rezension schreiben",
      addComment: "Kommentar hinzufügen",
      email: {
        header: "Senden Sie Ihre Bewertung",
        proceed: "Senden",
        text:
          "Geben Sie Ihre E-Mail-Adresse ein und gewinnen Sie eine Geschenkkarte von",
        textBold: " €20,-",
      },
      food: "Hast du gut gegessen?",
      rateUs: "Bewerten Sie uns",
      redirect: "Zurück zum Konto",
      service: "Wie hat Ihnen der Service gefallen?",
      thanks: "Danke für deine Bewertung!",
      tikkie:
        "Fordern Sie eine Rückerstattung an Ihre Tischkameraden an? Senden Sie einen Tikkie.",
      valkpay: "Wie haben Sie diesen neuen ValkPay-Dienst erlebt?",
      value: "Was denkst du über das Preis-Leistungsverhältnis?",
    },
    tikkie: {
      goBack: "Zuruck",
      person: "1 Person",
      person_plural: "{{count}} Personen",
      proceed: "Tikkie senden",
      text:
        "Wählen Sie die Anzahl der Personen aus, \nmit denen Sie die Quittung teilen möchten",
      title: "Personenzahl",
    },
    titles: {
      roomTab: "Auf Zimmerrechnung buchen",
      roomTabVerify: "Zimmernummer überprüfen",
    },
    voucher: {
      applySuccess: "Gutscheine erfolgreich hinzugefügt.",
      confirmSuccess: "Gutscheine erfolgreich eingelöst.",
      removeSuccess: "Gutscheine erfolgreich entfernt.",
      fetching: "Gutscheine sammeln..",
      hasBooking: "Haben Sie ein Zimmer im Hotel gebucht?",
      hasNoClaimableVouchers:
        "Aufgrund Ihrer Quittung sind keine Gutscheine verfügbar.",
      hasNoVouchers: "Sie haben keine Gutscheine für dieses Konto.",
      ineligibleArticles: "Sie haben die Möglichkeit, Gutscheine zu verwenden.",
      redeem: "Gutscheine einlösen",
      title: "Gutschein",
      tryAnotherAccount: "Versuchen Sie es mit einem anderen Konto",
    },
    welcome: {
      greetings: "Hoffe dich wieder zu sehen! \nFamilie van der Valk",
      text:
        "Wir hoffen, Sie hatten ein angenehmes Restauranterlebnis. Wir werden Sie bitten, Ihre Meinung zu teilen, indem Sie <0>3 Fragen</0> nach der Zahlung beantworten.",
      thanks: "Danke für Ihren Besuch.",
    },
  },
  scanner: {
    instructions:
      "Öffnen Sie Ihre Einstellungen und gewähren Sie Valk Pay Zugriff auf Ihre Kamera",
    noAccess: "Kamera ist ausgeschaltet",
    openSettings: "Einstellungen öffnen",
  },
  tab: {
    description: "Beschreibung",
    ep: "E.P.",
    qty: "Menge",
    servedBy: "Serviert von: ",
    total: "Total",
  },
  ui: {
    attention: "Achtung:",
    cancel: "Abbrechen",
    close: "Schließen",
    error: {
      close: "Benachrichtigung schließen",
      title: "Ein Fehler ist aufgetreten:\n",
    },
    errorPageTitle: "Ein Fehler ist aufgetreten",
    loading: "Laden...",
    notice: "Kommunikation",
    ok: "OK",
    proceed: "Weiter",
    signOut: "Abmelden",
    unknownError:
      "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut",
  },
};
