import { Spacing, Text } from "@valkdigital/ui-kit";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Pressable } from "react-native";

interface CloseButtonProps {
  onPress: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onPress }) => {
  const { t } = useTranslation();

  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [styles.button, pressed && { opacity: 0.4 }]}
    >
      <Text color="white" textAlign="center" type="h5">
        {t("ui.close")}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    height: Spacing.sp8,
    justifyContent: "center",
    borderTopWidth: 2,
    borderTopColor: "#ffffff",
  },
});

export default CloseButton;
