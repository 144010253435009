import React from "react";
import GuestRootScreen from "./guest/GuestRoutes";

interface RoutesProps {}

const Routes: React.FC<RoutesProps> = ({}) => {
  return <GuestRootScreen />;
};

export default Routes;
