import { Text, Spacing, ThemeContext } from "@valkdigital/ui-kit";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, Image, StyleSheet } from "react-native";

interface ScanButtonProps {
  toggleScan: () => void;
}

const ScanButton: React.FC<ScanButtonProps> = ({ toggleScan }) => {
  const { t } = useTranslation();
  const { info } = useContext(ThemeContext);

  return (
    <TouchableOpacity onPress={toggleScan} style={styles.container}>
      <Text
        type="subtextRegular"
        textAlign="center"
        color={info.primary}
        style={styles.text}
      >
        {t("guest.payment.giftcard.scanTitle")}
      </Text>
      <Image
        style={styles.icon}
        source={require("../../../../assets/images/scan.png")}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: -40,
    right: Spacing.sp1,
    padding: Spacing.sp1,
  },
  icon: {
    width: Spacing.sp7,
    height: Spacing.sp7,
  },
  text: {
    width: Spacing.sp7,
    marginBottom: Spacing["sp1/2"],
  },
});

export default ScanButton;
