import * as Yup from "yup";
import l from "../../../locale";

const FormSchema = Yup.object().shape({
  title: Yup.string(),
  firstName: Yup.string().required(
    l.t("account.form.required", {
      value: l.t("account.form.firstname").toLowerCase(),
    })
  ),
  lastName: Yup.string().required(
    l.t("account.form.required", {
      value: l.t("account.form.lastname").toLowerCase(),
    })
  ),
  postalcode: Yup.string()
    .min(
      4,
      l.t("account.form.check", {
        value: l.t("account.form.postalcode").toLowerCase(),
      })
    )
    .required(
      l.t("account.form.required", {
        value: l.t("account.form.postalcode").toLowerCase(),
      })
    ),
  mobile: Yup.boolean()
    .required(
      l.t("account.form.required", {
        value: l.t("account.form.mobile").toLowerCase(),
      })
    )
    .oneOf(
      [true],
      l.t("account.form.check", {
        value: l.t("account.form.mobile").toLowerCase(),
      })
    ),
  housenumber: Yup.string().required(
    l.t("account.form.required", {
      value: l.t("account.form.housenumber").toLowerCase(),
    })
  ),
  street: Yup.string().required(
    l.t("account.form.required", {
      value: l.t("account.form.street").toLowerCase(),
    })
  ),
  city: Yup.string().required(
    l.t("account.form.required", {
      value: l.t("account.form.city").toLowerCase(),
    })
  ),
  email: Yup.string()
    .email(
      l.t("account.form.check", {
        value: l.t("account.form.email").toLowerCase(),
      })
    )
    .required(
      l.t("account.form.required", {
        value: l.t("account.form.email").toLowerCase(),
      })
    ),
  password: Yup.string().required(
    l.t("account.form.required", {
      value: l.t("account.form.pass").toLowerCase(),
    })
  ),
});

export default FormSchema;
