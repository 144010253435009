import React from "react";
import { StyleSheet, View } from "react-native";
import ListMarker from "../../../components/Svg/ListMarker";
import { Text, Spacing } from "@valkdigital/ui-kit";

interface InstructionRowProps {
  item: {
    message: string;
    extra?: string;
  };
  index: number;
  markerType?: "letters" | "numbers";
}

const InstructionRow: React.FC<InstructionRowProps> = ({
  item: { message, extra },
  index,
  markerType = "numbers",
}) => {
  const markerValue =
    markerType === "letters"
      ? String.fromCharCode(97 + index).toUpperCase()
      : index + 1;

  return (
    <View style={styles.row} key={index}>
      <ListMarker marker={markerValue} style={styles.listMarker} />
      <View style={styles.textContainer}>
        <Text>{message}</Text>
        {extra && <Text type="subtextRegular">{extra}</Text>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    marginBottom: Spacing.sp2,
  },
  textContainer: {
    flex: 1,
  },
  listMarker: {
    marginRight: Spacing.sp2,
  },
});

export default InstructionRow;
