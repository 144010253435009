import { UIActionTypes } from "../ui/types";
import { PaymentActionTypes, PaymentState, PaymentActions } from "./types";

const initialState: PaymentState = null;

export default (state: PaymentState = initialState, action: PaymentActions) => {
  switch (action.type) {
    case PaymentActionTypes.SET_PAYMENT_METHOD: {
      return action.payload;
    }
    case UIActionTypes.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};
