import { Action } from "redux";
import { UIActionTypes } from "../ui/types";

export interface Rating {
  type: string;
  value: number;
  comments: string;
}

export interface ReviewState {
  ratings: Rating[];
}

export enum ReviewActionTypes {
  ADD_RATING = "@@review/ADD_RATING",
}

interface AddRating extends Action<ReviewActionTypes.ADD_RATING> {
  payload: ReviewState;
}
interface ResetStore extends Action<UIActionTypes.RESET_STORE> {}

export type ReviewActions = AddRating | ResetStore;
