import React, { useState, useRef, useContext } from "react";
import { StyleSheet, View } from "react-native";
import { cancelGiftCard, checkGiftcardPin } from "../store/giftcard/actions";
import { useDispatch } from "react-redux";
import shadow from "./utils/shadow";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";
import {
  Spacing,
  Text,
  Button,
  ThemeContext,
  TextButton,
  XLTextInput,
  TextInputType,
} from "@valkdigital/ui-kit";
import { setGiftcardError } from "../store/ui/actions";

interface GiftcardErrorMessageProps {}

const GiftcardErrorMessage: React.FC<GiftcardErrorMessageProps> = () => {
  const [code, setCode] = useState<string>("");
  const [pinError, setPinError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<TextInputType>(null);
  const dispatch = useDispatch();

  const { error, info } = useContext(ThemeContext);
  const { t } = useTranslation();

  const blurInput = () => {
    inputRef?.current?.blur();
  };

  const onCodeChange = (text: string) => {
    setPinError("");
    if (text.length > 5) blurInput();
    if (text.length > 6) return;
    setCode(text);
  };

  const proceedWithoutGiftcard = () => {
    dispatch(cancelGiftCard());
    blurInput();
    dispatch(setGiftcardError(false));
  };

  const proceed = () => {
    dispatch(checkGiftcardPin(code, setPinError, setIsLoading));
  };

  return (
    <Modal onClose={proceedWithoutGiftcard}>
      {(closeModal) => (
        <View style={[styles.modal, { backgroundColor: info.light }]}>
          <Text type="h6" textAlign="center">
            {t("ui.error.title")}
            {"\n"}
          </Text>
          <Text textAlign="center" type="subtextRegular" style={styles.error}>
            {t("guest.error.giftcardPin")}
          </Text>
          <XLTextInput
            ref={inputRef}
            onChangeText={onCodeChange}
            value={code}
            placeholder="••••••"
            keyboardType="number-pad"
            size="small"
            textAlign="center"
            containerStyle={styles.input}
          />
          <Text
            type="subtextRegular"
            style={styles.pinError}
            color={error.primary}
            textAlign="center"
          >
            {pinError}
          </Text>

          <TextButton
            label={t("guest.payment.giftcard.cancel")}
            onPress={closeModal}
            style={styles.button}
          />

          <Button
            label={t("ui.proceed")}
            onPress={proceed}
            loading={isLoading}
            color={info.midDark}
          />
        </View>
      )}
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  modal: {
    position: "relative",
    marginHorizontal: Spacing.sp2,
    padding: Spacing.sp2,
    borderRadius: Spacing["sp1/2"],
    ...shadow,
  },
  error: {
    marginVertical: Spacing.sp1,
  },
  button: {
    alignSelf: "center",
    marginBottom: Spacing.sp2,
  },
  input: {
    alignSelf: "center",
  },
  pinError: {
    marginTop: Spacing.sp1,
    marginBottom: Spacing.sp2,
    marginHorizontal: Spacing.sp3,
  },
});

export default GiftcardErrorMessage;
