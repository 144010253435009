import React, { useContext } from "react";
import { View, StyleSheet, ScrollView, Image } from "react-native";
import { Button, Text, Spacing, ThemeContext } from "@valkdigital/ui-kit";
import { useTranslation, Trans } from "react-i18next";
import { formatMoney } from "../../components/utils/formatMoney";

interface WelcomeScreenProps {
  amount: number;
  proceed: () => void;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ amount, proceed }) => {
  const { t } = useTranslation();
  const { info } = useContext(ThemeContext);

  return (
    <>
      <ScrollView contentContainerStyle={styles.container}>
        <View />
        <View>
          <Image
            source={require("../../../assets/images/valkpay.png")}
            style={styles.logo}
          />

          <View style={[styles.textContainer, { backgroundColor: info.light }]}>
            <Text textAlign="center">
              <Trans i18nKey="guest.welcome.text">
                <Text type="bodySemiBold" textAlign="center" />
              </Trans>
            </Text>
            <Text textAlign="center" style={styles.thanks}>
              {t("guest.welcome.thanks")}
            </Text>
            <Text textAlign="center" type="subtextSemiBold">
              {t("guest.welcome.greetings")}
            </Text>
          </View>

          <Text textAlign="center" type="subHeading">
            {t("common.totalAmount")}
          </Text>
          <Text textAlign="center" type="h2" style={styles.amount}>
            {formatMoney(amount)}
          </Text>
        </View>

        <Image
          style={styles.toucan}
          source={require("../../../assets/images/exclusief-logo.png")}
        />
      </ScrollView>
      <Button label={t("ui.proceed")} onPress={proceed} size="full" />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
  },
  logo: {
    width: 136,
    height: 51,
    alignSelf: "center",
  },
  textContainer: {
    padding: Spacing.sp2,
    marginTop: Spacing.sp3,
    marginBottom: Spacing.sp4,
  },
  thanks: {
    marginVertical: Spacing.sp1,
  },
  amount: {
    marginTop: Spacing["sp1/2"],
  },
  toucan: {
    alignSelf: "center",
    resizeMode: "contain",
    width: "60%",
    height: 40,
    maxWidth: 288,
  },
});

export default WelcomeScreen;
