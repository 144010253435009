import { Theme as NavigationTheme } from "@react-navigation/native";
import { Theme } from "@valkdigital/ui-kit";
import { useColorScheme } from "react-native";

const useNavigationTheme = (): NavigationTheme => {
  const colorScheme = useColorScheme();

  return {
    dark: colorScheme === "dark",
    colors:
      colorScheme === "dark"
        ? {
            primary: Theme.dark.typography.color,
            background: Theme.dark.background,
            card: Theme.dark.onBackground,
            text: Theme.dark.typography.color,
            border: Theme.dark.border,
            notification: Theme.dark.error.primary,
          }
        : {
            primary: Theme.light.typography.color,
            background: Theme.light.background,
            card: Theme.light.onBackground,
            text: Theme.light.typography.color,
            border: Theme.light.border,
            notification: Theme.light.error.primary,
          },
  };
};

export default useNavigationTheme;
