import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/types";
import ENV from "../../../../environment";
import { request } from "../../../api";
import "./ApplePayButton.css";
import { useColorScheme } from "react-native";
import { submitPayment } from "../../../store/payment/actions";

interface ApplePayButtonProps {}

interface CustomWindow extends Window {
  ApplePaySession?: any;
}
declare let window: CustomWindow;

const ApplePayButton: React.FC<ApplePayButtonProps> = () => {
  const colorScheme = useColorScheme();
  const [showButton, setShowButton] = useState(false);
  const receipt = useSelector(({ receipt }: AppState) => receipt);
  const dispatch = useDispatch();

  const startPayment = async () => {
    const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
      countryCode: "NL",
      currencyCode: "EUR",
      supportedNetworks: ["visa", "masterCard", "amex", "maestro"],
      merchantCapabilities: ["supports3DS"],
      total: {
        label: ENV.merchantName,
        amount: (receipt.amountPlusTip * 0.01).toFixed(2),
      },
    };
    const session = new ApplePaySession(3, paymentRequest);
    session.begin();
    session.onvalidatemerchant = async ({ validationURL }) => {
      const response = await request.post("/vpay/session", {
        verification_uri: validationURL,
      });
      session.completeMerchantValidation(response.data);
    };
    session.onpaymentauthorized = async ({ payment }) => {
      await dispatch(
        submitPayment({
          paymentMethod: {
            method: "applepay",
            token: JSON.stringify(payment.token.paymentData),
          },
          onComplete: () =>
            session.completePayment(ApplePaySession.STATUS_SUCCESS),
        })
      );
    };
  };

  useEffect(() => {
    const mountApplePayButton = async () => {
      if (window.ApplePaySession) {
        setShowButton(true);
        const canMakePayments = await ApplePaySession.canMakePaymentsWithActiveCard(
          ENV.merchantIdentifier
        );
        setShowButton(canMakePayments);
      }
    };
    mountApplePayButton();
  }, []);

  return (
    <>
      {showButton && (
        <div
          className={`apple-pay-button ${
            colorScheme === "dark"
              ? "apple-pay-button-white"
              : "apple-pay-button-black"
          }`}
          onClick={startPayment}
        />
      )}
    </>
  );
};

export default ApplePayButton;
