import { AccountState, AccountActionTypes, AccountActions } from "./types";
import { UIActionTypes } from "../ui/types";

const initialState: AccountState = {
  guest: undefined,
  employee: undefined,
};

export default (state: AccountState = initialState, action: AccountActions) => {
  switch (action.type) {
    case AccountActionTypes.SET_GUEST_ACCOUNT: {
      return {
        ...state,
        guest: action.payload,
      };
    }
    case AccountActionTypes.UPDATE_GUEST_ACCOUNT: {
      return {
        ...state,
        guest: { ...state.guest, ...action.payload },
      };
    }
    case AccountActionTypes.SET_EMPLOYEE_ACCOUNT: {
      return {
        ...state,
        employee: action.payload,
      };
    }
    case UIActionTypes.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};
