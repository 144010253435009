import React, { useEffect, useState } from "react";
import TipScreen from "./TipScreen";
import { AppState } from "../../../store/types";
import { addTip, selectTipMode } from "../../../store/receipt/actions";
import {
  formatMoney,
  formatMoneyWithoutDash,
} from "../../../components/utils/formatMoney";
import { createTipOptions } from "./TipOptions";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TipMode } from "../../../store/receipt/types";

interface TipContainerProps {
  navigation: GuestNavProp;
}

const TipContainer: React.FC<TipContainerProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const {
    amount,
    tip,
    tip: { tipAmount, tipMode },
  } = useSelector(({ receipt }: AppState) => receipt);
  const [newTip, setNewTip] = useState<string>(
    formatMoneyWithoutDash(tipAmount)
  );
  const dispatch = useDispatch();
  const segments = [
    t("guest.payment.tip.selection"),
    t("guest.payment.tip.custom"),
  ];
  const [segment, setSegment] = useState<string>(segments[0]);

  const parsedTip = parseFloat(newTip.replace(",", ".").slice(1));
  const newTipToNumber: number = isNaN(parsedTip) ? 0 : parsedTip * 100;
  const tipOptions: string[] = createTipOptions(amount);
  const amountPlusTipAsString = formatMoney(amount + newTipToNumber);

  const onTipChange = (tipInput: string) => {
    if (tipInput === newTip) {
      return setNewTip("€");
    }
    setNewTip(tipInput);
  };

  const onSegmentChange = (segment: string) => {
    setSegment(segment);
    const tipModes = Object.values(TipMode);
    const idx = segments.findIndex((s) => s === segment);
    dispatch(selectTipMode(tipModes[idx]));
  };

  const onCustomTipChange = (value: string) => {
    const regex = /^€[0-9]*(\.|\,)?([0-9]{1,2})?$/;
    const characterIsValid: boolean = regex.test(value);
    if (characterIsValid) setNewTip(value);
  };

  const proceedWithTip = () => {
    if (newTip === "€") {
      return proceedWithoutTip();
    }
    dispatch(addTip(newTipToNumber));
    navigation.push("payment-options");
  };

  const proceedWithoutTip = () => {
    dispatch(addTip(0));
    navigation.push("payment-options");
  };

  const onFocusedInput = () => {
    setNewTip("€");
  };

  useEffect(() => {
    const tipModes = Object.values(TipMode);
    const idx = tipModes.findIndex((t) => t === tipMode);
    setSegment(segments[idx]);
  }, []);

  return (
    <TipScreen
      tip={newTip}
      amountPlusTipAsString={amountPlusTipAsString}
      onTipChange={onTipChange}
      onCustomTipChange={onCustomTipChange}
      segment={segment}
      segments={segments}
      onSegmentChange={onSegmentChange}
      tipMode={tipMode}
      proceedWithTip={proceedWithTip}
      proceedWithoutTip={proceedWithoutTip}
      onFocusedInput={onFocusedInput}
      tipOptions={tipOptions}
    />
  );
};

export default TipContainer;
