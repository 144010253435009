import { Alert } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Yup from "yup";
import l from "../locale";
import ENV from "../../environment";
import { PaymentStatuses } from "../guest/payment/types";

const Auth = {
  async get() {
    const tokens = await AsyncStorage.getItem("tokens");
    if (!tokens) return false;
    return JSON.parse(tokens);
  },
  async update(accessToken: string, refreshToken: string) {
    return AsyncStorage.setItem(
      "tokens",
      JSON.stringify({
        accessToken,
        refreshToken,
      })
    );
  },
  async reset() {
    return AsyncStorage.removeItem("tokens");
  },
};

const Notification = {
  info: (message: string) => {
    Alert.alert(l.t("ui.notice"), message, [
      { text: l.t("ui.ok"), onPress: () => null },
    ]);
  },
};

const Form = {
  async validate(schema: Yup.Schema<any>, values: any): Promise<null | object> {
    try {
      await schema.validate(values, { abortEarly: false });
      return null;
    } catch (err) {
      const errors: any = {};
      err.inner.forEach((err: Yup.ValidationError) => {
        const currentKey = err.path;
        errors[currentKey] = err.message;
      });
      return errors;
    }
  },
};

const Payment = {
  getStatus: (
    channel: { id: string; channel: string },
    setPaymentStatus: (status: PaymentStatuses) => void
  ) => {
    const streamURL = `wss://${ENV.apiUrl}/stream`;
    const ws = new WebSocket(streamURL);

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          command: "subscribe",
          identifier: JSON.stringify(channel),
        })
      );
    };
    ws.onmessage = (response: MessageEvent) => {
      const res = JSON.parse(response.data);
      const state = res.message?.state;
      if (state) {
        if (state !== PaymentStatuses.started) ws.close();
        setPaymentStatus(state);
      }
    };
    ws.onerror = (error) => {
      console.error(error);
    };
  },
};

export default {
  Auth,
  Notification,
  Form,
  Payment,
};
