import { Action } from "redux";
import { UIActionTypes } from "../ui/types";

export interface EmployeeAccount {
  name: string;
}

export interface GuestAccount {
  email: string;
  firstName: string;
  id?: string;
  language: string;
  lastName: string;
  middleName: string;
  mobile: string;
  telphone: string;
  title: string;
  vex: boolean;
  complete: number;
  mailVerified: boolean;
  mobileVerified: boolean;
  docVerifed: boolean;
  postalcode: string;
  housenumber: string;
  city: string;
  countryCode: string;
  street: string;
  type: AccountType;
  workProperty?: string;
  workId: string;
  balance: Balance;
  companyName?: string;
  password?: string;
  corporateId?: string;
  favorites: Favorites;
  dob: Date;
}

export interface FormDetails {
  title?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dob?: Date;
  mobile?: string;
  postalcode?: string;
  housenumber?: string;
  street?: string;
  countryCode?: string;
  city?: string;
  email?: string;
  password?: string;
  language?: string;
}

export interface Favorites {
  hotels: string[];
  restaurants: string[];
}

export enum AccountType {
  LITE = "lite",
  PRIVATE = "private",
  CORPORATE = "corporate",
  BUSINESS = "business",
  EMPLOYEE = "employee",
}

interface Balance {
  ambassador: number;
  bonus: number;
  loyal: number;
}

export interface AccountState {
  guest?: GuestAccount;
  employee?: EmployeeAccount;
}

export enum AccountActionTypes {
  SET_GUEST_ACCOUNT = "@@account/SET_GUEST_ACCOUNT",
  UPDATE_GUEST_ACCOUNT = "@@account/UPDATE_GUEST_ACCOUNT",
  SET_EMPLOYEE_ACCOUNT = "@@account/SET_EMPLOYEE_ACCOUNT",
}

interface SetGuestAccount extends Action<AccountActionTypes.SET_GUEST_ACCOUNT> {
  payload: GuestAccount;
}
interface UpdateGuestAccount
  extends Action<AccountActionTypes.UPDATE_GUEST_ACCOUNT> {
  payload: Partial<FormDetails>;
}
interface SetEmployeeAccount
  extends Action<AccountActionTypes.SET_EMPLOYEE_ACCOUNT> {
  payload: EmployeeAccount;
}
interface ResetStore extends Action<UIActionTypes.RESET_STORE> {}

export type AccountActions =
  | SetGuestAccount
  | UpdateGuestAccount
  | SetEmployeeAccount
  | ResetStore;
