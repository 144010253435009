import { ThemeContext } from "@valkdigital/ui-kit";
import React, { useContext } from "react";
import Svg, { Rect, Path } from "react-native-svg";
import { SvgProps } from "./types";

interface CheckBoxProps extends SvgProps {
  checked: boolean;
}
const CheckBox: React.FC<CheckBoxProps> = ({ style, checked }) => {
  const { success } = useContext(ThemeContext);

  if (checked) {
    return (
      <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" style={style}>
        <Rect
          x="0.590881"
          y="0.590912"
          width="30.8182"
          height="30.8182"
          rx="4.5"
          stroke="#C2C2C2"
        />
        <Rect
          x="0.5"
          y="0.5"
          width="31"
          height="31"
          rx="4.5"
          fill={success.primary}
          stroke={success.primary}
        />
        <Path
          d="M13.3929 18.4007L13.5717 18.5838L13.7506 18.4007L23.5647 8.3505L26.6435 11.2561L13.5717 24.6422L5.35653 16.2294L8.43528 13.3238L13.3929 18.4007Z"
          fill="white"
          stroke="#86C840"
          stroke-width="0.5"
        />
      </Svg>
    );
  }
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" style={style}>
      <Rect
        x="0.590942"
        y="0.590912"
        width="30.8182"
        height="30.8182"
        rx="4.5"
        stroke="#C2C2C2"
      />
    </Svg>
  );
};

export default CheckBox;
