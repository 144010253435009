import React, { useContext } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { Text, Spacing, ThemeContext } from "@valkdigital/ui-kit";
import { useTranslation } from "react-i18next";

interface LoadingPageProps {
  message?: string;
  fontLoaded?: boolean;
}

const LoadingPage: React.FC<LoadingPageProps> = ({
  message,
  fontLoaded = true,
}) => {
  const { t } = useTranslation();
  const { typography } = useContext(ThemeContext);

  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color={typography.color} />
      {fontLoaded && (
        <Text style={styles.text} textAlign="center">
          {message ? message : t("ui.loading")}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
  },
  text: { marginTop: Spacing.sp2 },
});

export default LoadingPage;
