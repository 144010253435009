export default {
  account: {
    form: {
      email: "E-mailadres",
      emailInvalid: "Controleer of uw e-mailadres correct is ingevoerd",
      emailPlaceholder: "Vul uw e-mailadres in",
      pass: "Wachtwoord",
      required: "Uw {{value}} ontbreekt nog",
      title: "Aanhef",
      titles: ["Dhr.", "Mevr.", "Fam."],
      name: "Naam",
      firstname: "Voornaam",
      middlename: "Tussenvoegsel",
      lastname: "Achternaam",
      address: "Adres",
      housenumber: "Huisnummer",
      postalcode: "Postcode",
      country: "Land",
      city: "Stad",
      street: "Straat",
      mobile: "Telefoonnummer",
      check: "Controleer of uw {{value}} correct is ingevoerd",
      updateSuccess: "Uw gegevens zijn succesvol aangepast",
    },
    login: {
      appleButton: "Doorgaan met Apple",
      button: "Inloggen",
      info: {
        roomTab:
          "Om op kamerrekening te kunnen boeken, dient u eerst in te loggen.",
        voucher:
          "Om uw vouchers te kunnen verzilveren, \ndient u eerst in te loggen.",
      },
      fbButton: "Doorgaan met Facebook",
      forgotPass: "Wachtwoord vergeten?",
      noAccount: "Geen account?",
      text: {
        roomTab:
          "Dan heeft u niet direct bij ons geboekt. Op de vorige pagina kunt u alsnog met uw F(X)-nummer en kamernummer op rekening boeken.",
        voucher:
          "Dan heeft u op dit moment nog geen vouchers. U kunt na het betalen gemakkelijk een account bij ons aanmaken.",
      },
      title: "Login bij Valk Pay",
    },
    password: {
      button: "Stuur instructies",
      text:
        "Geen zorgen, u hoeft enkel uw e-mailadres even in te vullen. We zullen de instructies dan naar u mailen.",
      title: "Uw wachtwoord \nvergeten?",
    },
    titles: {
      login: "Login",
      register: "Account aanmaken",
      details: "Uw gegevens",
    },
  },
  common: {
    amountLeft: "Nog te voldoen",
    confirm: "Bevestigen",
    name: "Naam",
    noInternet: "Geen internet verbinding",
    noThanks: "Nee, bedankt",
    otherPaymentMethod: "Andere betaalmethode",
    redirect: "Terug naar de nota",
    refresh: "Pagina verversen",
    retry: "Opnieuw proberen",
    search: "Zoeken",
    searchEmpty:
      "Er zijn helaas geen resultaten gevonden voor de zoekopdracht. Probeer het opnieuw.",
    skip: "Overslaan",
    startReview: "Review geven",
    tip: "Fooi",
    total: "Totaal: ",
    totalAmount: "Totaalbedrag",
  },
  employee: {
    hotel: {
      fetchError: "Er ging iets fout bij het ophalen van de hotels",
    },
    login: {
      button: "Inloggen",
      changeHotel: "Hotel wijzigen",
      code: "Login",
      codePlaceholder: "Vul je persoonlijke logincode in",
      header: "Inloggen bij ValkPay",
      hotel: "Hotel",
      scanTitle: "Scan de QR-code",
    },
    navigation: {
      tabs: {
        pay: "Afrekenen",
        settings: "Instellingen",
      },
      titles: {
        createQr: "Valk Pay betaling",
        options: "Betaalmethode",
        pin: "Pin terminal",
        print: "Bonprinter",
        success: "Betaling verwerkt",
        tab: "Tafelnummer: {{table}}",
        table: "Valk Pay Medewerker",
        valkpay: "Valk Pay",
      },
    },
    options: {
      choose: "Kies de gewenste betaalmethode",
      pos: "Pinpas of fysieke Creditcard",
      room_tab: "Op kamerrekening",
      valkpay: "ValkPay",
    },
    payment: {
      return: "Terug naar beginscherm",
      success: "Betaling is succesvol verwerkt!",
    },
    pin: {
      error: {
        heading: "Er ging iets fout met de pin betaling",
        content:
          "Probeer het opnieuw, kies een andere pin terminal of kies een andere betaalmethode.",
        reselect: "Andere pin terminal",
      },
      header: "Selecteer de juiste pin terminal",
      noTerminal: "Er zijn momenteel geen pin terminals gevonden.",
      noTerminalFix:
        "Controleer of er pin terminals zijn aangesloten en probeer het opnieuw.",
      processing:
        "Pinbedrag wordt verstuurd \nnaar de Pin terminal {{terminalNr}}...",
      progress: "Betaalstatus aan het ophalen...",
      searching: "Beschikbare pin terminals aan het zoeken...",
      select: "Selecteer een pin terminal",
      submit: "Pin terminal selecteren",
    },
    printer: {
      error: {
        content: "Probeer het opnieuw of sla de bon printen stap over.",
        heading: "Er ging iets fout met de printer",
        proformaContent: "Probeer het opnieuw of ga terug naar de bon.",
        proformaSkip: "Terug naar de bon",
        reselect: "Andere printer kiezen",
        skip: "Doorgaan zonder printen",
      },
      fetchError:
        "Er ging iets fout bij het ophalen van de beschikbare printers",
      header: "Selecteer een printer",
      noPrinter: "Er zijn momenteel geen printers gevonden.",
      noPrinterFix:
        "Controleer of er printers zijn aangesloten en probeer het opnieuw.",
      processing: "De bon wordt geprint...",
      searching: "Beschikbare printers aan het zoeken...",
      select: "Selecteer een printer",
      shouldAskForPrinter: "Toon deze stap bij elke betaling",
    },
    qrInstruction:
      "De gast kan deze QR code scannen om met Valk Pay te betalen",
    roomTab: {
      amount: "Bedrag",
      checked: "Ik heb de gastnaam gecontroleerd",
      denied: {
        heading: "Kan niet op kamerrekening boeken voor kamer {{room}}.",
        content:
          "Op kamerrekening boeken is alleen mogelijk wanneer de gast een betaalgarantie heeft afgegeven.",
        otherRoom: "Andere kamer proberen",
      },
      failed: {
        heading: "Er ging iets fout met de huidige betaalmethode.",
        content: "Probeer het opnieuw of kies een andere betaalmethode.",
      },
      label: "Voer het kamernummer in",
      name: "Gastnaam",
    },
    selectHotel: "Selecteer je hotel om in te loggen",
    tab: {
      voucher: "Voucher?",
    },
    table: {
      label: "Voer het tafelnummer in",
      proceed: "Bon ophalen",
      welcome: "Welkom\n",
    },
    tip: {
      header: "Wil de gast nog fooi geven?",
      input: {
        total: "Bedrag aanpassen",
        tip: "Fooi invoeren",
      },
      amountWithoutTip: "Totaalbedrag zonder fooi",
    },
    update: {
      button: "Download update",
      error:
        "Het updaten van de app is mislukt. Controleer je internetverbinding en probeer het opnieuw.",
      info:
        "Een nieuwe versie van de Valk Pay app is beschikbaar. Download de de update om verder te gaan.",
      new: "De nieuwe update bevat:",
      subTitle: "Download nu de nieuwe update",
      title: "Nieuwe Valk Pay Update!",
    },
    voucher: {
      add: "Voucher toevoegen",
      complete: "Afronden",
      empty: "Er is nog geen voucher toegevoegd.",
      loading: "De bon wordt aangepast. \nEen moment geduld aub...",
      proceed: "Ja, scan de voucher",
      processing: "Vouchers worden verzilverd...",
      remove: "Verwijderen",
      scanTitle: "Scan voucher",
      skip: "Deze stap overslaan",
      text:
        "Heeft de gast eventueel nog een voucher die ingewisseld kan worden?",
      title: "Vouchers",
      warning:
        "Zodra het bedrag naar de pinterminal is verstuurd is het niet meer mogelijk om een voucher in te wisselen.",
    },
  },
  guest: {
    error: {
      alreadyPaid: "Uw bon is al betaald",
      giftcardBalance: "Er staat geen saldo op uw cadeaucard",
      appleLogin: "Doorgaan met Apple is mislukt",
      facebookLogin: {
        no_account:
          "Er is geen account gevonden. Probeer het opnieuw of login zonder Facebook.",
        user_denied:
          "U heeft toegang tot uw Facebook account geweigerd, probeer het opnieuw of login zonder Facebook.",
      },
      giftcardPin:
        "De pin van de cadeaucard ontbreekt \nVoer uw pincode hier in:",
      noParams: "De betaalstatus kan niet worden opgehaald",
      noReceipt: "Er is geen bon gescand",
    },
    payment: {
      cancelled: {
        header: "Betaling geannuleerd",
        retry: "Kies uw betaalmethode",
        text: "Wilt u een andere betaalmethode kiezen?",
      },
      failed: {
        header: "Betaling mislukt",
        retry: "Opniew proberen",
        text: "Probeer het alstublieft opnieuw.",
      },
      giftcard: {
        amount: "Te voldoen",
        balance: "Uw saldo",
        cancel: "Annuleer cadeaucard",
        label: "Selecteer het bedrag \ndat u wilt verzilveren",
        number: "Wat is uw cadeaucard \nnummer?",
        pay: "Betalen",
        pin: "Wat is uw cadeaucard \npincode?",
        receipt: "Uw rekening",
        scanTitle: "Scan de barcode",
        skip: "Kies een andere betaalmethode",
      },
      options: {
        header: "Kies uw betaalmethode",
        ideal: {
          header: "Kies uw bank",
        },
        roomtab: "Op kamerrekening",
      },
      processing: "Betaling aan het verwerken...",
      receipt: {
        amount: "Kassabon",
        giftcard: "Valk cadeaucard",
        title: "Uw rekening",
      },
      roomtab: {
        abort: "Kies een andere betaalmethode",
        account: "Geboekt met een Valk Account",
        auth: {
          instructions: [
            {
              extra:
                "(Heeft u een Valk account, dan kunt u dit ook online doen.)",
              message:
                "Dien een verzoek in aan de receptiebalie om uw creditcard te autoriseren.",
            },
            {
              message: "Voer uw creditcard gegevens in.",
            },
            {
              message: "Accepteer de voorwaarden.",
            },
            {
              message:
                "U kunt tijdens uw verblijf alles op kamerrekening boeken.",
            },
          ],
        },
        authorise: "Hoe kan ik een betaalgarantie afgeven?",
        book: "Boek op mijn kamer",
        check: "Gegevens controleren",
        consent:
          "Ik geef toestemming om deze kassabon op mijn kamerrekening te boeken.",
        folio: "F(X)-nummer",
        folioInfo:
          "Om de autorisatie te controleren, vezoeken wij u om uw F(X)-nummer en kamernummer in te voeren.",
        folioQuestion: "Waar vind ik mijn F(X)-nummer?",
        help:
          "Mochten er tijdens het proces problemen zijn, kan de receptie altijd geraadpleegd worden.",
        info:
          "Met ValkPay bieden we u de mogelijkheid om op kamerrekening te boeken. Hiervoor moet u al wel een creditcard autorisatie bij ons afgegeven hebben.",
        invalidProfile:
          "Het is helaas niet mogelijk om momenteel op kamerrekening te boeken.",
        noAccount: "Geen Valk Account",
        noAuth: "Betaalgarantie vereist",
        noAuthInfo: "U heeft momenteel nog geen betaalgarantie afgegeven.",
        noAuthYet: "Ik heb nog geen betaalgarantie afgegeven",
        notFound:
          "Het F(X)-nummer en/of het kamernummer is incorrect. Probeer het opnieuw.",
        options: "Kies uit één van deze mogelijkheden om door te gaan:",
        proceed: "Verifiëren",
        room: "Kamernummer",
        roomcode: {
          instructions: [
            {
              message:
                "Uw F(X)-nummer is terug te vinden in uw bevestigingsmail.",
            },
            {
              message:
                "Uw F(X)-nummer is terug te vinden op de houder van het kamerpasje.",
            },
          ],
        },
      },
      success: {
        header: "Bedankt. \nU heeft betaald!",
        message:
          "Wij horen graag of u een fijne restaurant ervaring heeft gehad.",
        text: "Geef een review en maak kans op een cadeaucard van",
        value: " €20,-",
        method: {
          other: "Bedankt \nU heeft betaald!",
          roomtab: "Bedankt. \nUw nota is op uw kamer geboekt!",
        },
        promo: {
          phone: "Hiervoor is uw 06-nummer benodigd.",
          proceed: "Ja, ik wil een overnachting winnen",
          subHeader:
            "Een overnachting in het spiksplinternieuwe hotel Amsterdam-Zuidas winnen?",
        },
      },
      tip: {
        custom: "Ander bedrag",
        header: "Wilt u fooi geven?",
        selection: "Selectie",
        subHeader: "Kleine moeite, groot plezier",
      },
    },
    promo: {
      conditions: "Actievoorwaarden",
      info:
        "Iedere 500e betaling ontvangt een overnachting in het spiksplinternieuwe hotel Amsterdam-Zuidas.",
      phone: {
        conditions: "actievoorwaarden",
        confirm: "Bevestig mobiel nummer",
        consent: "Ik ga akkoord met de ",
        header: "Overnachting winnen",
        label: "Mobiel nummer",
        text: "Vul uw telefoonnummer in om de overnachting te winnen.",
        textExtra:
          "Tevens ontvangt u een SMS van Valk Exclusief met \n€5 Valk Bonus tegoed op uw Valk Exclusief account.",
      },
      sms: {
        eta:
          "Het kan een paar minuten duren voordat u het SMS bericht ontvangt.",
        header: "SMS bericht wordt verzonden!",
        info:
          "In dit bericht staan de instructies om uw deelname compleet te maken.",
      },
    },
    review: {
      create: "Schrijf een review",
      addComment: "Voeg een opmerking toe",
      email: {
        header: "Verzend uw review",
        proceed: "Verzenden",
        text: "Vul uw e-mailadres in en maak kans op een cadeaucard van",
        textBold: " €20,-",
      },
      food: "Heeft u lekker gegeten?",
      rateUs: "Geef ons een cijfer",
      service: "Hoe vond u de bediening?",
      thanks: "Bedankt voor uw beoordeling!",
      tikkie: "Bedrag terugvragen aan uw tafelgenoten? Verstuur een Tikkie.",
      valkpay: "Hoe heeft u deze nieuwe ValkPay dienst ervaren?",
      value: "Wat vond u van de prijs-kwaliteitverhouding?",
    },
    tikkie: {
      goBack: "Ga terug",
      person: "1 persoon",
      person_plural: "{{count}} personen",
      proceed: "Tikkie versturen",
      text: "Selecteer het aantal personen \nmet wie u de bon wilt delen",
      title: "Aantal personen",
    },
    titles: {
      roomTab: "Op kamerrekening boeken",
      roomTabVerify: "Kamernummer verifiëren",
    },
    voucher: {
      applySuccess: "Vouchers succesvol toegevoegd.",
      confirmSuccess: "Vouchers succesvol verzilverd.",
      removeSuccess: "Vouchers succesvol verwijderd.",
      fetching: "Vouchers worden opgehaald..",
      hasBooking: "Heeft u een overnachting in het hotel?",
      hasNoClaimableVouchers:
        "Op basis van uw kassabon zijn er geen vouchers beschikbaar.",
      hasNoVouchers: "U heeft geen vouchers op dit account.",
      ineligibleArticles: "U heeft de mogelijkheid om vouchers in te zetten.",
      redeem: "Vouchers verzilveren",
      title: "Vouchers",
      tryAnotherAccount: "Ander account proberen",
    },
    welcome: {
      greetings: "Graag tot ziens! \nFamilie van der Valk",
      text:
        "Wij hopen dat u een fijne restaurant \nervaring heeft gehad. We vragen u na \nbetaling in <0>3 vragen</0> om uw mening.",
      thanks: "Bedankt voor uw bezoek.",
    },
  },
  scanner: {
    instructions:
      "Open uw instellingen en geef Valk Pay toegang tot uw camera.",
    noAccess: "Camera is uitgeschakeld",
    openSettings: "Open instellingen",
  },
  tab: {
    description: "Omschrijving",
    ep: "E.P.",
    qty: "Qty",
    servedBy: "Bediend door: ",
    total: "Totaal",
  },
  ui: {
    attention: "Let op:",
    cancel: "Annuleer",
    close: "Sluiten",
    error: {
      close: "Melding sluiten",
      title: "Er is iets fout gegaan:\n",
    },
    errorPageTitle: "Er is iets fout gegaan",
    loading: "Laden...",
    notice: "Mededeling",
    ok: "OK",
    proceed: "Doorgaan",
    signOut: "Uitloggen",
    unknownError:
      "Er is een onbekende fout opgetreden. Probeer het later opnieuw",
  },
};
