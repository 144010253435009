import React from "react";
import PromoSmsScreen from "./PromoSmsScreen";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";

interface PromoSmsContainerProps {
  navigation: GuestNavProp;
}

const PromoSmsContainer: React.FC<PromoSmsContainerProps> = ({
  navigation,
}) => {
  const proceedWithReview = () => {
    navigation.push("review-food");
  };

  return <PromoSmsScreen proceedWithReview={proceedWithReview} />;
};

export default PromoSmsContainer;
