import React from "react";
import ReviewSuccessScreen from "./ReviewSuccessScreen";
import { GuestNavProp } from "../../../navigation/guest/GuestRoutes";

interface ReviewSuccessContainerProps {
  navigation: GuestNavProp;
}

const ReviewSuccessContainer: React.FC<ReviewSuccessContainerProps> = ({
  navigation,
}) => {
  const proceed = () => {
    navigation.push("tikkie");
  };

  return <ReviewSuccessScreen proceed={proceed} />;
};

export default ReviewSuccessContainer;
