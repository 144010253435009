import React, { Ref } from "react";
import Slider from "../Slider";
import { View, StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import {
  Button,
  Spacing,
  Text,
  TextButton,
  TextInputType,
  XLTextInput,
} from "@valkdigital/ui-kit";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

interface ReviewScreenProps {
  inputRef: Ref<TextInputType>;
  question: string;
  currentProgress: number;
  sliderValue: number;
  comment: string;
  showComment: boolean;
  smallerTitle?: boolean;
  proceed: () => void;
  onSliderValueChange: (value: number) => void;
  onCommentChange: (text: string) => void;
  toggleShowComment: () => void;
}

const ReviewScreen: React.FC<ReviewScreenProps> = ({
  inputRef,
  question,
  currentProgress,
  sliderValue,
  comment,
  showComment,
  smallerTitle,
  proceed,
  onSliderValueChange,
  onCommentChange,
  toggleShowComment,
}) => {
  const { t } = useTranslation();

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={styles.container}
      scrollEnabled={true}
      keyboardOpeningTime={0}
      extraScrollHeight={20}
      enableResetScrollToCoords={false}
      keyboardShouldPersistTaps="handled"
    >
      <View style={styles.section}>
        {smallerTitle ? (
          <Text type="h4">{question}</Text>
        ) : (
          <Text type="h3">{question}</Text>
        )}
      </View>

      <View>
        <Text style={styles.rate} textAlign="center">
          {t("guest.review.rateUs")}
        </Text>
        <Slider
          value={sliderValue}
          min={1}
          max={10}
          step={1}
          onChange={onSliderValueChange}
        />

        <TextButton
          label={t("guest.review.addComment")}
          onPress={toggleShowComment}
          style={styles.textButton}
        />

        {showComment && (
          <XLTextInput
            ref={inputRef}
            value={comment}
            onChangeText={onCommentChange}
            keyboardType="default"
            multiline={true}
            useFullHeight={true}
            containerStyle={styles.textAreaContainer}
            style={styles.textArea}
          />
        )}
      </View>

      <Button
        label={t("ui.proceed")}
        onPress={proceed}
        currentProgress={currentProgress}
        containerStyle={styles.button}
        type="progressbar"
        color="#498fa7"
      />
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingTop: Spacing.sp2,
  },
  section: {
    paddingHorizontal: Spacing.sp2,
  },
  rate: {
    marginBottom: Spacing.sp2,
  },
  textButton: {
    alignSelf: "center",
  },
  button: {
    marginTop: Spacing.sp6,
  },
  textAreaContainer: {
    marginTop: Spacing.sp1,
    paddingHorizontal: Spacing.sp2,
  },
  textArea: {
    paddingTop: Spacing.sp1,
  },
});

export default ReviewScreen;
