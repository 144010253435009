import { Action } from "redux";
import { UIActionTypes } from "../ui/types";

export interface PinTerminal {
  id: string;
  label: string;
  type: string;
}

export interface Printer {
  id: string;
  label: string;
  object: string;
}

export interface SettingsState {
  pinTerminal?: PinTerminal;
  printer?: {
    selected: Printer;
    enabled: boolean;
  };
  shouldAskForPrinter?: boolean;
}

export enum SettingsActionTypes {
  SET_PIN_TERMINAL = "@@settings/SET_PIN_TERMINAL",
  SET_SELECTED_PRINTER = "@@settings/SET_SELECTED_PRINTER",
  SET_PRINTER_ENABLED = "@@settings/SET_PRINTER_ENABLED",
  SET_SHOULD_ASK_FOR_PRINTER = "@@settings/SET_SHOULD_ASK_FOR_PRINTER",
}

interface SetPinTerminal extends Action<SettingsActionTypes.SET_PIN_TERMINAL> {
  payload: PinTerminal;
}
interface SetSelectedPrinter
  extends Action<SettingsActionTypes.SET_SELECTED_PRINTER> {
  payload: Printer;
}
interface SetPrinterEnabled
  extends Action<SettingsActionTypes.SET_PRINTER_ENABLED> {
  payload: boolean;
}
interface SetShouldAskForPrinter
  extends Action<SettingsActionTypes.SET_SHOULD_ASK_FOR_PRINTER> {
  payload: boolean;
}
interface ResetStore extends Action<UIActionTypes.RESET_STORE> {}

export type SettingsActions =
  | SetPinTerminal
  | SetSelectedPrinter
  | SetPrinterEnabled
  | SetShouldAskForPrinter
  | ResetStore;
