import {
  PaymentActionTypes,
  PaymentActions,
  PaymentMethod,
  PaymentRequestBody,
} from "./types";
import { AppThunk } from "../../store/types";
import ENV from "../../../environment";
import { navigate } from "../../navigation/rootNavigation";
import { setError, setGiftcardError } from "../../store/ui/actions";
import l from "../../locale";
import { AxiosError } from "axios";
import { Platform } from "react-native";
import { handleErrors, request } from "../../api";
import { Dispatch } from "redux";

export const setPaymentMethod = (
  paymentMethod: PaymentMethod
): PaymentActions => ({
  type: PaymentActionTypes.SET_PAYMENT_METHOD,
  payload: paymentMethod,
});

const handleMissingGiftcardPin = ({
  error,
  methods,
  dispatch,
}: {
  error: AxiosError<any>;
  methods: PaymentMethod[];
  dispatch: Dispatch;
}) => {
  if (
    error.response?.status === 422 &&
    methods.filter((x) => x.method === "giftcard").length > 0
  ) {
    dispatch(setGiftcardError(true));
    return true;
  }
  return false;
};

const handleRoomTabErrors = ({
  error,
  skipToken,
  dispatch,
  paymentMethod,
}: {
  error: AxiosError<any>;
  skipToken: boolean;
  dispatch: Dispatch;
  paymentMethod?: PaymentMethod;
}) => {
  if (paymentMethod?.method === "room_tab") {
    if (error.response?.status === 404) {
      if (skipToken) {
        dispatch(setError(l.t("guest.payment.roomtab.notFound")));
      }
      if (Platform.OS === "web") navigate("room-tab-verify");
      return true;
    }
    if (error.response?.data?.error.includes("Invalid/expired profile")) {
      dispatch(setError(l.t("guest.payment.roomtab.invalidProfile")));
      return true;
    }
  }
  return false;
};

export const submitPayment = ({
  setIsLoading,
  paymentMethod,
  onComplete,
  skipToken = true,
}: {
  setIsLoading?: (isLoading: boolean) => void;
  paymentMethod?: PaymentMethod;
  onComplete?: () => void;
  skipToken?: boolean;
}): AppThunk => async (dispatch, getState) => {
  const {
    receipt: {
      id,
      tip: { tipAmount },
      amountPlusTip,
      printerId,
    },
    giftcard: { amount: giftcardAmount, code, pin },
  } = getState();

  const return_url = `${ENV.url}/guest/payment-progress?id=${id}`;

  const methods: PaymentMethod[] = [];

  if (giftcardAmount === amountPlusTip) {
    methods.push({
      method: "giftcard",
      number: code,
      pin,
    });
  } else {
    giftcardAmount &&
      methods.push({
        method: "giftcard",
        number: code,
        pin,
        amount: giftcardAmount,
      });
    paymentMethod && methods.push(paymentMethod);
  }

  const requestBody: PaymentRequestBody = {
    receiptId: id,
    methods,
    tip: tipAmount,
    return_url,
    printerId,
  };

  try {
    setIsLoading && setIsLoading(true);
    const response = await request.post(
      "/vpay/payments",
      requestBody,
      skipToken
    );
    setIsLoading && setIsLoading(false);
    const { method, value, allowed, reservations } = response.data;

    if (method === "redirect") {
      window.location.href = value;
      return;
    }
    if (method === "authorisation") {
      if (value === "completed") {
        if (onComplete) onComplete();
        navigate("payment-progress", { id });
        return;
      }
      if (allowed) {
        const { id: reservationId, ...reservationData } = reservations[0];
        dispatch(
          setPaymentMethod({
            ...paymentMethod,
            ...reservationData,
            reservationId,
          })
        );
        navigate("room-tab-confirm");
      } else {
        navigate("room-tab-denied");
      }
    }
  } catch (error) {
    setIsLoading && setIsLoading(false);
    if (handleMissingGiftcardPin({ error, methods, dispatch })) {
      return;
    }
    if (handleRoomTabErrors({ error, skipToken, dispatch, paymentMethod })) {
      return;
    }
    handleErrors(error);
  }
};
