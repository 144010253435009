import { Spacing, Text, Button, TextButton } from "@valkdigital/ui-kit";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, ScrollView } from "react-native";

interface RoomTabOptionsScreenProps {
  onLoginPress: () => void;
  onNoLoginPress: () => void;
  onNoAuthPress: () => void;
}

const RoomTabOptionsScreen: React.FC<RoomTabOptionsScreenProps> = ({
  onLoginPress,
  onNoLoginPress,
  onNoAuthPress,
}) => {
  const { t } = useTranslation();

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text type="bodyRegular">{t("guest.payment.roomtab.info")}</Text>

      <TextButton
        label={t("guest.payment.roomtab.noAuthYet")}
        style={styles.textButton}
        onPress={onNoAuthPress}
      />

      <Text type="bodyRegular" style={styles.instruction}>
        {t("guest.payment.roomtab.options")}
      </Text>

      <Button
        label={t("guest.payment.roomtab.account")}
        onPress={onLoginPress}
        containerStyle={styles.button}
      />
      <Button
        label={t("guest.payment.roomtab.noAccount")}
        onPress={onNoLoginPress}
        type="ghost"
        containerStyle={styles.button}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: Spacing.sp2,
    paddingTop: Spacing.sp4,
  },
  button: {
    marginVertical: Spacing.sp2,
  },
  textButton: {
    marginTop: Spacing.sp2,
    marginBottom: Spacing.sp6,
  },
  instruction: {
    marginBottom: Spacing.sp2,
  },
});

export default RoomTabOptionsScreen;
