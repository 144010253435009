import { Spacing, Text, ThemeContext } from "@valkdigital/ui-kit";
import React, { useContext } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";

interface SelectTipProps {
  tip: string;
  tipOptions: string[];
  onTipChange: (tip: string) => void;
}
const SelectTip: React.FC<SelectTipProps> = ({
  tip,
  tipOptions,
  onTipChange,
}) => {
  const { typography, info, grey } = useContext(ThemeContext);
  return (
    <View style={styles.container}>
      {tipOptions.map((tipOption) => (
        <TouchableOpacity
          key={tipOption}
          style={[
            styles.button,
            tip === tipOption
              ? { backgroundColor: info.primary }
              : { backgroundColor: grey[0] },
          ]}
          onPress={() => onTipChange(tipOption)}
        >
          <Text
            type="h3"
            color={tip === tipOption ? typography.inverted : typography.color}
          >
            {tipOption}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  button: {
    minWidth: 60,
    width: "auto",
    height: 60,
    justifyContent: "center",
    paddingHorizontal: Spacing.sp2,
    marginHorizontal: Spacing.sp2,
    borderRadius: Spacing.sp2,
  },
});

export default SelectTip;
