import { Action } from "redux";
import { UIActionTypes } from "../ui/types";

export interface GiftcardState {
  code: string;
  pin: string;
  balance: number;
  amount: number;
}

export enum GiftcardActionTypes {
  SET_GIFTCARD_DATA = "@@giftcard/SET_GIFTCARD_DATA",
  REMOVE_GIFTCARD_DATA = "@@giftcard/REMOVE_GIFTCARD_DATA",
}

interface SetGiftcardData
  extends Action<GiftcardActionTypes.SET_GIFTCARD_DATA> {
  payload: Partial<GiftcardState>;
}
interface RemoveGiftcardData
  extends Action<GiftcardActionTypes.REMOVE_GIFTCARD_DATA> {}
interface ResetStore extends Action<UIActionTypes> {}

export type GiftcardActions = SetGiftcardData | RemoveGiftcardData | ResetStore;
